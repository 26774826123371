import * as CONSTANTS from '../utils/constants/constants';

/**
 * Method to get the maximum weight to amend Service point
 * 
 * @param {*} data - array of object from "productCountryFeature" API
 * @returns - number - Maximum unique data from filtered array of object
 */
export const getServicepointWeight = (data) => {
  const filteredMaxWeight = data.filter((ele) =>
    ele.addressType === CONSTANTS.AMEND_SERVICE_POINT.ADDRESS_TYPE &&
    (ele.productCode === CONSTANTS.AMEND_SERVICE_POINT.PRO_CODE_210 ||
      ele.productCode === CONSTANTS.AMEND_SERVICE_POINT.PRO_CODE_220 ||
      ele.productCode === CONSTANTS.AMEND_SERVICE_POINT.PRO_CODE_1)
  ).map((x) => x.maximumWeight);

  const uniqueMaxWeight = [...new Set(filteredMaxWeight)][0];
  return uniqueMaxWeight;
};

/**
* Method to get the customsDeclaration from products list
*
* @returns boolean
*/
export const getCustomDeclaration = (allProducts = []) => {
  // Ensure allProducts is an array
  if (!Array.isArray(allProducts)) {
    throw new Error("Expected an array");
  }

  // Check if any product requires a customs declaration
  const showClearanceDeclaration = allProducts.some(
    (product) => product?.deliveryCapabilities?.accountType === CONSTANTS.CLERANCE_DECLARATION.PRODUCT_ACCOUNT_TYPE &&
      product?.deliveryCapabilities?.customsDeclaration === CONSTANTS.CLERANCE_DECLARATION.CUSTOM_DECLARATION,
  );

  return showClearanceDeclaration;
};