/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { connect, useSelector } from 'react-redux';
import ServicePoint from '../../../../components/addShipment/Products&Services/ServicePoint';
import { doorStepInstructionOptions as instructions, doorStepInstructionOptionsOld as oldInstruction, doorStepInstructionOptionForcedAGE as ageForced, doorStepInstructionOptionForcedPIN as pinForced } from '../../../../utils/constants/constants';
import { setDropdownValues } from '../../../../utils/helperFunctions';
import { deliveryChecksforPINAndAGE , DELIVERY_OPTIONS ,DELIVER_TYPE, MISC_VALUES} from '../../../../utils/constants/constants'
import {ERROR_MSG} from '../../../../utils/constants/errorConstant'
import domesticDefaults from '../../../../components/configuration/orderFileConfigurations/orderFileDefaults/domesticDefaults/domesticDefaults';

const DeliveryOptions = (props) => {
  const {
    formField: {
      doorStepInstructions,
      exchange,
      specialInstructions,
      additionalInstruction,
      deliveryType,
      leaveSafeLocation,
    },
    formikValues,
    setFieldValue,
    isDomestic,
    isCarriageForward,
    isTemplate,
    isAddressBook,
    loadedFrom,
    isOrderDefaults,
    isFromProcessOrder,
    isSelectDefaultDoorstepinst,
    servicePointAddress,
    isAmendShipment,
    postalCodeResponse,
    isCollectionPage,
    customerNumber,
    populatedAddressBookResponse,
    isCollectionDefault,
    searchedAddressData,
    isDomesticDefault,
    servicePointWeight
  } = props;

  const {
    allowed,
    not_allowed,
    forced
  } = deliveryChecksforPINAndAGE;



  const [showAdditionalInstruction, setShowAdditionalInstruction] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [doorStepInstructionOptions, setDoorStepInstructionOptions] = useState(instructions);

  const allProducts = useSelector((state) => state.common.allProducts);
  let userAccounts = useSelector((state) => state?.accounts?.userAccounts ?? []);
  let selectedUserAccount = userAccounts.filter((account) => (account.accountId == populatedAddressBookResponse?.accountNumber));
  let searchedAddressDataForInstructions = searchedAddressData;
  const onAddNewInstruction = () => {
    setShowAdditionalInstruction(true);
  };

  const isDeliveryTypeDoorStep = () => {
    return (props.formikValues || {}).deliveryType === DELIVER_TYPE.DELIVER_DOORSTEP;
  };

  const isDeliveryInstructionDoorStepOnly = () => {
    return ((props.formikValues || {}).doorStepInstructions || {}).value === DELIVERY_OPTIONS.DELIVERY_DOORSTEP_ONLY;
  };

  const isDeliveryInstructionLeaveSafe = () => {
    return ((props.formikValues || {}).doorStepInstructions || {}).value === DELIVERY_OPTIONS.DELIVERY_SAFE_LOCATION;
  };

  useEffect(() => {
    setShowAdditionalInstruction(false);
    formikValues.additionalInstruction?.trim?.() &&
      formikValues.additionalInstruction.trim() !== MISC_VALUES.HOUSE_NO &&
      setShowAdditionalInstruction(true);
  }, [formikValues.doorStepInstructions, formikValues.deliveryType, formikValues.additionalInstruction]);

  useEffect(() => {
    let ins = instructions;
    const nonPalletProducts = allProducts.filter((p) => !p.deliveryCapabilities?.pallets);

    const account_pin_age = props?.formikValues?.accountNumber?.deliveryCheck;
    const postal_pin = postalCodeResponse?.deliveryChecksPin;
    const postal_age = postalCodeResponse?.deliveryChecksAge;


    const filterInstructions = (condition, value) => {
      if (condition) {
        ins = ins.filter(instruction => instruction.value !== value);
      }
    };

    if (account_pin_age) {
      const { pin, age } = account_pin_age;

      if (pin === not_allowed && age === not_allowed) {
        ins = oldInstruction;
      } else if (age === forced) {
        ins = ageForced
      } else if (pin === forced) {
        ins = pinForced
      }
      else {
        filterInstructions(pin === allowed && age === not_allowed, DELIVERY_OPTIONS.DELIVERY_DOORSTEP_AGE_VERIFICATION);
        filterInstructions(pin === not_allowed && age === allowed, DELIVERY_OPTIONS.DELIVERY_DOORSTEP_PIN);
      }

      if (postal_pin !== undefined && postal_age !== undefined && !postal_age && !postal_pin) {
        ins = oldInstruction;
      }
    }

    if (isCollectionPage == true || isTemplate == true) {
      ins = oldInstruction
    }

    if (isAddressBook == true && formikValues.doorStepInstructions.value === DELIVERY_OPTIONS.DELIVERY_DOORSTEP_PIN) {
      ins = ins.filter((instruction) => instruction.value !== DELIVERY_OPTIONS.DELIVERY_DOORSTEP_AGE_VERIFICATION);
    } else if (isAddressBook == true && formikValues.doorStepInstructions.value === DELIVERY_OPTIONS.DELIVERY_DOORSTEP_AGE_VERIFICATION) {
      ins = ins.filter((instruction) => instruction.value !== DELIVERY_OPTIONS.DELIVERY_DOORSTEP_PIN);
    }
    else if (isAddressBook == true) {
      ins = oldInstruction;
    }

    if (formikValues.createPalletShipment === MISC_VALUES.YES) {
      ins = ins.filter((instruction) => instruction.value === DELIVERY_OPTIONS.DELIVERY_DOORSTEP_ONLY);
    }
    else if (nonPalletProducts.length > 0) {
      if (
        !nonPalletProducts.some((product) => {
          return product?.deliveryCapabilities?.neighbourDelivery;
        })
      ) {
        ins = ins.filter((instruction) => instruction.value !== DELIVERY_OPTIONS.DELIVERY_LEAVE_NEIGHBOUR);
      }
      if (
        !nonPalletProducts.some((product) => {
          return product?.deliveryCapabilities?.secureLocationDelivery;
        })
      ) {
        ins = ins.filter((instruction) => instruction.value !== DELIVERY_OPTIONS.DELIVERY_SAFE_LOCATION);
      }
      if (
        !nonPalletProducts.some((product) => {
          return (
            !product?.deliveryCapabilities?.neighbourDelivery && !product?.deliveryCapabilities?.secureLocationDelivery
          );
        })
      ) {
        ins = ins.filter((instruction) => instruction.value !== DELIVERY_OPTIONS.DELIVERY_DOORSTEP_ONLY);
      }
    }

    if (!isDomesticDefault && searchedAddressDataForInstructions) {
      const selectedOption = ins.findIndex(obj => obj.value == searchedAddressDataForInstructions?.deliveryOptions?.doorStepInstructions)
      if (selectedOption == -1) {
        setFieldValue('doorStepInstructions', ins[0]);
      }
      else {
        setFieldValue('doorStepInstructions', ins[selectedOption]);
        const addressSpecialInstruction =  searchedAddressDataForInstructions?.deliveryOptions?.specialInstructions;
       if(addressSpecialInstruction)
        {
          setFieldValue('specialInstructions', searchedAddressDataForInstructions?.deliveryOptions?.specialInstructions);
        }
        else{
          setFieldValue('specialInstructions', '');
        }
      }
    }

    if (JSON.stringify(ins) !== JSON.stringify(instructions) && !isOrderDefaults) {
      setDoorStepInstructionOptions(ins);
    } else {
      setDoorStepInstructionOptions(instructions);
    }
  }, [allProducts, isAddressBook, formikValues?.createPalletShipment, postalCodeResponse, searchedAddressDataForInstructions]);

  useEffect(() => {
    if (formikValues.deliveryType === DELIVER_TYPE.DELIVER_DOORSTEP && (loadedFrom?.loadedFrom === '' || !loadedFrom)) {
      if ((formikValues.doorStepInstructions || {}).value === DELIVERY_OPTIONS.DELIVERY_LEAVE_NEIGHBOUR) {
        setFieldValue('specialInstructions', 'If out leave with neighbour');
        setFieldValue('additionalInstruction', 'House no/name: ');
      } else if ((formikValues.doorStepInstructions || {}).value === DELIVERY_OPTIONS.DELIVERY_SAFE_LOCATION) {
        setFieldValue('specialInstructions', '');
        setFieldValue('additionalInstruction', '');
        setFieldValue('leaveSafeLocation', 'Leave safe location');
      } else {
        setFieldValue('additionalInstruction', '');
        setFieldValue('specialInstructions', '');
      }
    } else if (!loadedFrom || loadedFrom?.loadedFrom === '') {
      setFieldValue('additionalInstruction', '');
      setFieldValue('specialInstructions', '');
    }

    if (
      isTemplate === true &&
      formikValues.deliveryType === DELIVER_TYPE.DELIVER_SERVICE_POINT &&
      formikValues.specialInstructions !== '' && (!loadedFrom || loadedFrom?.loadedFrom === '')
    ) {
      setFieldValue('specialInstructions', '');
      setFieldValue('doorStepInstructions', doorStepInstructionOptions[0]);
    }
  }, [formikValues.deliveryType, formikValues.doorStepInstructions]);

  const [isDeliveryTypeDisabled, setIsDeliveryTypeDisabled] = useState(false);

 useEffect(() => {
    const { weight, noOfItems } = formikValues;
    if (isDomestic && ((weight > servicePointWeight && !isAmendShipment) || noOfItems > MISC_VALUES.ONE)) {
      setFieldValue('deliveryType', 'deliverToDoorStep');
      setIsDeliveryTypeDisabled(true);
    } else if (!loadedFrom || loadedFrom?.loadedFrom === '') {
      setIsDeliveryTypeDisabled(false);
    }
  }, [formikValues.destinationCountry, formikValues.weight, formikValues.noOfItems, isDomestic, servicePointWeight]);

  //Set Delivery type for carriage forward form when weight & parcel change.
  useEffect(() => {
    const { fromWeight, fromParcel } = formikValues;
    if (isDomestic && ((fromWeight > servicePointWeight && !isAmendShipment) || fromParcel > MISC_VALUES.ONE)) {
      setFieldValue('deliveryType', 'deliverToDoorStep');
      setIsDeliveryTypeDisabled(true);
    }
    else {
      setIsDeliveryTypeDisabled(false);
    }
  }, [formikValues.fromWeight, formikValues.fromParcel, isDomestic, servicePointWeight]);

  useEffect(() => {
    const { createPalletShipment, weight, noOfItems } = formikValues;
    if ((createPalletShipment === MISC_VALUES.YES) || 
    (isDomestic && ((weight > servicePointWeight && !isAmendShipment) || noOfItems > MISC_VALUES.ONE))) {
      setFieldValue('deliveryType', 'deliverToDoorStep');
      setIsDeliveryTypeDisabled(true);
    }
    else {
      setIsDeliveryTypeDisabled(false);
    }
  }, [props.formikValues?.createPalletShipment, props.formikValues?.weight, servicePointWeight]);

  useEffect(() => {
    let instructionOptions = doorStepInstructionOptions;
    if (!formikValues.doorStepInstructions) {
    }
    formikValues?.deliveryType !== DELIVER_TYPE.DELIVER_DOORSTEP && setFieldValue('doorStepInstructions', instructionOptions[0]);
  }, [formikValues.deliveryType]);

  useEffect(() => {
    if (doorStepInstructionOptions.length > 0) {
      if (loadedFrom?.loadedFrom === MISC_VALUES.SUMMARY) {
        setFieldValue(
          'doorStepInstructions',
          setDropdownValues(
            doorStepInstructionOptions,
            'value',
            loadedFrom?.loadedResponse?.doorStepInstructions?.value,
          ),
        );
      } else if (loadedFrom?.loadedFrom) {
        setFieldValue(
          'doorStepInstructions',
          setDropdownValues(
            doorStepInstructionOptions,
            'value',
            loadedFrom?.loadedResponse?.deliveryOptions?.doorStepInstructions,
          ),
        );
      } else {
        setFieldValue('doorStepInstructions', doorStepInstructionOptions[0]);
      }
    }
  }, [doorStepInstructionOptions]);
  useEffect(() => {
    if (formikValues.exchange === MISC_VALUES.YES && formikValues.doorStepInstructions?.value === DELIVERY_OPTIONS.DELIVERY_DOORSTEP_ONLY) {
      setShowInstructions(false);
    } else {
      setShowInstructions(true);
    }
  }, [formikValues.exchange, formikValues.doorStepInstructions]);

  useEffect(() => {
    if (formikValues?.createPalletShipment === MISC_VALUES.YES) {
      setTimeout(() => {
        setFieldValue("exchange", "no");
        setFieldValue('additionalInstruction', '');
        setShowInstructions(false);
      }, 0);
    }
    else if (!(formikValues.exchange === MISC_VALUES.YES && formikValues.doorStepInstructions?.value === DELIVERY_OPTIONS.DELIVERY_DOORSTEP_ONLY)) {
      setShowInstructions(true);
    }
  }, [formikValues?.createPalletShipment]);

  useEffect(() => {
    if (props.updateValidationSchema) {
      specialInstructions.validation.validations = specialInstructions.validation.validations.filter(validation => validation.type === MISC_VALUES.MAX);
      if (isAmendShipment || isOrderDefaults || isFromProcessOrder) {
        specialInstructions.validation.validations.push({
          type: 'max',
          params: [MISC_VALUES.SIXTY, ERROR_MSG.For_60_Char],
        });
        specialInstructions.props.inputProps.maxLength = MISC_VALUES.SIXTY;
      }
      else {
        specialInstructions.validation.validations.push({
          type: 'max',
          params: [MISC_VALUES.THIRTY, ERROR_MSG.For_30_char],
        });
        specialInstructions.props.inputProps.maxLength = MISC_VALUES.THIRTY;
      }
      props.updateValidationSchema();
    }
  }, [isAmendShipment, isOrderDefaults, isFromProcessOrder]);


  return (
    <div className="wd-100">
      <Grid className="radio-label" container spacing={2} direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12}>
          <FormRenderer
            {...deliveryType.props}
            fullWidth
            data={deliveryTypeOptions}
            isDisabled={isDeliveryTypeDisabled || isAmendShipment}
          />
        </Grid>

        {/* <Grid item xs={10} sm={4}></Grid> */}
      </Grid>

      {isDeliveryTypeDoorStep() && isDomestic ? (
        <Grid container spacing={3} direction="row" alignItems="flex-start" style={{ paddingTop: '0px' }}>
          <Grid item xs={12} sm={4} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '38px' }}>
            <FormRenderer {...doorStepInstructions.props} fullWidth data={doorStepInstructionOptions} />
          </Grid>
          {isDeliveryInstructionDoorStepOnly() && !isCarriageForward && !(formikValues?.createPalletShipment === MISC_VALUES.YES) ? (
            <Grid className="radio-label" item xs={12} sm={6} md={5} lg={5} style={{ marginLeft: '0px' }}>
              <FormRenderer {...exchange.props} data={exchangeOnDelivery} isDisabled={isAmendShipment} fullWidth />
            </Grid>
          ) : null}
          {isDeliveryInstructionLeaveSafe() && !isCarriageForward ? (
            <Grid item xs={12} sm={6} md={isCarriageForward ? 12 : 6}>
              <FormRenderer {...leaveSafeLocation.props} fullWidth />
            </Grid>
          ) : null}
        </Grid>
      ) : null}

      {!isDeliveryTypeDoorStep() && !isTemplate && !isAddressBook && !isOrderDefaults ? (
        <Grid container spacing={3} direction="row" alignItems="flex-start" style={{ paddingTop: '12px' }}>
          <Grid item xs={12} sm={6} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '37px' }}>
            <ServicePoint isDomestic={true} {...props} />
          </Grid>
          {isCarriageForward ? null : <Grid item xs={12} sm={6} md={6} lg={6}></Grid>}
        </Grid>
      ) : null}

      {isDomestic ? (
        <Grid container spacing={3} direction="row" style={{ paddingTop: '4px' }} alignItems="flex-start">
          {isCarriageForward && isDeliveryTypeDoorStep() && isDeliveryInstructionLeaveSafe() ? (
            <>
              {!isCarriageForward && (
                <Grid item xs={12} sm={12} md={12} style={{ paddingRight: '37px' }}>
                  <FormRenderer {...leaveSafeLocation.props} fullWidth />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} style={{ paddingRight: '37px' }}>
                <FormRenderer {...specialInstructions.props} fullWidth />
              </Grid>
            </>
          ) : (
            <Grid item xs={10} sm={6} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '37px' }}>
              <FormRenderer {...specialInstructions.props} fullWidth />
            </Grid>
          )}
          {isCarriageForward ? (
            <Grid item xs={10} sm={6} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '37px' }}>
              <FormRenderer {...additionalInstruction.props} label={'Additional instructions'} fullWidth />
            </Grid>
          ) : null}
          {!isCarriageForward && !isOrderDefaults ? (
            <React.Fragment>
              {showInstructions && showAdditionalInstruction ? (
                <Grid item xs={10} sm={6} md={isCarriageForward ? 12 : 6} style={{ paddingLeft: '15px' }}>
                  <FormRenderer {...additionalInstruction.props} isDisabled={isAmendShipment || isFromProcessOrder} fullWidth />
                </Grid>
              ) : showInstructions && !isOrderDefaults ? (
                <Grid
                  item
                  xs={10}
                  sm={6}
                  md={isCarriageForward ? 12 : 6}
                  style={{ marginTop: '3px', paddingLeft: '10px' }}
                >
                  <Button
                    startIcon={<Add style={{ fontSize: '22px' }} />}
                    onClick={onAddNewInstruction}
                    disabled={isAmendShipment || isFromProcessOrder}
                    style={{ textTransform: 'none' }}
                  >
                    Add another instruction
                  </Button>
                </Grid>
              ) : null}
            </React.Fragment>
          ) : isTemplate && !isOrderDefaults ? (
            <Grid item xs={10} sm={6} md={isCarriageForward ? 12 : 6} style={{ marginTop: '3px', paddingLeft: '10px' }}>
              <Button
                startIcon={<Add style={{ fontSize: '22px' }} />}
                onClick={onAddNewInstruction}
                style={{ textTransform: 'none' }}
              >
                Add another instruction
              </Button>
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </div>
  );
};

DeliveryOptions.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
  isCarriageForward: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    loadedFrom: state.loadedFrom.loadedFrom,
    postalCodeResponse: state.addShipmentData.postalCodeResponse,
    servicePointWeight: state.common.servicePointWeight
  };
};

export default connect(mapStateToProps, null)(DeliveryOptions);

const deliveryTypeOptions = [
  {
    value: 'deliverToDoorStep',
    label: 'Deliver to doorstep',
  },
  {
    value: 'deliverToServicePoint',
    label: 'Deliver to ServicePoint',
  },
];

const exchangeOnDelivery = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];
