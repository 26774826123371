import React, { useState, useRef, useEffect } from 'react';

import { getYupSchemaFromMetaData } from '../../../../utils/yupSchema/yupSchemaGenerator';
import { Form, Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

import {
  schemaValues,
  importTypeValues,
  dateOptions,
  delimiterOptions,
} from '../../../../utils/constants/constants';

import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import { Grid, Snackbar } from '@material-ui/core';
import CustomModal from '../../../../framework/modal/modal';
import ConfirmationDialog from '../../../addressBook/confirmation/confirmation';
import { formatCreateSchemaObj, getCustomerAccountID } from '../../../../utils/helperFunctions';
import { axiosConfig } from '../../../../api/axios';
import './createNewSchema.scss';
import MainSchemaDetails from './MainSchemaDetails';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import * as actions from '../../../../store/actions/index';
import { useDispatch } from 'react-redux';
let OrderSetupResponse;

const CreateNewSchema = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { oldSchemaName } = useParams();

  const { formId, formField } = createNewSchemaForm;
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [showClearConfirmationDialog, setShowClearConfirmationDialog] = useState(false);
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState(false);
  const [requiredField, setRequiredField] = useState(false);
  // const [noEmptyFields, setNoEmptyFields] = useState(false);

  const [userSchemaDetails, setUserSchemaDetails] = useState();
  const [userMappingDetails, setUserMappingDetails] = useState();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isClear, setIsClear] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false)
  const formikRef = useRef(null);
  const [isValid, setIsValid] = useState(true);
  const [schemaNameExist, setSchemaNameExist] = useState(false);
  const [existingFolderPaths, setExistingFolderPaths] = useState([]);
  const [folderPathExist, setFolderPathExist] = useState(false)

  let newSearchValidationSchema = getYupSchemaFromMetaData(formField, [], []);


  const initialValues = {
    dateFormat: dateOptions[0],
    importType: importTypeValues[1].value,
    delimiter: delimiterOptions[0],
    schemaType: schemaValues[0],
    schemaName: '',
    pollingPath: '',
    newDelimeter: '',
    otherformat: '',
    rowIndicator3: '',
    rowIndicator1: '',
    rowIndicator2: '',
    fileExtension: '',
  };

  let reqFields = [
    'schemaName',
    'schemaType',
    'dateFormat',
    'delimiter',
    'newDelimeter',
    'otherformat',
    'rowIndicator3',
    'rowIndicator2',
    'rowIndicator1',
    'pollingPath',
    'fileExtension',
  ];

  const isFormInValid = (errors, values, mappingField) => {
    if (Object.keys(errors).length > 0) {
      for (let err in errors) {
        if (
          reqFields.some((fieldName) => {
            if (fieldName === 'schemaName') {
              return fieldName === err;

            } else if (fieldName === 'pollingPath') {

              return fieldName === err;

            } else if (fieldName === 'fileExtension') {

              return fieldName === err;

            } else if (fieldName === 'otherformat') {
              if (values.dateFormat.value === 'other') {
                return fieldName === err;
              }
            } else if (fieldName === 'newDelimeter') {
              if (values.importType === "delimited") {
                if (values.delimiter.value === 'otherDelimiter') {
                  return fieldName === err;
                }
              }

            } else if (fieldName === 'rowIndicator3' || fieldName === 'rowIndicator2' || fieldName === 'rowIndicator1') {
              for (let i = 0; i < mappingField.length; i++) {
                // if(mappingField[i].valueName==="rowindicator"){
                if (values.schemaType.value === 'orders+customs+collections' || values.schemaType.value === 'orders+customs+parcels') {
                  if (values.rowIndicator1 === "" || values.rowIndicator2 === '' || values.rowIndicator3 === "") {

                    return true
                  }



                } else if (
                  values.schemaType.value === 'orders+customs' ||
                  values.schemaType.value === 'orders+collections'||
                  values.schemaType.value ===  'orders+clearance' ||
                  values.schemaType.value ===  'collections+clearance'
                ) {


                  if (values.rowIndicator1 === "" || values.rowIndicator2 === '') {

                    return true
                  }

                }
                // }

              }

            }
          })
        ) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  const isMapInValid = (schemaDetails, fieldName) => {
    if (schemaDetails.importType === 'fixedlength') {
      for (let i = 0; i < fieldName.length; i++) {
        if (fieldName[i].isRequiredField) {
          if (
            fieldName[i].startsAt === '' ||
            fieldName[i].length === '' ||
            isNaN(fieldName[i].startsAt) ||
            isNaN(fieldName[i].length)
          ) {
            return true;
          }
        } else if (!fieldName[i].isRequiredField) {
          if (fieldName[i].startsAt != '' || fieldName[i].length != '') {
            if (isNaN(fieldName[i].startsAt) || isNaN(fieldName[i].length)) {
              return true;
            }
          }
        }
      }
    } else if (schemaDetails.importType === 'delimited') {
      for (let i = 0; i < fieldName.length; i++) {
        if (fieldName[i].isRequiredField) {
          if (fieldName[i].columnFromFile === '') {
            return true;
          }
        }
      }
    }

    return false;
  };

  const [isOderSetupValueMatching, setIsOderSetupValueMatching] = useState(true);

  useEffect(() => {
    axiosConfig
      .get(`${ServiceEndPoints.orderSetup}?organization=${getCustomerAccountID()}`)
      .then((res) => {
        OrderSetupResponse = res.data;
        if (!res.data.importsSetup?.overWriteSameCustomerRef || !res.data.importsSetup?.overWriteSameAltRef) {
          setIsOderSetupValueMatching(false)
        }
      }).catch(err => { console.log(err) })
    axiosConfig
      .get(
        `${ServiceEndPoints.getSchemas}?&organization=${getCustomerAccountID()}`,
      )
      .then((res) => {
        let existingPaths = []
        if (oldSchemaName) {
          existingPaths = res.data.orderFileSchemas.filter(schema => schema.schemaName?.trim()?.toLowerCase() !== oldSchemaName?.trim()?.toLowerCase()).filter(sc => sc.filePollingFolder?.trim());
        }
        else
          existingPaths = res.data.orderFileSchemas.filter(schema => schema.filePollingFolder?.trim());

        setExistingFolderPaths(existingPaths.map(available => available.filePollingFolder.replaceAll("//", "/")))
      })
  }, [])

  const isMultipleSchema = () => {
    const formValues = formikRef.current.values
    return formValues.schemaType.value !== 'order' && formValues.schemaType.value !== 'collection' && formValues.schemaType.value !== 'custom' && formValues.schemaType.value !== "order+collectionsConsignor" && formValues.schemaType.value !== "parcel" && formValues.schemaType.value !== "clearance" ? true : false;
  };

  const handleSubmit = (values, errors, showConditionalPopup = true) => {
    setRequiredField(true);
    userMappingDetails.map(item => item.tableData?.checked === true ? Object.assign(item, item.tableData.checked = false) : item)
    if (!isFormInValid(errors, values, userMappingDetails) && !isMapInValid(userSchemaDetails, userMappingDetails) && !schemaNameExist && !folderPathExist) {

      if (showConditionalPopup && isMultipleSchema() && !isOderSetupValueMatching) {
        setShowWarningModal(true);
        return;
      }
      const reqObj = formatCreateSchemaObj(userSchemaDetails, userMappingDetails, values);
      axiosConfig
        .post(ServiceEndPoints.schemaUrl, reqObj)
        .then((res) => {
          dispatch(actions.setAppSnackBarData({ msg: 'Schema saved successfully' }));
          if (!oldSchemaName) {
            setUploadedFile(null);
            formikRef.current.resetForm();
            setIsClear(true);
          }
          else {
            dispatch(actions.setActiveTab('mySchema'));
            history.push('/configuration/fileConfiguration');
          }

        })
        .catch((err) => {
          console.log(err, 'err');
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: 'Error in saving schema: Error status',
                },
              ],
            }),
          );
        });
    }
  };

  const clearSchema = () => {
    setConfirmationMsg('Are you sure you want to clear the page?');
    setShowClearConfirmationDialog(true);
    setIsClear(false);
  };

  const cancelSchema = () => {
    setConfirmationMsg('Any unsaved changes will be lost?');
    setShowCancelConfirmationDialog(true);
  };
  const handleDialogClose = () => {
    setShowCancelConfirmationDialog(false);
    setShowClearConfirmationDialog(false);
  };
  const onClearDialogConfirm = () => {
    setIsClear(true);

    setShowClearConfirmationDialog(false);
    // setNoEmptyFields(false);
    formikRef?.current?.resetForm();
    setUploadedFile(null);
  };

  const onCancelDialogConfirm = () => {
    if (oldSchemaName != undefined) {
      dispatch(
        actions.setActiveTab('mySchema'),
      );
    }

    history.goBack();
    setShowCancelConfirmationDialog(false);
  };

  const onDialogCancel = () => {
    setShowCancelConfirmationDialog(false);
    setShowClearConfirmationDialog(false);
  };
  const setTotalSchemaDetails = (data) => {
    setRequiredField(false);


    setUserSchemaDetails(data);
  };
  const setTotalMappingDetails = (fields) => {
    setRequiredField(false);

    setUserMappingDetails(fields);
  };

  const setOrderSetupOwerWriteValues = () => {
    if (OrderSetupResponse) {
      const reqObj = {
        ...OrderSetupResponse,
        importsSetup: {
          ...OrderSetupResponse?.importsSetup,
          overWriteSameCustomerRef: true
        }
      };
      axiosConfig
        .post(`${ServiceEndPoints.orderSetup}`, reqObj)
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div className="createNewSchema">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={newSearchValidationSchema}
        innerRef={formikRef}
      >
        {({ values, setFieldValue, errors, setFieldTouched, resetForm, setValues }) => (
          <Form id={formId}>
            <MainSchemaDetails
              formField={formField}
              formikValues={values}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              oldSchemaName={oldSchemaName}
              errors={errors}
              requiredField={requiredField}
              setIsValid={setIsValid}
              setTotalSchemaDetails={setTotalSchemaDetails}
              setTotalMappingDetails={setTotalMappingDetails}
              userMappingDetails={userMappingDetails}
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              resetForm={resetForm}
              isClear={isClear}
              setSchemaNameExist={setSchemaNameExist}
              setFolderPathExist={setFolderPathExist}
              existingFolderPaths={existingFolderPaths}
              folderPathExist={folderPathExist}
              setValues={setValues}
              setIsClear={setIsClear}
            />

            <Grid container spacing={3} direction="row" justify="flex-start" alignItems="flex-start">
              <Grid item>
                <span>
                  Fields marked as <span style={{ color: 'red' }}>** </span>are mandatory if you want to directly print
                  labels
                </span>
              </Grid>
            </Grid>
            <Grid
              container
              className="create-schema-btnContainer"
              spacing={3}
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Grid item>
                <InputButton variant="text" className="text-btn" label="Cancel" onClick={() => cancelSchema()} />
                <Grid container item className="customModal-book">
                  <CustomModal open={showCancelConfirmationDialog} title="" onClose={handleDialogClose} maxWidth="sm">
                    <ConfirmationDialog
                      confirmationMessage={confirmationMsg}
                      onConfirm={onCancelDialogConfirm}
                      OnCancel={onDialogCancel}
                    />
                  </CustomModal>
                </Grid>
              </Grid>
              <Grid item>
                {oldSchemaName === undefined ? (
                  <InputButton variant="text" className="outlined-btn" label="Clear" onClick={() => clearSchema()} />
                ) : (
                  ''
                )}
                <Grid container item className="customModal-book">
                  <CustomModal open={showClearConfirmationDialog} title="" onClose={handleDialogClose} maxWidth="sm">
                    <ConfirmationDialog
                      confirmationMessage={confirmationMsg}
                      onConfirm={onClearDialogConfirm}
                      OnCancel={onDialogCancel}
                    />
                  </CustomModal>
                </Grid>
              </Grid>
              <Grid item>
                <InputButton
                  variant="outlined"
                  className="contained-btn"
                  label={oldSchemaName != undefined ? 'Update schema' : 'Save schema'}
                  onClick={() => {
                    handleSubmit(values, errors);
                  }}
                  buttonType="submit"
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <CustomModal open={showWarningModal} title="" onClose={() => { setShowWarningModal(false) }} maxWidth="sm">
        <ConfirmationDialog
          confirmationMessage={"For multiple schema type the overwrite of orders having same customer and alternatvie reference is required. If you want to change the configuration please go to Order setup tab"}
          onConfirm={() => {
            setShowWarningModal(false);
            setOrderSetupOwerWriteValues();
            handleSubmit(formikRef?.current?.values, formikRef?.current?.errors, false);
          }}
        />
      </CustomModal>
    </div>
  );
};

export default CreateNewSchema;

const createNewSchemaForm = {
  formId: 'createNewSchema',

  formField: {
    schemaName: {
      props: {
        name: 'schemaName',
        label: 'Schema name',
        type: 'text',
        placeholder: 'Enter',
        isRequired: true,
      },
      validation: {
        name: 'schemaName',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Schema name is required'],
          },
        ],
      },
    },
    schemaType: {
      props: {
        name: 'schemaType',
        label: 'Schema type',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
        isRequired: true,
      },
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Schema type is required'],
          },
        ],
      },
    },
    pollingPath: {
      props: {
        name: 'pollingPath',
        label: 'File polling path',
        type: 'text',
        isRequired: true,
        placeholder: "C:\\Myfolder\\Myfilelocation",
      },
      validation: {
        name: 'pollingPath',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['File polling path is required'],
          },
        ],
      },
    },
    importType: {
      props: {
        name: 'importType',
        label: 'Import type:',
        type: 'radio',
      },
    },
    dateFormat: {
      props: {
        name: 'dateFormat',
        label: 'Date format',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
        isRequired: true,
      },
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Date format is required'],
          },
        ],
      },
    },
    isheaderIncluded: {
      props: {
        name: 'isheaderIncluded',
        label: 'Is first row a header?',
        type: 'checkbox',
      },
    },
    delimiter: {
      props: {
        name: 'delimiter',
        label: 'Delimiter',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
      },
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Doorstep instructions is required'],
          },
        ],
      },
    },
    otherformat: {
      props: {
        name: 'otherformat',
        label: 'Enter other date format',
        type: 'text',
        placeholder: 'Enter',
      },
      validation: {
        name: 'otherformat',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Date format is required'],
          },
        ],
      },
    },
    newDelimeter: {
      props: {
        name: 'newDelimeter',
        label: 'Enter the delimeter',
        type: 'text',
        placeholder: 'Enter',
        isRequired: true,
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Delimiter is required'],
          },
        ],
      },
    },
    rowIndicator1: {
      props: {
        name: 'rowIndicator1',
        label: 'Row indicator',
        type: 'text',
        placeholder: 'Enter',
        isRequired: true,
      },
      validation: {
        name: 'rowIndicator1',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Row indicator is required'],
          },
        ],
      },
    },
    rowIndicator2: {
      props: {
        name: 'rowIndicator2',
        label: 'Row indicator',
        type: 'text',
        placeholder: 'Enter',
        isRequired: true,
      },
      validation: {
        name: 'rowIndicator2',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Row indicator is required'],
          },
        ],
      },
    },
    rowIndicator3: {
      props: {
        name: 'rowIndicator3',
        label: 'Row indicator',
        type: 'text',
        placeholder: 'Enter',
        isRequired: true,
      },
      validation: {
        name: 'rowIndicator3',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Row indicator is required'],
          },
        ],
      },
    },
    fileExtension: {
      props: {
        name: 'fileExtension',
        label: 'File Extension',
        type: 'text',
        isRequired: true,
        placeholder: "*.txt",
      },
      validation: {
        name: 'fileExtension',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['File extension is required'],
          },
          {
            type: 'matches',
            params: [/^[a-zA-z0-9*]*(\d*\.\d*)[a-zA-z]{1,5}$/, 'File extension must be in correct format e.g. *.txt or *.csv'],
          },
        ],
      },
    },
  },
};
