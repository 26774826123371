import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { getYupSchemaFromMetaData } from '../../../../../utils/yupSchema/yupSchemaGenerator';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import './addCommodityDetails.scss';
import InputButton from '../../../../../framework/Inputs/inputButton/inputButton';
import { GET } from '../../../../../api/axios';
import { ReactComponent as InfoIcon } from '../../../../../assets/images/InfoIcon.svg';
import { dynamicSort } from '../../../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../../../utils/constants/ApiConstant';

const AddCommodityDetails = (props) => {
  const {
    addNewCommodityDetails,
    updateCommodityDetails,
    marketPlacesList,
    isMarketplacesCommodity,
    shipmentMovementType,
    data: { operationType, rowDetails, index, totalWeight },
  } = props;

  const countryDetails = { ...(rowDetails || {}).countryOfOrigin };
  const formField = getFormField(totalWeight);
  const formField2 = getFormField2();

  const validationSchema = getYupSchemaFromMetaData(formField, [], [], [['quantity', 'weight']]);
  const validationSchema2 = getYupSchemaFromMetaData(formField2, [], [], [['quantity', 'weight']]);
  const emptyValues = {
    commodityCode: '',
    fullDescriptionOfGoods: '',
    quantity: '',
    unitWeight: '',
    unitValue: '',
    countryOfOrigin: '',
  };

  const [countries, setCountries] = useState([]);
  const [disblebtn, setdisblebtn] = useState(false);
  const [initialValues, setInitialValues] = useState(
    operationType === 'updateCommodityDetails' ? rowDetails : emptyValues,
  );

  const formikRef = useRef(null);

  // const debounced = useDebouncedCallback((value) => {
  //   fetchCommodityDetails(value);
  // }, 500);
  const isFormValid = (errors) => {
    // const {...restControls } = errors;
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = async (values, actions) => {
    if (operationType === 'updateCommodityDetails') {
      updateCommodityDetails(index, values);
    } else {
      addNewCommodityDetails(values);
    }
  };

  const getCountry = (countryCode) => {
    return countries.find((country) => country.code === countryCode);
  };

  useEffect(() => {
    if (operationType === 'updateCommodityDetails') {
      let existingValues = { ...rowDetails };
      setInitialValues(existingValues);
    } else {
      setInitialValues(emptyValues);
    }
  }, [countries, operationType, rowDetails]);


  useEffect(() => {
    if (operationType === 'updateCommodityDetails') {
      let existingValues = { ...rowDetails };
      existingValues['countryOfOrigin'] = getCountry((countryDetails || {}).code);
      setInitialValues(existingValues);
    } else {
      emptyValues['countryOfOrigin'] = getCountry('GBR');
      setInitialValues(emptyValues);
    }
  }, [countries, operationType, rowDetails]);

  useEffect(() => {
    // eslint-disable-next-line new-cap
    GET(ServiceEndPoints.getCountries)
      .then((res) => {
        setCountries(res.data.countries.sort(dynamicSort('name')));
      })
      .catch((err) => {
        setCountries([]);
      });
  }, []);
  const resetbtnForm = async (values,errors) => {
    if(isFormValid(errors)){
      await setdisblebtn(true);
      handleSubmit(values);
    }else{
      
    }
  }
  const resetCommodityForm = (resetForm, setFieldValue) => {
    resetForm();
    setFieldValue('countryOfOrigin', getCountry('GBR'));
  };
  return (
    <div className="wd-100">
      <Grid container>
      {shipmentMovementType == 'b2b' ?  
      <Formik
          initialValues={initialValues}
          // onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={validationSchema2}
          validateOnMount={true}
          innerRef={formikRef}
        >
          {({ values, setFieldValue, errors, resetForm, dirty }) => (
            <Form className="wd-100">
              <Grid container spacing={2} direction="column" style={{ paddingTop: '12px' }}>
                <Grid item>
                  <FormRenderer {...formField2.fullDescriptionOfGoods.props} fullWidth />
                </Grid>
                <Grid item>
                  <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                    <Grid item xs={11} sm={11}>
                      <FormRenderer
                        {...formField2.commodityCode.props}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <Tooltip title="Use the info button to look this up at gov.uk">
                        <IconButton target="_blank" href="https://www.trade-tariff.service.gov.uk/sections">
                          <SvgIcon fontSize="large" component={InfoIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <FormRenderer {...formField2.quantity.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...formField2.unitWeight.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...formField2.unitValue.props} fullWidth />
                </Grid>
                <Grid item>
                <FormRenderer {...formField2.countryOfOrigin.props} data={countries} fullWidth />
                </Grid>            
                <Grid item>
                  <Grid spacing={3} container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                      <InputButton
                        variant="outlined"
                        className="outlined-btn"
                        onClick={() => resetCommodityForm(resetForm, setFieldValue)}
                        label="Reset"
                        buttonType="reset"
                        isDisabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <InputButton
                        variant="contained"
                        className="contained-btn"
                        buttonType="submit"
                        label={operationType === 'updateCommodityDetails' ? 'Update' : 'Add'}
                        isDisabled={disblebtn}
                        onClick={() => resetbtnForm(values,errors)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        :
        <Formik
          initialValues={initialValues}
          // onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnMount={true}
          innerRef={formikRef}
        >
          {({ values, setFieldValue, errors, resetForm, dirty }) => (
            <Form className="wd-100">
              <Grid container spacing={2} direction="column" style={{ paddingTop: '12px' }}>
                <Grid item>
                  <FormRenderer {...formField.fullDescriptionOfGoods.props} fullWidth />
                </Grid>
                <Grid item>
                  <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                    <Grid item xs={11} sm={11}>
                      <FormRenderer
                        {...formField.commodityCode.props}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <Tooltip title="Use the info button to look this up at gov.uk">
                        <IconButton target="_blank" href="https://www.trade-tariff.service.gov.uk/sections">
                          <SvgIcon fontSize="large" component={InfoIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <FormRenderer {...formField.quantity.props} fullWidth />
                </Grid>
                <Grid item>
                  <Grid spacing={3} container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                      <InputButton
                        variant="outlined"
                        className="outlined-btn"
                        onClick={() => resetCommodityForm(resetForm, setFieldValue)}
                        label="Reset"
                        buttonType="reset"
                        isDisabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <InputButton
                        variant="contained"
                        className="contained-btn"
                        buttonType="submit"
                        label={operationType === 'updateCommodityDetails' ? 'Update' : 'Add'}
                        isDisabled={disblebtn}
                        onClick={() => resetbtnForm(values,errors)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
}
      </Grid>
    </div>
  );
};

AddCommodityDetails.propTypes = {};

export default AddCommodityDetails;

const getFormField2 = () =>{
  return {
    commodityCode: {
      props: {
        name: 'commodityCode',
        label: 'Commodity code',
        type: 'text',
        inputProps: {
          maxLength: 10,
        },
        isRequired: true,
        class_Name: 'commodity_Code',
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Commodity code is required'],
          },
          {
            type: 'matches',
            params: [/^[0-9]{10}$/, 'Commodity code should be a 10 digit number'],
          },
          {
            type: 'max',
            params: [10, 'Commodity code should be a 10 digit number'],
          },
        ],
      },
    },
    fullDescriptionOfGoods: {
      props: {
        name: 'fullDescriptionOfGoods',
        label: 'Description of goods',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 90,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [90, 'Maximum of 90  characters are allowed'],
          },
          {
            type: 'required',
            params: ['Description of goods is required'],
          },
          {
            type: 'min',
            params: [1, 'Minimum 1 character is required'],
          },
          {
            type: 'matches',
            params: [/(.*[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]){3}/i, 'Description must be at least 3 non blank characters'],
          },
        ],
      },
    },
    quantity: {
      props: {
        name: 'quantity',
        label: 'Unit Quantity',
        type: 'number',
        isRequired: true,
        inputProps: {
          max: 999,
          min: 1,
        },
      },
      validation: {
        validationType: 'number',
        name: 'quantity',
        validations: [
          {
            type: 'required',
            params: ['Quantity is required'],
          },
          {
            type: 'min',
            params: [1, 'Quantity must be greater than or equals to 1'],
          },
          {
            type: 'max',
            params: [999, 'Quantity must be 999 or less'],
          },
          {
            type: 'integer',
            params: ['Quantity should be a whole number'],
          },
        ],
      },
    },
    unitWeight: {
      props: {
        name: 'unitWeight',
        label: 'Unit Weight',
        type: 'number',
        isRequired: true,
        inputProps: {
          max: 999999,
          min: 0,
        },
      },
      validation: {
        validationType: 'number',
        name: 'unitWeight',
        validations: [
          {
            type: 'required',
            params: ['Unit Weight is required'],
          },
          {
            type: 'min',
            params: [0.01, 'Unit Weight must be greater than or equals to 0.1'],
          },
          {
            type: 'max',
            params: [999999.99, 'Unit Weight must be 999998.99 or less'],
          },
        ],
      },
    },
    unitValue: {
      props: {
        name: 'unitValue',
        label: 'Unit Value',
        type: 'number',
        isRequired: true,
        inputProps: {
          max: 999999,
          min: 0,
        },
      },
      validation: {
        validationType: 'number',
        name: 'unitValue',
        validations: [
          {
            type: 'required',
            params: ['Unit Value is required'],
          },
          {
            type: 'min',
            params: [0.01, 'Unit Value must be greater than or equals to 0.1'],
          },
          {
            type: 'max',
            params: [999999.99, 'Unit Value must be 999998.99 or less'],
          },
        ],
      },
    },
    countryOfOrigin: {
      props: {
        name: 'countryOfOrigin',
        label: 'Country of origin',
        type: 'select',
        displayField: 'name',
        isRequired: true,
      },
      validation: {
        validationType: 'object',
        name: 'countryOfOrigin',
        validations: [
          {
            type: 'required',
            params: ['Country of origin is required'],
          },

          {
            type: 'max',
            params: [2, 'Maximum of 2 characters allowed'],
          },
          {
            type: 'min',
            params: [2, 'Minimum of 2 characters allowed'],
          },
        ],
      },
    },
  };
}

const getFormField = (totalWeight) => {
  return {
    commodityCode: {
      props: {
        name: 'commodityCode',
        label: 'Commodity code',
        type: 'text',
        class_Name: 'commodity_Code',
        inputProps: {
          maxLength: 6,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'matches',
            params: [/^[0-9]{6}$/, 'Commodity code should be a 6 digit number'],
          },
          {
            type: 'max',
            params: [6, 'Commodity code should be a 6 digit number'],
          },
        ],
      },
    },
    fullDescriptionOfGoods: {
      props: {
        name: 'fullDescriptionOfGoods',
        label: 'Description of goods',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 90,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [90, 'Maximum of 90  characters are allowed'],
          },
          {
            type: 'required',
            params: ['Description of goods is required'],
          },
          {
            type: 'min',
            params: [1, 'Minimum 1 character is required'],
          },
          {
            type: 'matches',
            params: [/(.*[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]){3}/i, 'Description must be at least 3 non blank characters'],
          },
        ],
      },
    },
    quantity: {
      props: {
        name: 'quantity',
        label: 'Unit Quantity',
        type: 'number',
        isRequired: true,
        inputProps: {
          max: 999,
          min: 1,
        },
      },
      validation: {
        validationType: 'number',
        name: 'quantity',
        validations: [
          {
            type: 'required',
            params: ['Quantity is required'],
          },
          {
            type: 'min',
            params: [1, 'Quantity must be greater than or equals to 1'],
          },
          {
            type: 'max',
            params: [999, 'Quantity must be 999 or less'],
          },
          {
            type: 'integer',
            params: ['Quantity should be a whole number'],
          },
        ],
      },
    },
  };
};
