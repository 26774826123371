import React from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AddressFormate from '../../../../framework/addressFormate/AddressFormate';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';
import CustomHeader from '../../../../framework/summaryFormate/CustomHeader';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import ProductAndServiceSummary from './ProductAndServiceSummary';
import DetailsSummary from './DetailsSummary';
import AddShipmentAdditionalInfo from './AddShipmentAdditionalInfo';
// import { useSelector } from 'react-redux';
import { doorStepInstructionOptions } from '../../../../utils/constants/constants';
import CustomsDeclarationSummary from './CustomsDeclarationSummary';

const useStyles = makeStyles({
  boxContainer: {
    '& :last-child': {
      borderBottom: 'unset',
    },
  },
});

const DomesticSummary = ({ shipmentDetails }) => {
  const classes = useStyles();
  let history = useHistory();
  return (
    <React.Fragment>
      <Box boxShadow={3} bgcolor="background.paper" m={1} p={1} className={classes.boxContainer}>
        <CustomHeader heading="Details">
          <DetailsSummary shipmentDetails={shipmentDetails} />
        </CustomHeader>
        <CustomHeader heading="Delivery address">
          <Grid container spacing={3} direction="row" lg={6}>
            <AddressFormate
              addShipmentSummary={true}
              addressType={shipmentDetails.addressType}
              contactName={shipmentDetails.recipientName}
              businessName={shipmentDetails.businessName}
              addressLine1={shipmentDetails.addressLine1}
              addressLine2={shipmentDetails.addressLine2}
              addressLine3={shipmentDetails.addressLine3}
              town={shipmentDetails.town}
              county={shipmentDetails.county}
              postCode={shipmentDetails.postalCode}
              emailAddress={shipmentDetails.emailAddress}
              telephoneNumber={shipmentDetails.telephone}
              what3Words={shipmentDetails.what3Words}
              isDomestic={true}
            />
          </Grid>
        </CustomHeader>
        <CustomHeader heading="Delivery options">
          {shipmentDetails.deliveryType === 'deliverToDoorStep' ? (
            <Grid container item xs={12} sm={12} lg={12} spacing={3}>
              <Grid item xs={12} lg={3}>
                <CustomCaption title="Delivery type:" value={'Deliver to doorstep'} />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomCaption title="Doorstep instructions" value={shipmentDetails.doorStepInstructions.name} />
              </Grid>
              {shipmentDetails.doorStepInstructions.value === doorStepInstructionOptions[0].value && (
                <>
                  <Grid item xs={12} lg={3}>
                    <CustomCaption title="Special instructions" value={shipmentDetails.specialInstructions} />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <CustomCaption title="Additional instructions" value={shipmentDetails.additionalInstruction} />
                  </Grid>
                </>
              )}
              {shipmentDetails.doorStepInstructions.value === doorStepInstructionOptions[1].value && (
                <>
                  {shipmentDetails.exchange === 'no' ? (
                    <>
                      <Grid item xs={12} lg={3}>
                        <CustomCaption title="Special instructions" value={shipmentDetails.specialInstructions} />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <CustomCaption title="Additional instructions" value={shipmentDetails.additionalInstruction} />
                      </Grid>
                      {shipmentDetails.createPalletShipment !== 'yes' && (
                        <Grid item xs={12} lg={3}>
                          <CustomCaption
                            title="Exchange on delivery"
                            value={shipmentDetails.exchange === 'no' ? 'No' : 'Yes'}
                          />
                        </Grid>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} lg={3}>
                        <CustomCaption title="Special instructions" value={shipmentDetails.specialInstructions} />
                      </Grid>
                      {shipmentDetails.createPalletShipment !== 'yes' && (
                        <Grid item xs={12} lg={3}>
                          <CustomCaption
                            title="Exchange on delivery"
                            value={shipmentDetails.exchange === 'no' ? 'No' : 'Yes'}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}
              {shipmentDetails.doorStepInstructions.value === doorStepInstructionOptions[2].value && (
                <>
                  <Grid item xs={12} lg={3}>
                    <CustomCaption title="Special instructions" value={shipmentDetails.specialInstructions} />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <CustomCaption title="Additional instructions" value={shipmentDetails.additionalInstruction} />
                  </Grid>
                  <Grid className="previewDeliveryOption" item xs={12} lg={3}>
                    <CustomCaption title="Leave safe location" value={shipmentDetails.leaveSafeLocation} />
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <Grid container item xs={12} sm={12} lg={12} spacing={3}>
              <Grid item xs={12} lg={3}>
                <CustomCaption title="Delivery type:" value={'Deliver to ServicePoint'} />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomCaption title="Delivery ServicePoint" value={shipmentDetails.deliveryServicePoint} />
              </Grid>
              <Grid item xs={12} lg={3}>
                <CustomCaption title="Special instructions" value={shipmentDetails.specialInstructions} />
              </Grid>
              <Grid className="previewDeliveryOption" item xs={12} lg={3}>
                <CustomCaption title="Additional instructions" value={shipmentDetails.additionalInstruction} />
              </Grid>
            </Grid>
          )}
        </CustomHeader>
        <CustomHeader heading="Product and service">
          <ProductAndServiceSummary shipmentDetails={shipmentDetails} />
        </CustomHeader>
        <CustomHeader heading="Additional information about your shipment">
          <AddShipmentAdditionalInfo shipmentDetails={shipmentDetails} />
        </CustomHeader>
        {shipmentDetails?.accountNumber?.returnConfiguration?.inboxReturnAllowed &&
        shipmentDetails?.product?.deliveryCapabilities?.inBoxReturn ? (
          <CustomHeader heading="Return Options" style={{ borderBottom: 'unset' }}>
            <Grid container item xs={12} sm={12} lg={12} spacing={3}>
              <Grid item xs={6} lg={6}>
                <Typography variant="body2">
                  {shipmentDetails.inboxReturnLabel !== ''
                    ? `Include inbox return label: ${shipmentDetails.inboxReturnLabel === 'true' ? 'Yes' : 'No'}`
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="body2">
                  {shipmentDetails.returnReference !== '' && shipmentDetails.returnReference !== undefined
                    ? `Return reference : ${shipmentDetails.returnReference}`
                    : null}
                </Typography>
              </Grid>
            </Grid>
          </CustomHeader>
          
        ) : null}
          <CustomHeader heading="Clearance declaration">
          {/* {shipmentDetails.isFullCustomDetails &&  */}
              <CustomsDeclarationSummary shipmentDetails={shipmentDetails} />
           {/* } */}
        </CustomHeader>
      </Box>
      <Grid
        className="preview-btn-container"
        container
        spacing={3}
        direction="row"
        justify="flex-end"
        alignItems="flex-end"
      >
        <Grid item>
          <InputButton
            variant="contained"
            className="contained-btn"
            label="Back"
            onClick={() =>
              history.push({
                pathname: history.location.state.prevPath,
                state: { ...history.location.state, fromPreview: true },
              })
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DomesticSummary;
