import React from 'react';

export const ServicePointError = 'Service points not found for given postal code';
export const MIN_SEARCH_RESULTS_REQUIRED = 10;
export const UK_POSTAL_CODE_REGEX = /^(GIR[ ]?0AA)|((AB|AL|B|BA|BB|BD|BF|BH|BL|BN|BR|BS|BT|BX|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|XX|YO|ZE)(\d?[\dA-Z]?[ ]?\d?[ABD-HJKLN-UW-Z]{2})|BFPO[ ]?\d{1,4})$/;
export const DOMESTIC_PRODUCTS = ['ukDomestic'];
export const INTERNATIONAL_ROAD_PRODUCTS = ['internationalRoad'];
export const PHONE_REGEX = /^[a-zA-Z0-9+]*$/;
export const NoOfRows = 5;

export const parcelHandoverMethods = {
  driverCollection: 1,
  servicePoint: 2,
};

export const reverseHandOverMethod = {
  1: 'driverCollection',
  2: 'servicePoint',
};

export const recipientType = {
  business: 1,
  residential: 2,
};
export const reverseRecipientType = {
  1: 'business',
  2: 'residential',
};
export const deliveryType = {
  deliverToDoorStep: 1,
  deliverToServicePoint: 2,
};
export const reverseDeliveryType = {
  1: 'deliverToDoorStep',
  2: 'deliverToServicePoint',
};
export const invoiceType = {
  commercial: 1,
  proforma: 2,
};
export const reverseInvoiceType = {
  1: 'commercial',
  2: 'proforma',
};

export const doorStepInstructionOptionForcedAGE = [
  {
    value: 'deliverToDoorStepWithAge',
    key: 'AGE',
    name: 'Deliver to doorstep with AGE verification',
  }
]
export const doorStepInstructionOptionForcedPIN = [
  {

    value: 'deliverToDoorStepWithPin',
    key: 'PIN',
    name: 'Deliver to doorstep with PIN',
  }
]

export const doorStepInstructionOptionsOld = [

  {
    value: 'deliverToNeighbour',
    key: '',
    name: 'Deliver to neighbour if no answer at doorstep',
  },
  {
    value: 'deliverToDoorStepOnly',
    key: '',
    name: 'Deliver to doorstep only',
  },
  {
    value: 'leaveSafe',
    key: '',
    name: 'Leave safe if no answer at doorstep',
  },

];

export const doorStepInstructionOptions = [
  {
    value: 'deliverToDoorStepWithAge',
    key: 'AGE',
    name: 'Deliver to doorstep with AGE verification',
  },
  {
    value: 'deliverToDoorStepWithPin',
    key: 'PIN',
    name: 'Deliver to doorstep with PIN',
  },
  {
    value: 'deliverToDoorStepOnly',
    key: '',
    name: 'Deliver to doorstep only',
  },
  {
    value: 'deliverToNeighbour',
    key: '',
    name: 'Deliver to neighbour if no answer at doorstep',
  },
  {
    value: 'leaveSafe',
    key: '',
    name: 'Leave safe if no answer at doorstep',
  },

];
export const shipmentMovementOptions = [
  {
    value: 'b2b',
    name: 'B2B',
  },
  {
    value: 'b2c',
    name: 'B2C',
  },
  {
    value: 'c2b',
    name: 'C2B',
  },
  {
    value: 'c2c',
    name: 'C2C',
  },
];

export const reasonOptions = [
  {
    value: 'gift',
    name: 'Gift',
  },
  {
    value: 'commercialSample',
    name: 'Commercial Sample',
  },
  {
    value: 'commercialSale',
    name: 'Commercial Sale',
  },
];
export const doorStepInstructionOptionswithSelect = [
  {
    value: '',
    name: 'Select',
  },
  {
    value: 'deliverToNeighbour',
    name: 'Deliver to neighbour if no answer at doorstep',
  },
  {
    value: 'deliverToDoorStepOnly',
    name: 'Deliver to doorstep only',
  },
  {
    value: 'leaveSafe',
    name: 'Leave safe if no answer at doorstep',
  },
  {
    value: 'deliverToDoorStepWithAge',
    name: 'Deliver to doorstep with AGE verification',
  },
  {
    value: 'deliverToDoorStepWithPin',
    name: 'Deliver to doorstep with pin',
  },
];

export const reasonForExportOptions = [
  {
    value: 6,
    label: 'Commercial sale',
  },
  {
    value: 3,
    label: 'Commercial sample',
  },
  {
    value: 1,
    label: 'Gift',
  },
  {
    value: 4,
    label: 'Returned goods',
  },
  {
    value: 2,
    label: 'Documents',
  },
  {
    value: 5,
    label: 'Repairs',
  },
];

export const limitReasonForExport = [
  {
    value: 6,
    label: 'Commercial sale',
  },

  {
    value: 1,
    label: 'Gift',
  },
];


export const constantTransferActionTypes = {
  1: 'transferToHoldingArea',
  2: 'postWithLabels',
};

export const reverseTransferActionTypes = {
  transferToHoldingArea: 1,
  postWithLabels: 2,
};

export const ADDRESSBOOK_MODAL_CONTENT = (addressType) => {
  // if (addressType === 'domestic') {
  //   return (
  //     <React.Fragment>
  //       <p>
  //         <b>Import address from .CSV file</b>
  //       </p>
  //       <p>
  //         <b>The file must be in csv format and should consist of the following fields in the following order:</b>
  //       </p>
  //       <b>Customer number,Contact name,</b> Business name, <b>Address1,</b> Address2, Address3, <b>Town,</b> County,{' '}
  //       <b>Postal code, Telephone number,</b> Email address, Weight, Items, Customer reference, Alternative
  //       reference, Extended liability, Pre delivery notification, Leave safe location, Special instructions 1, Special
  //       instructions 2{' '}
  //       <p>
  //         Mandatory fields are in <b>bold.</b>
  //       </p>
  //       <p><b>Note: Address book having rows up to 2000 are allowed, if the number of rows are more the file upload may fail.</b></p>
  //     </React.Fragment>
  //   );
  // } else if (addressType === 'international') {
  //   return (
  //     <React.Fragment>
  //       <p>
  //         <b>Import address from .CSV file</b>
  //       </p>
  //       <p>
  //         <b>The file must be in csv format and should consist of the following fields in the following order:</b>
  //       </p>
  //       <b>Customer number,</b> Is Business, <b>Receiver name,</b> Company name,{' '}
  //       <b>Street address and House no, Town,</b> County,
  //       <b> Zipcode, Country, Local telephone number, Local email address,</b> Weight (Kg), Length (cm), Width (cm),
  //       Height (cm), Customer reference, Alternative reference, Description1, Description2, Description3, Value,
  //       Currency code{' '}
  //       <p>
  //         Mandatory fields are in <b>bold.</b>
  //       </p>
  //       <p><b>Note: Address book having rows up to 2000 are allowed, if the number of rows are more the file upload may fail.</b></p>
  //     </React.Fragment>
  //   );
  // } else if (addressType === 'consignor') {
  //   return (
  //     <React.Fragment>
  //       <p>
  //         <b>Import address from .TXT file</b>
  //       </p>
  //       <p>
  //         <b>
  //           The file must be in txt format, should be tilde(~) delimited and should consist of the following fields in
  //           the following order:
  //         </b>
  //       </p>
  //       <b>Customer number, Customer name, Address1,</b> Address2, Locality, <b>Town,</b> County, <b>Postcode,</b> Postcode Valid,{' '}
  //       <b>Country,</b> Telephone, Email, Default special instruction 1, Default special instruction 2, Contact{' '}
  //       <p>
  //         Mandatory fields are in <b>bold.</b>
  //       </p>
  //       <p><b>Note: Address book having rows up to 2000 are allowed, if the number of rows are more the file upload may fail.</b></p>
  //     </React.Fragment>
  //   );
  // } 
  if (addressType === 'ship') {
    return (
      <React.Fragment>
        <p>
          <b>Import address from .CSV file</b>
        </p>
        <p>
          <b>The file must be in .CSV format consist of the following fields in the following order:</b>
        </p>
        <b>Customer number,</b> Is Business, <b>Recipient name,</b> Business name, <b>Address1,</b> Address2, Address3,{' '}
        <b> Town, </b>County, <b>Postal code, Country,</b> Telephone number, <b>Email address</b>, Items, Weight (Kg),
        Customer reference, Alternative reference, Extended liability, Leave safe location, Special instructions 1,
        Special instructions 2, Length (cm), Width (cm), Height (cm), Value, Currency{' '},
        Collection instructions, Description of good to be collected, What3words
        <p>
          Mandatory fields are in <b>bold.</b>
        </p>
        <p><b>Note: Address book having rows up to 2000 are allowed, if the number of rows are more the file upload may fail.</b></p>
      </React.Fragment>
    );
  }
};

export const fullSearchByOptions = [
  {
    value: 'customernumber',
    name: 'Customer number',
  },
  {
    value: 'recipientname',
    name: 'Recipient name',
  },
  {
    value: 'businessname',
    name: 'Business name',
  },
  {
    value: 'town',
    name: 'Town',
  },
  {
    value: 'postcode',
    name: 'Postal code',
  },
];

export const deliveryStatusForDomestic = [
  {
    value: 'today&yesterday',
    name: 'Collected yesterday and today',
  },
];

export const internationalProductData = [
  // {
  //   value: '',
  //   name: 'select',
  // },
  {
    value: 'Premier 24',
    name: 'Premier 24',
  },
  {
    value: 'Premier 48',
    name: 'Premier 48',
  },
  {
    value: 'Dhl eCommerce Connect ',
    name: 'Dhl eCommerce Connect',
  },
  {
    value: 'worldwideair',
    name: 'Worldwide Air',
  },
  {
    value: 'Parcelinternational',
    name: 'Parcelinternational',
  },
  {
    value: 'economyroad',
    name: 'Economy road',
  },
];
export const domesticProductData = [
  {
    value: 'Parcels',
    name: 'Parcels',
  },
  {
    value: 'bagit',
    name: 'Bagit',
  },
  {
    value: 's',
    name: 'S',
  },
  {
    value: 'm',
    name: 'M',
  },
  {
    value: 'l',
    name: 'L',
  },
  {
    value: 'xl',
    name: 'XL',
  },
];

export const domesticServiceData = [
  // {
  //   value: '',
  //   name: 'select',
  // },
  {
    value: 'Next Day',
    name: 'Next Day',
  },
  {
    value: 'nextdayby&12',
    name: 'Next Day by 12',
  },
  {
    value: 'nextdayby&10:30AM',
    name: 'Next Day by 10:30 AM',
  },
  {
    value: 'Tommorow',
    name: 'Tommorow',
  },
  {
    value: 'Saturday',
    name: 'Saturday',
  },
  {
    value: 'saturdayby&10:30AM',
    name: 'Saturday by 10:30 AM',
  },
  {
    value: '48 hours',
    name: '48 hours',
  },
];

export const shipmentManagementDateFilter = [
  {
    value: 'today',
    name: 'Today',
  },
  {
    value: 'daterange',
    name: 'Date range',
  },
];

export const deliveryStatusForDashboard = [
  {
    value: 'today&yesterday',
    name: 'Collected today & yesterday',
  },

  {
    value: 'thisweek',
    name: 'This week',
  },
  {
    value: 'thismonth',
    name: 'This month',
  },
  {
    value: 'previousmonth',
    name: 'Previous month',
  },
  {
    value: 'daterange',
    name: 'Date range',
  },
];

export const fullSearchDashboardByOptions = [
  {
    value: 'shipmentNumber',
    name: 'Shipment number',
  },
  {
    value: 'customerRefNumber',
    name: 'Customer reference',
  },
  {
    value: 'recipientName',
    name: 'Recipient name',
  },
  {
    value: 'businessName',
    name: 'Business name',
  },
  {
    value: 'address',
    name: 'Address',
  },
  {
    value: 'postCode',
    name: 'Postal code',
  },
  {
    value: 'email',
    name: 'Email address',
  },
  {
    value: 'telephoneNumber',
    name: 'Telephone number',
  },
  {
    value: 'deliveryDueDate',
    name: 'Delivery due date',
  },
  {
    value: 'dispatchDate',
    name: 'Dispatch date',
  },
];

export const ReturnTypes = [
  {
    value: 'returnToSender',
    label: 'Return to sender',
  },
  {
    value: 'sendToThirdParty',
    label: 'Send to 3rd party',
  },
];

export const changeDeliveryType = [
  { name: 'Safe place location', value: 'Safe place location' },
  { name: 'Leave with neighbour', value: 'Leave with neighbour' },
  { name: 'Deliver to Doorstep only', value: 'Deliver to Doorstep only' },
  { name: 'Deliver to doorstep with PIN', value: 'Deliver to doorstep with PIN' },
  { name: 'Deliver to doorstep with AGE verification', value: 'Deliver to doorstep with AGE verification' },
  { name: 'Deliver to local DHL eCommerce UK Depot with PIN', value: 'Deliver to local DHL eCommerce UK Depot with PIN' },
  { name: 'Deliver to local DHL eCommerce UK Depot with AGE Verification', value: 'Deliver to local DHL eCommerce UK Depot with AGE Verification' },
  { name: 'Deliver to local DHL eCommerce UK depot', value: 'Deliver to local DHL eCommerce UK depot' },
];

export const schemaValues = [
  {
    value: 'order',
    name: 'Orders schema',
  },
  {
    value: 'custom',
    name: 'Customs schema',
  },
  {
    value: 'collection',
    name: 'Collections schema',
  },
  {
    value: 'parcel',
    name: 'Parcels schema',
  },
  {
    value: 'orders+customs',
    name: 'Orders + Customs schema',
  },
  {
    value: 'orders+collections',
    name: 'Orders + Collections schema',
  },
  {
    value: 'orders+customs+collections',
    name: 'Orders + Customs + Collections schema',
  },
  {
    value: 'orders+customs+parcels',
    name: 'Orders + Customs + Parcels schema',
  },
  {
    value: 'order+collectionsConsignor',
    name: 'Orders + Collections Consignor schema',
  },
  {
    value: 'clearance',
    name: 'Clearance schema',
  },
  {
    value: 'orders+clearance',
    name: 'Orders + Clearance schema',
  },
  {
    value: 'collections+clearance',
    name: 'Collections + Clearance schema',
  }
];

export const importTypeValues = [
  {
    value: 'fixedlength',
    label: 'Fixed length',
  },
  {
    value: 'delimited',
    label: 'Delimited',
  },
];

export const dateOptions = [
  {
    value: 'd/M/yyyy',
    name: 'DD/MM/YYYY',
  },
  {
    value: 'M/d/yyyy',
    name: 'MM/DD/YYYY',
  },
  {
    value: 'other',
    name: 'Other',
  },
];

export const delimiterOptions = [
  {
    value: ',',
    name: 'Comma',
  },
  {
    value: '~',
    name: 'Tilde',
  },
  {
    value: '|',
    name: 'Pipe',
  },
  {
    value: '\\t',
    name: 'Tab',
  },
  {
    value: ';',
    name: 'Semicolon',
  },
  {
    value: 'otherDelimiter',
    name: 'Other',
  },
];
export const mappingCategoryByOptions = [
  {
    value: 'accounts',
    name: 'Accounts',
  },
  {
    value: 'services',
    name: 'Services',
  },
];

export const mappingCategoryForMarketplaces = [
  {
    value: 'services',
    name: 'Services',
  },
];

export const safePlaceLocationData = [
  { name: 'Porch', value: '0' },
  { name: 'Garage', value: '3' },
  { name: 'Greenhouse', value: '6' },
  { name: 'Behind the gate', value: '1' },
  { name: 'With Porter / Caretaker', value: '4' },
  { name: 'Shed', value: '2' },
  { name: 'Conservatory', value: '5' },
  {
    name:
      'If you are self isolating, part of an at risk group or disabled, please tell us about any accessibility delivery needs you have',
    value: '8',
  },
  { name: 'Other', value: '7' },
];

export const filedToSearchByOptions = [
  {
    name: 'Select',
    value: '',
  },
  {
    value: 'customerReference',
    name: 'Customer reference',
  },
  {
    value: 'alternateReference',
    name: 'Alternative reference',
  },
];

export const labelType = [
  { name: 'Select', value: '' },
  { name: 'ZPL', value: 1 },
  { name: 'PDF', value: 2 },
];
export const labelSize = [
  { name: 'Select', value: '' },
  { name: '6*4', value: 0 },
  { name: 'A4', value: 1 },
];
export const labelSizeWhenZpl = [
  { name: '6*4', value: 0 },
];

export const UserRoles = {
  Admin: 'Admin',
  SuperUser: 'SuperUser',
  Reporting: 'Reporting',
  Supervisor: 'Supervisor',
  ShipmentEntry: 'Shipment Entry',
  ShipmentEntryReporting: 'Shipment Entry & Reporting',
  ReportingShipmentEntry: 'Reporting & Shipment Entry',
  NationalSuperUser: "National SuperUser",
  BasicShipmentEntry: "Basic Shipment Entry",
  RetailSuperUser: "Retail SuperUser",
  BasicShipmentEntryAndReporting: "Basic Shipment Entry & Reporting"
};

export const roleOptions = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'SuperUser',
    label: 'SuperUser',
  },
  {
    value: 'Supervisor',
    label: 'Supervisor',
  },
  {
    value: 'Shipment Entry',
    label: 'Shipment Entry',
  },
  {
    value: 'Reporting & Shipment Entry',
    label: 'Reporting & Shipment Entry',
  },
  {
    value: 'Reporting',
    label: 'Reporting',
  },
  {
    value: 'Retail SuperUser',
    label: 'Retail SuperUser',
  },
  {
    value: 'National SuperUser',
    label: 'National SuperUser',
  },
  {
    value: 'Basic Shipment Entry',
    label: 'Basic Shipment Entry',
  },
];

export const UserManagementSearch = [
  {
    value: 'userName',
    name: 'User name'
  },
  {
    value: 'userEmail',
    name: "User's email id"
  }
]

export const EmailParam = {
  fromEmailAddress: 'communication@dhlecommerce.co.uk',
  subject: 'DHL eCommerce UK labels for parcel collection',
  body:
    "Please find attached the labels for the parcels due to be collected by DHL eCommerce UK on (date). There is a separate label for each parcel to be collected. Please ensure that a label is securely attached to each parcel prior to the driver collection. </br></br></br><span style='font-size: smaller;'>DHL eCommerce UK Ltd. Company number 00965783. Registered office address 120 Buckingham Avenue, Slough, SL1 4LZ</span>",
  type: 'application/pdf',
  fileName: 'attachment.pdf',
  displayRecipients: false,
};

export const MAX_ROWS_FOR_SCHEMA = 4;

export const reasonsForExportList = [
  {
    description: "Commercial Sale",
    type: "commercialSale"
  },
  {
    description: "Gift",
    type: "gift"
  },
  {
    description: "Commercial Sample",
    type: "commercialSample"
  },
];

export const currenciesMarketplaces = [
  { code: "EUR", name: "EURO", symbol: "null" },
  { code: "GBP", name: "UK Pounds Sterling", symbol: "null" },
  { code: "USD", name: "US DOLLARS", symbol: "null" },
];

export const TermsOfDelivery = [
  { value: "DAP", label: "DAP" },
  { value: "DDP", label: "DDP" },
];

export const deliveryChecksforPINAndAGE = {

  "allowed": 1,
  "not_allowed": 0,
  "forced": 2
}

// For Amend ServicePoint Dims & Weight
export const AMEND_SERVICE_POINT = {
  ADDRESS_TYPE : "servicePoint",
  PRO_CODE_210: 210,
  PRO_CODE_220: 220,
  PRO_CODE_1: 1
};


export const DELIVERY_CHOICES = {
  LEAVE_NEIGHBOUR: 'YYY',
  DOORSTEP_ONLY: 'NYN',
  SAFE_LOCATION: 'YYY/SAFE',
  DOORSTEP_AGE_VERIFICATION: 'NYN/AGE',
  DOORSTEP_PIN: 'NYN/PIN',
  DEPOT_PIN: 'NNN/DPI',
  DEPOT: 'NNN/Depot',
  DEPOT_AGE_VERIFICATION: 'NNN/DAG',
  SERVICE_POINT: 'NNN/SP',
  SAFE_LOCATION_RESCHEDULED: 'NYN/SAFE',
  AGE: 'AGE',
  PIN: 'PIN',
};

export const DELIVERY_TYPES = {
  SERVICE_POINT: 'Deliver to local ServicePoint',
  LEAVE_NEIGHBOUR: 'Leave with neighbour',
  SAFE_LOCATION: 'Safe place location',
  DEPOT: 'Deliver to local DHL eCommerce UK depot',
  DEPOT_PIN: 'Deliver to local DHL eCommerce UK Depot with PIN',
  DEPOT_AGE_VERIFICATION: 'Deliver to local DHL eCommerce UK Depot with AGE Verification',
  DOORSTEP_ONLY: 'Deliver to Doorstep only',
  DOORSTEP_PIN: 'Deliver to doorstep with PIN',
  DOORSTEP_AGE_VERIFICATION: 'Deliver to doorstep with AGE verification',
};

export const DELIVERY_STATUS = {
  NULL: null,
  NONE: 'NONE',
  AGE: 'AGE',
  PIN: 'PIN',
};

export const DELIVERY_OPTIONS = {
  DELIVERY_LEAVE_NEIGHBOUR: 'deliverToNeighbour',
  DELIVERY_SAFE_LOCATION: 'leaveSafe',
  DELIVERY_DOORSTEP_ONLY: 'deliverToDoorStepOnly',
  DELIVERY_DOORSTEP_PIN: 'deliverToDoorStepWithPin',
  DELIVERY_DOORSTEP_AGE_VERIFICATION: 'deliverToDoorStepWithAge',
};

export const DELIVER_TYPE = {
  DELIVER_DOORSTEP: 'deliverToDoorStep',
  DELIVER_SERVICE_POINT: 'deliverToServicePoint',
  DEPOT: 'depot'
};

export const MISC_VALUES = {
  YES: 'yes',
  MAX: 'max',
  MIN: 'min',
  REQUIRED: 'required',
  SUMMARY: 'fromPreviewSummary',
  HOUSE_NO: 'House no/name:',
  ONE: 1,
  THIRTY: 30,
  SIXTY: 60,
  COUNTRY_CODE_GB: 'GB',
  DELIVERY_TYPE: 'deliveryType',
  ALL_ACCOUNTS: 'All_Accounts',
  THIS_MONTH: 'thismonth',
  PREVIOUS_MONTH: 'previousmonth',
  THIS_WEEK: 'thisweek',
  DOMESTIC: 'domestic',
  DASHBOARD_DETAILS: 'dashboardDetails',
  DATE_RANGE: 'daterange',
  SELECT: 'select',
  ACCOUNTS_SELECTED: 'accounts selected',
  ACCOUNT_SELECTED: 'account selected',
  SERVICE_POINT_HANDOVER_METHOD: 'servicePoint',
  DRIVER_COLLECTION_HANDOVER_METHOD: 'driverCollection',
  RESIDENTIAL_ADDRESS_TYPE: 'residential',
  COMMERCIAL_ADDRESS_TYPE: 'Commercial',
  BUSINESS_ADDRESS_TYPE: 'business',
  CUSTOMER_NUMBER:'customernumber',
  DOORSTEP_ONLY: 'doorstepOnly',
  DELIVER_TO_NEIGHBOUR: 'deliverToNeighbour',
  NEIGHBOUR_DELIVERY: 'neighbourDelivery',
  LEAVE_SAFE: 'leaveSafe',
  SECURE_LOCATION_DELIVERY: 'secureLocationDelivery',
  DELIVER_DOORSTEP_ONLY: 'deliverToDoorStepOnly',
  ACCOUNT_BUSINESS: 'Business',
  ACCOUNT_RESIDENTIAL: 'Residential',
  SEND_TO_THIRD_PARTY: 'sendToThirdParty',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  ARRAY: 'array',
  GBR: 'GBR',
  MARKETNO: 'marketNo',
  USA: 'USA',
  GY: 'GY',
  JE: 'JE',
  F2: 'f2',
  ESC: 'esc',
  DROPDOWNRESPONSE: 'dropdownResponse',
};

export const CLERANCE_DECLARATION = {
  SHIPMENT_MOVEMENT_TYPE: 'Shipment Movement Type:',
  TOTAL_VALUE: 'Total Value',
  SHIPPER_UKIMS_NUMBER: 'Shipper UKIMS number',
  SHIPPER_EORI_NUMBER: 'Shipper EORI number',
  GOODS_DESCRIPTION: 'Goods description',
  RECIPIENT_UKIMS_NUMBER: 'Recipient UKIMS number',
  RECIPIENT_EORI_NUMBER: 'Recipient EORI number',
  SHIPPING_CHARGES: 'Shipping charges',
  REASON_FOR_EXPORT: 'Reason for export',
  SHIPPER_DEFERMENT_NUMBER: 'Shipper deferment number',
  COUNTRY_CODE_BT: 'BT',
  DASHBOARD_BUTTON_VIEW: 'View Clearance Declaration details',
  DASHBOARD_HEADING: 'Clearance declaration',
  CATCH_MSG_TYPE: 'error',
  CATCH_MSG: 'Error in fetching clearance declaration details',
  CUSTOM_DECLARATION: 'Full',
  PRODUCT_ACCOUNT_TYPE: 'ukDomestic',
  MOVEMENT_TYPE_B2B_VALUE: 'b2b',
  MOVEMENT_TYPE_C2C_VALUE: 'c2c',
  MOVEMENT_TYPE_C2B_VALUE: 'c2b',
  MOVEMENT_TYPE_B2C_VALUE: 'b2c',
};
