import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';
import CommodityDetailsSummary from './CommodityDetailsSummary';
import { formatDate } from '../../../../utils/helperFunctions';

const useStyles = makeStyles({
  flexContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  rowDiff: {
    marginTop: '5px',
    marginBottom: '10px',
  },
});

const CustomsDeclarationSummary = ({ shipmentDetails }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid>
        <Grid item xs={12} lg={12}>
          <Typography m={2} className={classes.rowDiff}>
            {(shipmentDetails || {}).shipmentMovement !== ''
              ? `Shipment Movement Type:  ${(shipmentDetails || {}).shipmentMovement.name}`
              : null}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
          className={classes.rowDiff}
        >
          {(shipmentDetails || {}).descriptionOfGoods !== '' && (
            <Grid item className={classes.flexContainer}>
              <CustomCaption title="Description of goods" value={(shipmentDetails || {}).descriptionOfGoods} />
            </Grid>
          )}
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Total Value" value={(shipmentDetails || {}).clearanceTotalValue} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Shipper UKIMS" value={(shipmentDetails || {}).shipperUKIMS} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Shipper EORI" value={(shipmentDetails || {}).shipperEORI} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Receipent EORI" value={(shipmentDetails || {}).receipentEORI} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Receipent UKIMS" value={(shipmentDetails || {}).receipentUKIMS} />
          </Grid>
        </Grid>
       
        <CommodityDetailsSummary shipmentDetails={shipmentDetails} className={classes.rowDiff} />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
          className={classes.rowDiff}
        >
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Shipping Charges" value={(shipmentDetails || {}).shippingCharges} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Reason For Export" value={(shipmentDetails || {}).reasonExport.value} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Shipper Deferment Account" value={(shipmentDetails || {}).shipperDeferment} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CustomsDeclarationSummary;
