import React, { useState } from 'react';
import SchemaMappingGrid from './SchemaMappingGrid';
import SchemaMaterialTable from './SchemaMaterialTable';

const SchemaTabCreations = (props) => {
  const {
    formField,
    formikValues,
    mappingDetails,
    getFieldSchemaDetails,
    requiredField,
    setIsValid,
    dropdownValues,
    setCurrentValue,
    setCurrentValueStartLength,
    setCurrentRowIndicatorValue,
    setCurrentRowIndicatorPlacement,
    isClear,
    setFieldValue,
    currentTab,
    setTabValue,
    setIsClear,
    setFieldTouched
  } = props;

  return (
    <div>
      {(formikValues.schemaType || {}).value === 'order'
        ? (
          <SchemaMappingGrid
            {...props}
            firstTab="Orders mapping"
            typeOfSchema="order"
            multiTab={false}
            orderValues={orderValues.filter(value => value.valueName !== "rowindicator")}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValue={setCurrentValue}
            setCurrentValueStartLength={setCurrentValueStartLength}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setIsClear={setIsClear}
          />
        )
        : (
          ''
        )}
      {(formikValues.schemaType || {}).value === 'parcel'
        ? (
          <SchemaMappingGrid
            {...props}
            firstTab="Parcels mapping"
            typeOfSchema="parcel"
            multiTab={false}
            orderValues={parcelValues.filter(value => value.valueName !== "rowindicator")}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValue={setCurrentValue}
            setCurrentValueStartLength={setCurrentValueStartLength}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setIsClear={setIsClear}
          />
        )
        : (
          ''
        )}
         {(formikValues.schemaType || {}).value === 'clearance'
        ? (
          <SchemaMappingGrid
            {...props}
            firstTab="Clearance mapping"
            typeOfSchema="clearance"
            multiTab={false}
            orderValues={clearanceValues.filter(value => value.valueName !== "rowindicator")}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValue={setCurrentValue}
            setCurrentValueStartLength={setCurrentValueStartLength}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setIsClear={setIsClear}
          />
        )
        : (
          ''
        )}
      {(formikValues.schemaType || {}).value === 'orders+customs'
        ? (
          <SchemaMappingGrid
            {...props}
            formField={formField}
            customsValues={customsValues}
            orderValues={orderValues}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            firstTab="Orders mapping"
            secTab="Customs mapping"
            thirdTab=""
            setCurrentValue={setCurrentValue}
            multiTab={true}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValueStartLength={setCurrentValueStartLength}
            setCurrentRowIndicatorValue={setCurrentRowIndicatorValue}
            setCurrentRowIndicatorPlacement={setCurrentRowIndicatorPlacement}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setTabValue={setTabValue}
            currentTab={currentTab}
            setIsClear={setIsClear}
          />
        )
        : (
          ''
        )}
      {(formikValues.schemaType || {}).value === 'orders+collections'
        ? (
          <SchemaMappingGrid
            {...props}
            formField={formField}
            customsValues={collectionValues}
            orderValues={orderValues}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            firstTab="Orders mapping"
            secTab="Collections mapping"
            thirdTab=""
            setCurrentValue={setCurrentValue}
            multiTab={true}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValueStartLength={setCurrentValueStartLength}
            setCurrentRowIndicatorValue={setCurrentRowIndicatorValue}
            setCurrentRowIndicatorPlacement={setCurrentRowIndicatorPlacement}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setTabValue={setTabValue}
            currentTab={currentTab}
            setIsClear={setIsClear}
          />
        )
        : (
          ''
        )}
      
         {(formikValues.schemaType || {}).value === 'orders+clearance'
        ? (
          <SchemaMappingGrid
            {...props}
            formField={formField}
            customsValues={clearanceValues}
            orderValues={orderValues}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            firstTab="Orders mapping"
            secTab="Clearance mapping"
            thirdTab=""
            setCurrentValue={setCurrentValue}
            multiTab={true}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValueStartLength={setCurrentValueStartLength}
            setCurrentRowIndicatorValue={setCurrentRowIndicatorValue}
            setCurrentRowIndicatorPlacement={setCurrentRowIndicatorPlacement}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setTabValue={setTabValue}
            currentTab={currentTab}
            setIsClear={setIsClear}
          />
        )
        : (
          ''
        )}
         {(formikValues.schemaType || {}).value === 'collections+clearance'
        ? (
          <SchemaMappingGrid
            {...props}
            formField={formField}
            customsValues={clearanceValues}
            orderValues={[...collectionValues, ...collectionClearanceValues]}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            firstTab="Collections mapping"
            secTab="Clearance mapping"
            thirdTab=""
            setCurrentValue={setCurrentValue}
            multiTab={true}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValueStartLength={setCurrentValueStartLength}
            setCurrentRowIndicatorValue={setCurrentRowIndicatorValue}
            setCurrentRowIndicatorPlacement={setCurrentRowIndicatorPlacement}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setTabValue={setTabValue}
            currentTab={currentTab}
            setIsClear={setIsClear}
          />
        )
        : (
          ''
        )}
      {(formikValues.schemaType || {}).value === 'orders+customs+collections'
        ? (
          <SchemaMappingGrid
            {...props}
            formField={formField}
            collectionValues={collectionValues}
            customsValues={customsValues}
            orderValues={orderValues}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            firstTab="Orders mapping"
            secTab="Customs mapping"
            thirdTab="Collections mapping"
            multiTab={true}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValue={setCurrentValue}
            setCurrentValueStartLength={setCurrentValueStartLength}
            setCurrentRowIndicatorValue={setCurrentRowIndicatorValue}
            setCurrentRowIndicatorPlacement={setCurrentRowIndicatorPlacement}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setTabValue={setTabValue}
            currentTab={currentTab}
            setIsClear={setIsClear}
          />
        )
        : (
          ''
        )}

      {(formikValues.schemaType || {}).value === 'orders+customs+parcels'
        ? (
          <SchemaMappingGrid
            {...props}
            formField={formField}
            typeOfSchema="orders+customs+parcels"
            collectionValues={parcelValues}
            customsValues={customsValues}
            orderValues={orderValues.filter(value => value.valueName !== "Length" && value.valueName !== "Width" && value.valueName !== "Height")}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            firstTab="Orders mapping"
            secTab="Customs mapping"
            thirdTab="Parcels mapping"
            multiTab={true}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValue={setCurrentValue}
            setCurrentValueStartLength={setCurrentValueStartLength}
            setCurrentRowIndicatorValue={setCurrentRowIndicatorValue}
            setCurrentRowIndicatorPlacement={setCurrentRowIndicatorPlacement}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setTabValue={setTabValue}
            currentTab={currentTab}
            setIsClear={setIsClear}
          />
        )
        : (
          ''
        )}
      {(formikValues.schemaType || {}).value === 'order+collectionsConsignor'
        ? (
          <SchemaMappingGrid
            {...props}
            firstTab="Orders mapping"
            typeOfSchema="orderCollectionConsignor"
            multiTab={false}
            orderValues={orderCollectionConsignor}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValue={setCurrentValue}
            setCurrentValueStartLength={setCurrentValueStartLength}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setIsClear={setIsClear}

          />
        )
        : (
          ''
        )}
      {(formikValues.schemaType || {}).value === 'collection'
        ? (
          <SchemaMappingGrid
            {...props}
            firstTab="Collections mapping"
            typeOfSchema="collections"
            multiTab={false}
            orderValues={collectionValues.filter(value => value.valueName !== "rowindicator")}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValue={setCurrentValue}
            setCurrentValueStartLength={setCurrentValueStartLength}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setIsClear={setIsClear}

          />
        )
        : (
          ''
        )}
      {(formikValues.schemaType || {}).value === 'custom'
        ? (
          <SchemaMappingGrid
            {...props}
            firstTab="Customs mapping"
            typeOfSchema="custom"
            multiTab={false}
            orderValues={customsValues.filter(value => value.valueName !== "rowindicator")}
            formikValues={formikValues}
            setFieldTouched={setFieldTouched}
            mappingDetails={mappingDetails}
            getFieldSchemaDetails={getFieldSchemaDetails}
            requiredField={requiredField}
            setIsValid={setIsValid}
            dropdownValues={dropdownValues}
            setCurrentValue={setCurrentValue}
            setCurrentValueStartLength={setCurrentValueStartLength}
            isClear={isClear}
            setFieldValue={setFieldValue}
            setIsClear={setIsClear}

          />
        )
        : (
          ''
        )}
    </div>
  );
};

export default SchemaTabCreations;

const orderValues = [
  {
    id: 1,
    valueName: 'AccountNumber',
    name: 'Account number',
    isRequiredField: true,
  },
  {
    id: 2,
    valueName: 'DispatchDate',
    name: 'Dispatch date',
    isRequiredField: true,
  },
  {
    id: 3,
    valueName: 'CustomerName',
    name: 'Delivery business name',
    isRequiredField: true,
  },
  {
    id: 4,
    valueName: 'Address1',
    name: 'Delivery address 1',
    isRequiredField: true,
  },
  {
    id: 5,
    valueName: 'Address2',
    name: 'Delivery address 2',
    isRequiredField: false,

  },
  {
    id: 6,
    valueName: 'Address3',
    name: 'Delivery address 3',
    isRequiredField: false,

  },
  {
    id: 7,
    valueName: 'Town',
    name: 'Delivery town',
    isRequiredField: true,
  },
  {
    id: 8,
    valueName: 'County',
    name: 'Delivery county',
    isRequiredField: false,

  },
  {
    id: 9,
    valueName: 'TelephoneNumber',
    name: 'Delivery telephone',
    isRequiredField: true,
  },
  {
    id: 10,
    valueName: 'PostalCode',
    name: 'Delivery postal code',
    isRequiredField: true,
  },
  {
    id: 11,
    valueName: 'Contact',
    name: 'Delivery contact name',
    isRequiredField: false,

  },
  {
    id: 12,
    valueName: 'NoOfItems',
    name: 'No.of items',
    isRequiredField: true,
  },
  {
    id: 13,
    valueName: 'Weight',
    name: 'Weight',
    isRequiredField: true,
  },
  {
    id: 14,
    valueName: 'ServiceCode',
    name: 'Service code',
    isRequiredField: true,
  },
  {
    id: 15,
    valueName: 'ExtendedCover',
    name: 'Extended liability domestic',
    isRequiredField: false,

  },
  {
    id: 16,
    valueName: 'SpecialInstructions1',
    name: 'Special instructions 1st line',
    isRequiredField: false,

  },
  {
    id: 17,
    valueName: 'SpecialInstructions2',
    name: 'Special instructions 2nd line',
    isRequiredField: false,

  },
  {
    id: 18,
    valueName: 'ExchangeOnDelivery',
    name: 'Exchange on delivery',
    isRequiredField: false,

  },
  {
    id: 19,
    valueName: 'CustomersReference',
    name: 'Customer reference',
    isRequiredField: false,

  },
  {
    id: 20,
    valueName: 'AlternateReference',
    name: 'Alternative reference',
    isRequiredField: false,

  },
  {
    id: 21,
    valueName: 'Email',
    name: 'Delivery email',
    isRequiredField: true,
  },
  {
    id: 22,
    valueName: 'Length',
    name: 'Length',
    isRequiredField: true,
  },
  {
    id: 23,
    valueName: 'Width',
    name: 'Width',
    isRequiredField: true,
  },
  {
    id: 24,
    valueName: 'Height',
    name: 'Height',
    isRequiredField: true,
  },
  {
    id: 25,
    valueName: 'ExtraInsurance',
    name: 'Extended liability international',
    isRequiredField: false,

  },
  {
    id: 26,
    valueName: 'Country',
    name: 'Country',
    isRequiredField: true,
  },
  {
    id: 27,
    valueName: 'IncludeInboxReturnLabel',
    name: 'Include inbox return label',
    isRequiredField: false,

  },
  {
    id: 28,
    valueName: 'ReturnCustomerReference',
    name: 'Return customer reference',
    isRequiredField: false,

  },
  {
    id: 29,

    valueName: 'AddressType',
    name: 'Address type',
    isRequiredField: false,

  },
  {
    id: 30,
    valueName: 'RecipientAddress1',
    name: 'Recipient address 1',
    isRequiredField: true,
  },
  {
    id: 31,
    valueName: 'RecipientAddress2',
    name: 'Recipient address 2',
    isRequiredField: false,

  },
  {
    id: 32,
    valueName: 'RecipientAddress3',

    name: 'Recipient address 3',
    isRequiredField: false,

  },
  {
    id: 33,
    valueName: 'RecipientTown',

    name: 'Recipient town',
    isRequiredField: true,
  },
  {
    id: 34,
    valueName: 'RecipientCounty',

    name: 'Recipient county',
    isRequiredField: false,

  },
  {
    id: 35,
    valueName: 'RecipientPostalCode',

    name: 'Recipient postal code',
    isRequiredField: true,
  },
  {
    id: 36,
    valueName: 'RecipientTelephone',

    name: 'Recipient telephone',
    isRequiredField: false,

  },
  {
    id: 37,
    valueName: 'RecipientContact',

    name: 'Recipient name',
    isRequiredField: true,
  },
  {
    id: 38,
    valueName: 'RecipientEmail',

    name: 'Recipient email',
    isRequiredField: true,
  },
  {
    id: 39,
    valueName: 'ServicePointId',

    name: 'Service point id',
    isRequiredField: false,

  },
  {
    id: 40,
    valueName: 'DescriptionOfGoods1',

    name: 'Description of goods 1',
    isRequiredField: true,
  },
  {
    id: 41,
    valueName: 'DescriptionOfGoods2',

    name: 'Description of goods 2',
    isRequiredField: false,

  },
  {
    id: 42,
    valueName: 'DescriptionOfGoods3',

    name: 'Description of goods 3',
    isRequiredField: false,
  },
  {
    id: 43,
    valueName: 'Value',

    name: 'Value',
    isRequiredField: true,
  },
  {
    id: 44,
    valueName: 'Currency',

    name: 'Currency',
    isRequiredField: true,
  },
  {
    id: 45,
    valueName: 'InvoiceType',

    name: 'Invoice type',
    isRequiredField: true,
  },
  {
    id: 46,
    valueName: 'InvoiceDate',

    name: 'Invoice date',
    isRequiredField: true,
  },
  {
    id: 47,
    valueName: 'InvoiceNumber',

    name: 'Invoice number',
    isRequiredField: true,
  },
  {
    id: 48,
    valueName: 'ReceiverCustomsIdentifier',

    name: 'Receiver customs identifier',
    isRequiredField: false,

  },
  {
    id: 49,
    valueName: 'ShippingCharges',

    name: 'Shipping charges',
    isRequiredField: true,
  },
  {
    id: 50,
    valueName: 'ReasonForExportCode',

    name: 'Reason for export code',
    isRequiredField: true,
  },
  {
    id: 51,
    valueName: 'rowindicator',
    name: 'Row indicator',
    isRequiredField: true,
  },
  {
    id: 52,
    valueName: 'RecipientCountry',
    name: 'Recipient country',
    isRequiredField: false,

  },
  {
    id: 53,
    valueName: 'PrinterQueueName',
    name: 'Printer queue name',
    isRequiredField: false,

  },
  {
    id: 54,
    valueName: 'USStateCode',
    name: 'US state code',
    isRequiredField: false,
  },
  {
    id: 55,
    valueName: 'RecipientBusinessName',
    name: 'Recipient business name',
    isRequiredField: false,
  },
  {
    id: 56,
    valueName: 'IossShipment',
    name: 'IOSS Shipment',
    isRequiredField: false,
  },
  {
    id: 57,
    valueName: 'IossNumber',
    name: 'Marketplace IOSS number',
    isRequiredField: false,
  },
  {
    id: 58,
    valueName: 'Pallets',
    name: 'Pallets',
    isRequiredField: false,
  },
  {
    id: 59,
    valueName: 'What3Words',
    name: 'What3Words',
    isRequiredField: false,
  },
  {
    id: 60,
    valueName: 'W3WLat',
    name: 'What3Words latitude',
    isRequiredField: false,
  },
  {
    id: 61,
    valueName: 'W3WLong',
    name: 'What3Words longitude',
    isRequiredField: false,
  },
  {
    id: 62,
    valueName: 'ShipmentMovementType',
    name: 'Shipment Movement Type',
    isRequiredField: true,
  },
  {
    id: 63,
    valueName: 'SendersEORINumber',
    name: 'Senders EORI Number',
    isRequiredField: false,
  },
  {
    id: 64,
    valueName: 'SendersUKIMSNumber',
    name: 'Senders UKIMS Number',
    isRequiredField: false,
  },
  {
    id: 65,
    valueName: 'RecipientEORINumber',
    name: 'Recipient EORI Number',
    isRequiredField: false,
  },
  {
    id: 66,
    valueName: 'RecipientUKIMSNumber',
    name: 'Recipient UKIMS Number',
    isRequiredField: false,
  },
  {
    id: 67,
    valueName: 'TotalValue',
    name: 'Total Value',
    isRequiredField: true,
  },
  {
    id: 68,
    valueName: 'DescriptionofgoodC2CMovementType',
    name: 'Description Of Goods(Shipment Level)',
    isRequiredField: true,
  },
  {
    id: 69,
    valueName: 'SendersDefermentAccount',
    name: 'Senders Deferment Account',
    isRequiredField: false,
  },
  {
    id: 70,
    valueName: 'DeliveryChoice',
    name: 'Delivery Choice',
    isRequiredField: false,
  }
];

const customsValues = [
  {
    id: 1,
    valueName: 'CustomerReference',

    name: 'Customer reference',
    isRequiredField: true,
  },
  {
    id: 2,
    valueName: 'CommodityCode',

    name: 'Commodity code',
    isRequiredField: true,
  },
  {
    id: 3,
    valueName: 'DescriptionOfGoods',

    name: 'Description of goods',
    isRequiredField: true,
  },
  {
    id: 4,
    valueName: 'Quantity',

    name: 'Quantity',
    isRequiredField: true,
  },
  {
    id: 5,
    valueName: 'NetWeight',

    name: 'Net weight',
    isRequiredField: true,
  },
  {
    id: 6,
    valueName: 'UnitValue',

    name: 'Unit value',
    isRequiredField: true,
  },
  {
    id: 7,
    valueName: 'CountryOfOrigin',

    name: 'Country of origin',
    isRequiredField: true,
  },
  {
    id: 8,
    valueName: 'rowindicator',
    name: 'Row indicator',
    isRequiredField: true,
  },
];

const collectionValues = [
  {
    id: 1,
    valueName: 'CollectionDate',
    name: 'Collection date',
    isRequiredField: true,
  },
  {
    id: 2,
    valueName: 'AccountNumber',
    name: 'Account number',
    isRequiredField: true,
  },
  {
    id: 3,
    valueName: 'CollectionBusinessName',
    name: 'Collection business name',
    isRequiredField: false,
  },
  {
    id: 4,
    valueName: 'CollectionAddress1',
    name: 'Collection address 1',
    isRequiredField: true,
  },
  {
    id: 5,
    valueName: 'CollectionAddress2',
    name: 'Collection address 2',
  },
  {
    id: 6,
    valueName: 'CollectionAddress3',
    name: 'Collection address 3',
  },
  {
    id: 7,
    valueName: 'CollectionTown',
    name: 'Collection town',
    isRequiredField: true,
  },
  {
    id: 8,
    valueName: 'CollectionCounty',
    name: 'Collection county',
  },
  {
    id: 9,
    valueName: 'CollectionPostalCode',
    name: 'Collection postal code',
    isRequiredField: true,
  },
  {
    id: 10,
    valueName: 'CollectionTelephoneNumber',
    name: 'Collection telephone number',
  },
  {
    id: 11,
    valueName: 'CollectionContact',
    name: 'Collection contact name',
    isRequiredField: true,
  },
  {
    id: 12,
    valueName: 'CustomersReference',
    name: 'Customer reference',
  },
  {
    id: 13,
    valueName: 'CollectionSpecialInstructions1',
    name: 'Collection special instructions 1',
  },
  {
    id: 14,
    valueName: 'CollectionSpecialInstructions2',
    name: 'Collection special instructions 2',
  },
  {
    id: 15,
    valueName: 'DeliveryCustomerName',
    name: 'Delivery busines name',
  },
  {
    id: 16,
    valueName: 'DeliveryAddress1',
    name: 'Delivery address 1',
    isRequiredField: true,
  },
  {
    id: 17,
    valueName: 'DeliveryAddress2',
    name: 'Delivery address 2',
  },
  {
    id: 18,
    valueName: 'DeliveryAddress3',
    name: 'Delivery address 3',
  },
  {
    id: 19,
    valueName: 'DeliveryTown',
    name: 'Delivery town',
    isRequiredField: true,
  },
  {
    id: 20,
    valueName: 'DeliveryCounty',
    name: 'Delivery county',
  },
  {
    id: 21,
    valueName: 'DeliveryPostalCode',
    name: 'Delivery postal code',
    isRequiredField: true,
  },
  {
    id: 22,
    valueName: 'DeliveryTelephone',
    name: 'Delivery telephone',
  },
  {
    id: 23,
    valueName: 'DeliveryContact',
    name: 'Delivery contact name',
    isRequiredField: true,
  },
  {
    id: 24,
    valueName: 'ServiceCode',
    name: 'Service code',
    isRequiredField: true,
  },
  {
    id: 25,
    valueName: 'DeliverySpecialInstruction1',
    name: 'Delivery special instuctions 1',
  },
  {
    id: 26,
    valueName: 'DeliverySpecialInstruction2',
    name: 'Delivery special instuctions 2',
  },
  {
    id: 27,
    valueName: 'ColDescrOfGoods1',
    name: 'Collection description of goods 1',
  },
  {
    id: 28,
    valueName: 'ColDescrOfGoods2',
    name: 'Collection description of goods 2',
  },
  {
    id: 29,
    valueName: 'AddressType',
    name: 'Address type ',
  },
  {
    id: 30,
    valueName: 'ServicePointId',
    name: 'Service point id',
  },
  {
    id: 31,
    valueName: 'RecipientAddress1',
    name: 'Recipient address 1',
    isRequiredField: true,
  },
  {
    id: 32,
    valueName: 'RecipientAddress2',
    name: 'Recipient address 2',
  },
  {
    id: 33,
    valueName: 'RecipientAddress3',
    name: 'Recipient address 3',
  },
  {
    id: 34,
    valueName: 'RecipientTown',
    name: 'Recipient town',
    isRequiredField: true,
  },
  {
    id: 35,
    valueName: 'RecipientCounty',
    name: 'Recipient county',
  },
  {
    id: 36,
    valueName: 'RecipientPostalCode',
    name: 'Recipient postal code',
  },
  {
    id: 37,
    valueName: 'RecipientTelephone',
    name: 'Recipient telephone',
    isRequiredField: true,
  },
  {
    id: 38,
    valueName: 'RecipientContact',
    name: 'Recipient name',
  },
  {
    id: 39,
    valueName: 'RecipientEmail',
    name: 'Recipient email',
  },
  {
    id: 40,
    valueName: 'rowindicator',
    name: 'Row indicator',
    isRequiredField: true,
  },
  {
    id: 41,
    valueName: 'NoOfParcels',
    name: 'No.of parcels',
    isRequiredField: true,
  },
  {
    id: 42,
    valueName: 'Weight',
    name: 'Weight',
    isRequiredField: true,
  },
  {
    id: 43,
    valueName: 'CollectionEmail',
    name: 'Collection email',
    isRequiredField: true,

  },
  {
    id: 44,
    valueName: 'IsReturnToSender',
    name: 'Is return to sender',
    isRequiredField: false,

  },
  {
    id: 45,
    valueName: 'IsSendToThirdParty',
    name: 'Is send to third party',
    isRequiredField: false,

  },
  {
    id: 46,
    valueName: 'RecipientBusinessName',
    name: 'Recipient business name',
    isRequiredField: false,
  },
  {
    id: 47,
    valueName: 'DeliveryEmail',
    name: 'Delivery email',
    isRequiredField: false,

  },
  {
    id: 48,
    valueName: 'What3Words',
    name: 'What3Words',
    isRequiredField: false,
  },
  {
    id: 49,
    valueName: 'W3WLat',
    name: 'What3Words latitude',
    isRequiredField: false,
  },
  {
    id: 50,
    valueName: 'W3WLong',
    name: 'What3Words longitude',
    isRequiredField: false,
  },

];

const orderCollectionConsignor = [
  {
    id: 1,
    valueName: 'AccountNumber',
    name: 'Account number',
    isRequiredField: true,
  },
  {
    id: 2,
    valueName: 'DispatchDate',
    name: 'Despatch date',
    isRequiredField: true,
  },
  {
    id: 3,
    valueName: 'CustomerName',
    name: 'Delivery business name',
    isRequiredField: true,
  },
  {
    id: 4,
    valueName: 'Address1',
    name: 'Delivery address 1',
    isRequiredField: true,
  },
  {
    id: 5,
    valueName: 'Address2',
    name: 'Delivery address 2',
    isRequiredField: false,

  },
  {
    id: 6,
    valueName: 'Address3',
    name: 'Delivery address 3',
    isRequiredField: false,

  },
  {
    id: 7,
    valueName: 'Town',
    name: 'Delivery town',
    isRequiredField: true,
  },
  {
    id: 8,
    valueName: 'County',
    name: 'Delivery county',
    isRequiredField: false,

  },
  {
    id: 9,
    valueName: 'TelephoneNumber',
    name: 'Delivery telephone ',
    isRequiredField: true,
  },
  {
    id: 10,
    valueName: 'PostalCode',
    name: 'Delivery postal code',
    isRequiredField: true,
  },
  {
    id: 11,
    valueName: 'Contact',
    name: 'Delivery contact name',
    isRequiredField: false,

  },
  {
    id: 12,
    valueName: 'NoOfItems',
    name: 'No.of items',
    isRequiredField: true,
  },
  {
    id: 13,
    valueName: 'Weight',
    name: 'Weight',
    isRequiredField: true,
  },
  {
    id: 14,
    valueName: 'ServiceCode',
    name: 'Service code',
    isRequiredField: true,
  },
  {
    id: 15,
    valueName: 'ExtendedCover',
    name: 'Extended liability domestic',
    isRequiredField: false,

  },
  {
    id: 16,
    valueName: 'SpecialInstructions1',
    name: 'Delivery special instructions 1',
    isRequiredField: false,

  },
  {
    id: 17,
    valueName: 'SpecialInstructions2',
    name: 'Delivery special instructions 2',
    isRequiredField: false,

  },
  {
    id: 18,
    valueName: 'ExchangeOnDelivery',
    name: 'Exchange on delivery',
    isRequiredField: false,

  },
  {
    id: 19,
    valueName: 'CustomersReference',
    name: 'Customer reference',
    isRequiredField: false,

  },
  {
    id: 20,
    valueName: 'AlternateReference',
    name: 'Alternative reference',
    isRequiredField: false,

  },
  {
    id: 21,
    valueName: 'Email',
    name: 'Delivery email',
    isRequiredField: true,
  },
  {
    id: 22,
    valueName: 'Length',
    name: 'Length',
    isRequiredField: true,
  },
  {
    id: 23,
    valueName: 'Width',
    name: 'Width',
    isRequiredField: true,
  },
  {
    id: 24,
    valueName: 'Height',
    name: 'Height',
    isRequiredField: true,
  },
  {
    id: 25,
    valueName: 'ExtraInsurance',
    name: 'Extended liability international',
    isRequiredField: false,

  },
  {
    id: 26,
    valueName: 'Country',
    name: 'Country',
    isRequiredField: true,
  },
  {
    id: 27,
    valueName: 'IncludeInboxReturnLabel',
    name: 'Include inbox return label',
    isRequiredField: false,

  },
  {
    id: 28,
    valueName: 'ReturnCustomerReference',
    name: 'Return customer reference',
    isRequiredField: false,

  },
  {
    id: 29,

    valueName: 'AddressType',
    name: 'Address type',
    isRequiredField: false,

  },
  {
    id: 30,
    valueName: 'RecipientAddress1',
    name: 'Recipient address 1',
    isRequiredField: true,
  },
  {
    id: 31,
    valueName: 'RecipientAddress2',
    name: 'Recipient address 2',
    isRequiredField: false,

  },
  {
    id: 32,
    valueName: 'RecipientAddress3',

    name: 'Recipient address 3',
    isRequiredField: false,

  },
  {
    id: 33,
    valueName: 'RecipientTown',

    name: 'Recipient town',
    isRequiredField: true,
  },
  {
    id: 34,
    valueName: 'RecipientCounty',

    name: 'Recipient county',
    isRequiredField: false,

  },
  {
    id: 35,
    valueName: 'RecipientPostalCode',

    name: 'Recipient postal code',
    isRequiredField: true,
  },
  {
    id: 36,
    valueName: 'RecipientTelephone',

    name: 'Recipient telephone',
    isRequiredField: false,

  },
  {
    id: 37,
    valueName: 'RecipientContact',

    name: 'Recipient name',
    isRequiredField: true,
  },
  {
    id: 38,
    valueName: 'RecipientEmail',

    name: 'Recipient email',
    isRequiredField: true,
  },
  {
    id: 39,
    valueName: 'ServicePointId',

    name: 'Service point id',
    isRequiredField: false,

  },
  {
    id: 40,
    valueName: 'RecipientCountry',
    name: 'Recipient country',
    isRequiredField: false,

  },
  {
    id: 41,
    valueName: 'PrinterQueueName',
    name: 'Printer queue name',
    isRequiredField: false,

  },
  {
    id: 42,
    valueName: 'USStateCode',
    name: 'US state code',
    isRequiredField: false,

  },
  {
    id: 43,
    valueName: 'DescriptionOfGoods1',

    name: 'Description of goods 1',
    isRequiredField: true,
  },
  {
    id: 44,
    valueName: 'DescriptionOfGoods2',

    name: 'Description of goods 2',
    isRequiredField: false,

  },
  {
    id: 45,
    valueName: 'DescriptionOfGoods3',

    name: 'Description of goods 3',
    isRequiredField: false,
  },
  {
    id: 46,
    valueName: 'Value',

    name: 'Value',
    isRequiredField: true,
  },
  {
    id: 47,
    valueName: 'Currency',

    name: 'Currency',
    isRequiredField: true,
  },
  {
    id: 48,
    valueName: 'InvoiceType',

    name: 'Invoice type',
    isRequiredField: true,
  },
  {
    id: 49,
    valueName: 'InvoiceDate',

    name: 'Invoice date',
    isRequiredField: true,
  },
  {
    id: 50,
    valueName: 'InvoiceNumber',

    name: 'Invoice number',
    isRequiredField: true,
  },
  {
    id: 51,
    valueName: 'ReceiverCustomsIdentifier',

    name: 'Receiver customs identifier',
    isRequiredField: false,

  },
  {
    id: 52,
    valueName: 'ShippingCharges',

    name: 'Shipping charges',
    isRequiredField: true,
  },
  {
    id: 53,
    valueName: 'ReasonForExportCode',

    name: 'Reason for export code',
    isRequiredField: true,
  },
  {
    id: 54,
    valueName: 'CollectionDate',
    name: 'Collection date',
    isRequiredField: true,
  },
  {
    id: 55,
    valueName: 'CollectionAddress1',
    name: 'Collection address 1',
    isRequiredField: true,
  },
  {
    id: 56,
    valueName: 'CollectionAddress2',
    name: 'Collection address 2',
  },
  {
    id: 57,
    valueName: 'CollectionAddress3',
    name: 'Collection address 3',
  },
  {
    id: 58,
    valueName: 'CollectionTown',
    name: 'Collection town',
    isRequiredField: true,
  },
  {
    id: 59,
    valueName: 'CollectionCounty',
    name: 'Collection county',
  },
  {
    id: 60,
    valueName: 'CollectionPostalCode',
    name: 'Collection postal code',
    isRequiredField: true,
  },
  {
    id: 61,
    valueName: 'CollectionTelephoneNumber',
    name: 'Collection telephone number',
  },
  {
    id: 62,
    valueName: 'CollectionContact',
    name: 'Collection contact name',
    isRequiredField: true,
  },
  {
    id: 63,
    valueName: 'CollectionSpecialInstructions1',
    name: 'Collection special instructions 1',
  },
  {
    id: 64,
    valueName: 'CollectionSpecialInstructions2',
    name: 'Collection special instructions 2',
  },
  {
    id: 64,
    valueName: 'ColDescrOfGoods1',
    name: 'Collection description of goods 1',
  },
  {
    id: 66,
    valueName: 'ColDescrOfGoods2',
    name: 'Collection description of goods 2',
  },
  {
    id: 67,
    valueName: 'CollectionEmail',
    name: 'Collection email',
  },
  {
    id: 68,
    valueName: 'RecipientBusinessName',
    name: 'Recipient business name',
  },
  {
    id: 69,
    valueName: 'CollectionBusinessName',
    name: 'Collection business name',
  },
  {
    id: 70,
    valueName: 'Pallets',
    name: 'Pallets',
    isRequiredField: false,
  },
]

const parcelValues = [
  {
    id: 1,
    valueName: 'CustomerReference',

    name: 'Customer reference',
    isRequiredField: true,
  },
  {
    id: 2,
    valueName: 'ParcelNumber',

    name: 'Parcel number',
    isRequiredField: true,
  },
  {
    id: 3,
    valueName: 'ParcelReference',

    name: 'Parcel reference',
    isRequiredField: true,
  },
  {
    id: 4,
    valueName: 'Weight',
    name: 'Weight',
    isRequiredField: true,
  },
  {
    id: 5,
    valueName: 'Length',
    name: 'Length',
    isRequiredField: true,
  },
  {
    id: 6,
    valueName: 'Width',
    name: 'Width',
    isRequiredField: true,
  },
  {
    id: 7,
    valueName: 'Height',
    name: 'Height',
    isRequiredField: true,
  },
  {
    id: 8,
    valueName: 'rowindicator',
    name: 'Row indicator',
    isRequiredField: true,
  },
];

const clearanceValues = [
  {
    id: 1,
    valueName: 'CustomerReference',

    name: 'Customer reference',
    isRequiredField: true,
  },
  {
    id: 2,
    valueName: 'CommodityCode',

    name: 'Commodity code',
    isRequiredField: false,
  },
  {
    id: 3,
    valueName: 'DescriptionOfGoods',

    name: 'Description of goods',
    isRequiredField: true,
  },
  {
    id: 4,
    valueName: 'UnitQuantity',
    name: 'Unit quantity',
    isRequiredField: true,
  },
  {
    id: 5,
    valueName: 'UnitValue',
    name: 'Unit value',
    isRequiredField: true,
  },
  {
    id: 6,
    valueName: 'UnitWeight',
    name: 'Unit weight',
    isRequiredField: true,
  },
  {
    id: 7,
    valueName: 'CountryOfManufacture',
    name: 'Country of manufacture',
    isRequiredField: true,
  },
  {
    id: 8,
    valueName: 'rowindicator',
    name: 'Row indicator',
    isRequiredField: true,
  },
];

const collectionClearanceValues = [
  {
    id: 1,
    valueName: 'shipmentMovementType',
    name: 'Shipment movement type',
    isRequiredField: true,
  },
  {
    id: 2,
    valueName: 'collectionEoriNumber',
    name: 'Collection EORI number',
    isRequiredField: true,
  },
  {
    id: 3,
    valueName: 'collectionUkimsNumber',
    name: 'Collection UKIMS number',
    isRequiredField: true,
  },
  {
    id: 4,
    valueName: 'collectionDefermentAccount',
    name: 'Collection deferment account',
    isRequiredField: true,
  },
  {
    id: 5,
    valueName: 'recipientEoriNumber',
    name: 'Recipient EORI number',
    isRequiredField: true,
  },
  {
    id: 6,
    valueName: 'recipientUkimsNumber',
    name: 'Recipient UKIMS number',
    isRequiredField: true,
  },
  {
    id: 7,
    valueName: 'shippingCharges',
    name: 'Shipping Charges',
    isRequiredField: true,
  },
  {
    id: 8,
    valueName: 'totalValue',
    name: 'Total Value',
    isRequiredField: true,
  },
  {
    id: 9,
    valueName: 'reasonForExport',
    name: 'Reason for Export',
    isRequiredField: true,
  },
];

