import * as CONSTANTS from '../utils/constants/constants';
import { ShipmentRequestModel, ShipmentModel, recipientAddress, shipmentDetails } from '../utils/Models';
import { arrayValuesToQueryStringParam, isValidNumber } from '../utils/common';
import { applicationID } from './authProvider/authProvider';
import { GET } from '../api/axios';
import { ServiceEndPoints } from './constants/ApiConstant';
import moment from 'moment';
import momentTZ from 'moment-timezone';
export const getCustomerAccountID = () => {
  return sessionStorage.getItem('customerAccountID');
};

const reA = /[^a-zA-Z\s]/g;
const reN = /[^0-9]/g;

export const formatPostalCodeValues = (
  records,
  addressTypeData = [],
  leftOverData = [],
  getPostalAddresses,
  setPostalSearchData,
  setOpenPostalSearchModal,
  minSearchResultsReq = 10,
  searchedString = '',
) => {
  records.forEach((record) => {
    if (record.type === 'Address') {
      addressTypeData.push(record);
    } else {
      leftOverData.push(record);
    }
  });

  if (addressTypeData.length < minSearchResultsReq && leftOverData.length > 0) {
    getPostalAddresses(null, `&container=${leftOverData[0].id}`, searchedString);
    leftOverData.shift();
  } else {
    setPostalSearchData(addressTypeData);
    setOpenPostalSearchModal(true);
    addressTypeData = [];
    leftOverData = [];
  }
};

const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

const sixMonthsWorkingDays = (holidayList, setWorkingDays, shouldIncludeTodaysDate, weekendHolidayPickup) => {
  let next6months = moment().add(6, 'months').calendar();
  let next6monthsFormat = moment(next6months).format('YYYY-MM-DD');
  let lastDaymonthsFormat = moment().format('YYYY-MM-DD');
  let days = 0;
  let count = 0;
  days = diffDays(new Date(lastDaymonthsFormat), new Date(next6monthsFormat));

  let nextDate = new Date();
  if (shouldIncludeTodaysDate) nextDate.setDate(nextDate.getDate() - 1);
  const fiveWorkingDays = [];
  while (count <= days) {
    nextDate.setDate(nextDate.getDate() + 1);
    let tempVar = new Date(nextDate).setHours(0, 0, 0, 0);
    tempVar = new Date(tempVar);
    if (!weekendHolidayPickup) {
      if (
        tempVar.getDay() !== 0 &&
        tempVar.getDay() !== 6 &&
        !(holidayList || []).some((holiday) => new Date(holiday.date).getTime() === tempVar.getTime())
      ) {
        fiveWorkingDays.push(tempVar);
      }
    } else {
      fiveWorkingDays.push(tempVar);
    }
    count++;
  }
  setWorkingDays(fiveWorkingDays);
}
const fiveWorkingDays = (holidayList, setWorkingDays, shouldIncludeTodaysDate, weekendHolidayPickup) => {
  let nextDate = new Date();
  if (shouldIncludeTodaysDate) nextDate.setDate(nextDate.getDate() - 1);
  const fiveWorkingDays = [];
  while (fiveWorkingDays.length < 5) {
    nextDate.setDate(nextDate.getDate() + 1);
    let tempVar = new Date(nextDate).setHours(0, 0, 0, 0);
    tempVar = new Date(tempVar);
    if (!weekendHolidayPickup) {
      if (
        tempVar.getDay() !== 0 &&
        tempVar.getDay() !== 6 &&
        !(holidayList || []).some((holiday) => new Date(holiday.date).getTime() === tempVar.getTime())
      ) {
        fiveWorkingDays.push(tempVar);
      }
    } else {
      fiveWorkingDays.push(tempVar);
    }
  }
  setWorkingDays(fiveWorkingDays);
}
export const getWorkingDays = (
  holidayList,
  setWorkingDays,
  shouldIncludeTodaysDate = false,
  weekendHolidayPickup = false,
  shipmentForwardDating = false,
  isInternational = false,
) => {
  if (isInternational) {
    fiveWorkingDays(holidayList, setWorkingDays, shouldIncludeTodaysDate, weekendHolidayPickup)
  } else {
    if (shipmentForwardDating) {
      sixMonthsWorkingDays(holidayList, setWorkingDays, shouldIncludeTodaysDate, weekendHolidayPickup)
    } else {
      fiveWorkingDays(holidayList, setWorkingDays, shouldIncludeTodaysDate, weekendHolidayPickup)
    }
  }
};

export const getCommodityDetails = (commodityDetails) => {
  const formattedCommodities = [];
  (commodityDetails || []).forEach((commodity) => {
    formattedCommodities.push({ ...commodity, countryOfOrigin: (commodity.countryOfOrigin || {}).code2Digit });
  });
  return formattedCommodities;
};

export const getMarketplaceCommodityDetails = (commodityDetails) => {
  const formattedCommodities = [];
  (commodityDetails || []).forEach((commodity) => {
    formattedCommodities.push({ ...commodity, countryOfOrigin: (commodity.countryOfOrigin || {}).code2Digit, marketplaceCode: commodity?.marketplaceChannel?.marketplaceCode });
  });
  return formattedCommodities;
}

const getParcelDimensions = (formikValues, isDomestic, isOrderfileDefault = false) => {
  let formattedDimensions = [];
  if (isDomestic) {
    formattedDimensions = ((formikValues || {}).parcelDetails || []).map((parcel) => {
      const parcelRecord = {
        parcelNumber: parcel.id,
        pieceWeight: getParsedFloatValues(parcel.pieceWeight),
        length: getParsedFloatValues(parcel.length),
        width: getParsedFloatValues(parcel.width),
        height: getParsedFloatValues(parcel.height),
        volumetricWeight: getParsedFloatValues(parcel.volumetricWeight),
      };
      return parcelRecord;
    });
  } else if (!isOrderfileDefault) {
    formattedDimensions = formikValues.intlParcelDetails.map((dims) => {
      return {
        length: getParsedFloatValues(dims.length),
        width: getParsedFloatValues(dims.width),
        height: getParsedFloatValues(dims.height),
        pieceWeight: getParsedFloatValues(dims.weight),
        pieceRefId: `${dims.pieceRefId}`,
        isValid: dims.isValid
      };
    });
  }
  else {
    formattedDimensions.push({
      length: getParsedFloatValues(formikValues.length),
      width: getParsedFloatValues(formikValues.width),
      height: getParsedFloatValues(formikValues.height),
      volumetricWeight: getParsedFloatValues(formikValues.totalVolumetricWeight),
    });
  }
  return formattedDimensions;
};

export const getParsedFloatValues = (floatInString) => {
  if (isNaN(floatInString) || floatInString === '') {
    return 0;
  }
  return parseFloat(parseFloat(floatInString).toFixed(2));
};

const getUserIdArray = (userDetails) => {
  return [userDetails?.userId];
};

export const formatParcelDetails = (formikValues, isDomestic, isOrderfileDefault = false, isTemplateManagement = false) => {
  return {
    numberOfItems: isTemplateManagement ? formikValues.noOfItems || 0 : formikValues.noOfItems || null,
    weight: isTemplateManagement ? formikValues.weight || 0 : formikValues.weight || null,
    pallets: formikValues.createPalletShipment === 'yes' ? formikValues.pallets : 0,
    ...((!isDomestic || formikValues.addParcelDimensions) && {
      parcelDimensions: getParcelDimensions(formikValues, isDomestic, isOrderfileDefault),
    }),
    ...((!isDomestic || formikValues.addParcelDimensions) && {
      totalVolumetricWeight: getParsedFloatValues(formikValues.totalVolumetricWeight),
    }),
  };
};

const formatDeliveryAddress = (formikValues) => {
  const what3Words = {
    words: formikValues.what3Words,
    geo:{
    latitude: formikValues.w3WCoordinates?.lat || formikValues.w3WCoordinates?.latitude,
    longitude: formikValues.w3WCoordinates?.lng || formikValues.w3WCoordinates?.longitude,
    }
  };
  return {
    recipientType: CONSTANTS.recipientType[formikValues.addressType],
    customerNumber: formikValues.customerNumber,
    businessName: formikValues.businessName,
    recipientName: formikValues.recipientName,
    line1: formikValues.addressLine1,
    line2: formikValues.addressLine2,
    line3: formikValues.addressLine3,
    town: formikValues.town,
    county: formikValues.county,
    postCode: formikValues.postalCode,
    telephone: formikValues.telephone,
    email: formikValues.emailAddress,
    //set What3words details
    what3Words: formikValues.destinationCountry.code2Digit === 'GB' ? what3Words || {} : {},
  };
};

export const formatDeliveryOptions = (formikValues, isOrderFileDefault = false) => {
  return {
    deliveryType: CONSTANTS.deliveryType[formikValues.deliveryType],
    doorStepInstructions: formikValues.doorStepInstructions?.value,
    specialInstructions: formikValues.specialInstructions,
    exchangeOnDelivery: formikValues.exchange === 'yes',
    additionalInstruction: !isOrderFileDefault ? formikValues.additionalInstruction : "",
    leaveSafeLocation: formikValues.leaveSafeLocation,
  };
};

const formatdeliveryNotification = (formikValues) => {
  return {
    mobile: formikValues.mobileRecipient === 'checked' || formikValues.mobileRecipient === true,
    email: formikValues.emailRecipient === 'checked' || formikValues.emailRecipient === true,
  };
};

const formatExtendedLiability = (formikValues) => {
  return {
    extendedLiability: formikValues.extendedCoverDomestic || 0,
    extendedLiabilityRequired: formikValues.extendedCoverInternational || false,
  };
};

const formatCustomDeclaration = (formikValues, countries = []) => {
  return {
    invoiceType: CONSTANTS.invoiceType[formikValues.invoiceTypeLabel],
    invoiceNumber: formikValues.InvoiceNumber,
    invoiceDate: formikValues.InvoiceDate,
    descriptionOfGoods: formikValues.descriptionOfGoods,
    shippingCharges: getParsedFloatValues(formikValues.customDeclarationShippingCharges),
    currency: (formikValues.customDeclarationCurrency || {}).code,
    shippersEORINumber: formikValues.customDeclarationShippersEoriNo,
    receiversEORINumber: formikValues.customDeclarationReceiversEoriNo,
    customItemDetails: getCommodityDetails(formikValues.commodityDetails),
    subTotal: getParsedFloatValues(formikValues.subTotal),
    shippingCost: getParsedFloatValues(formikValues.customDeclarationShippingCharges),
    totalValue: getParsedFloatValues(formikValues.totalValue),
    fdaRegistrationNumber: formikValues.FDARegistrationNumber,
    termsOfDelivery: formikValues.TermsOfDelivery?.label?.toString(),
  };
};

export const formatBookDriverPickUpObj = (formikValues, userDetails, operationalAccount) => {

  const bookDriverObj = {
    customerAccountId: userDetails.customerAccountID,
    requestorName: userDetails.name,
    requestorEmail: userDetails.emailId,
    pickupDate: formatDate(formikValues.bookCollectionDate),
    timeReady: formikValues.earliestTime.substr(0, formikValues.earliestTime.lastIndexOf(':')),
    latestTime: formikValues.latestTime.substr(0, formikValues.latestTime.lastIndexOf(':')),
    instructions: formikValues.specialInstructions,
    tradingLocationId: formikValues.pickupLocation?.value || "",
    operationalAccountId: operationalAccount?.accountId ?? ""
  };
  return bookDriverObj;
};

export const formatCreateSchemaObj = (schemaDetails, mappingDetails, formikValues) => {
  const fieldsArray = mappingDetails.map((field) => {
    return {
      type: field.type,
      mapTo: field.valueName,
      mapFrom: formikValues.importType != 'delimited' ? '' : field.columnFromFile, //string
      range: {
        startIndex:
          field.startsAt === undefined || field.startsAt === '' || formikValues.importType === 'delimited'
            ? 0
            : parseInt(field.startsAt),
        columnLength:
          field.length === undefined || field.length === '' || formikValues.importType === 'delimited'
            ? 0
            : parseInt(field.length),
      },
      value:
        field?.valueName?.toLowerCase() === 'rowindicator'
          ? formikValues.schemaType.value === 'orders+collections'
            ? field.type === 'order'
              ? formikValues['rowIndicator1']
              : field.type === 'collections'
                ? formikValues['rowIndicator2']
                : ''
            : field.type === 'order'
              ? formikValues['rowIndicator1']
              : field.type === 'custom'
                ? formikValues['rowIndicator2']
                : field.type === 'clearance'
                ? formikValues['rowIndicator2']
                : formikValues['rowIndicator3']
          : '',
    };
  });

  const createSchemaObj = {
    organization: getCustomerAccountID(),
    schemaName: schemaDetails.schemaName,
    schemaType: schemaDetails.schemaType.value,
    schemaFileType: {
      fileType: schemaDetails.importType === 'delimited' ? 2 : 1,
      delimiter: schemaDetails.newDelimeter === '' ? schemaDetails.delimiter.value : schemaDetails.newDelimeter,
    },
    time: '',
    date: schemaDetails.otherformat === '' ? schemaDetails.dateFormat.value : schemaDetails.otherformat,
    isheaderIncluded: schemaDetails.isheaderIncluded === undefined ? false : schemaDetails.isheaderIncluded,
    filePollingFolder: schemaDetails.pollingPath,
    fieldsMappingSchema: fieldsArray,
    isEnabled: true,
    fileExtension: schemaDetails.fileExtension,
  };

  return createSchemaObj;
};

export const formatBarcodeObj = (barcodeDetails) => {
  const createSchemaObj = {
    organization: getCustomerAccountID(),
    type: 'barcode',
    fieldToSearchBy: (barcodeDetails.filedToSearchBy || {}).value,
    startPosition: barcodeDetails.startPosition,
    finalPosition: barcodeDetails.finalPosition,
    barCodeLength: parseInt(barcodeDetails.barCodeLength ? barcodeDetails.barCodeLength : 0),
  };

  return createSchemaObj;
};

export const addFAQReqObj = (values, categoryType, id) => {
  const toCamelCase = function (name) {
    return name.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
      if (p2) return p2.toLowerCase();
      return p1.toUpperCase();
    });
  };
  const reqObjArray = [
    {
      id: id,
      category: categoryType === 'textBox' ? values.catagory : values.faqCatagory.name,
      question: values.addQuestion,
      answer: values.addAnswer,
    },
  ];

  return reqObjArray;
};

export const formatAddToFavReqObj = (formikValues, userDetails = {}, addressObj = {}) => {
  let reqObj = {};
  if (formikValues.updateAddToAddressBook) {
    reqObj = { ...addressObj };
    reqObj.favouriteUsers = [];
    reqObj.deliveryAddress = formatDeliveryAddress(formikValues);
    reqObj.organization = getCustomerAccountID();
    reqObj.deliveryOptions= formatDeliveryOptions(formikValues);
  } else {
    reqObj = {
      organization: getCustomerAccountID(),
      accountNumber: formikValues.accountNumber?.accountId,
      destinationCountry: formikValues.destinationCountry?.code2Digit,
      parcelDetails: formatParcelDetails(formikValues, formikValues.destinationCountry?.code === 'GBR'),
      deliveryAddress: formatDeliveryAddress(formikValues),
      deliveryOptions: formatDeliveryOptions(formikValues),
      product: formikValues.product?.productCode,
      service: formikValues.service?.name,
      customerReference: formikValues.customerReference,
      extendedLiability: formatExtendedLiability(formikValues),
      alternativeRefernce: formikValues.alternateReference,
      deliveryNotification: formatdeliveryNotification(formikValues),
      customDeclaration: formatCustomDeclaration(formikValues),
      collectionInstructions: formikValues.collectionInstructions,
      descriptionOfGoodsToBeCollected: formikValues.descriptionOfGoodsToBeCollected,
    };
  }

  if (formikValues.addToFavCutomerList) {
    reqObj['favouriteUsers'] = getUserIdArray(userDetails);
  }
  formatReqObjForDomestic(reqObj, formikValues);
  removeUndefinedProperties(reqObj);
  return [reqObj];
};

export const formatTemplateModelForSave = (formikValues, isTemplateManagement) => {
  const objectModel = {
    organization: getCustomerAccountID(),
    templateName: formikValues.templateName,
    parcelDetails: formatParcelDetails(formikValues, true, false, isTemplateManagement),
    deliveryOptions: formatDeliveryOptions(formikValues),
    product: formikValues.product?.productCode,
    service: (formikValues.service || {}).name,
    customerReference: formikValues.customerReference,
    alternativeRefernce: formikValues.alternateReference,
    extendedLiability: formatExtendedLiability(formikValues),
    deliveryNotification: formatdeliveryNotification(formikValues),
    documentCreatedMonthYear: `${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
  };
  return objectModel;
};

export const carriageForwardFormToFavReqObj = (formikValues, formName, userDetails, addressObj = {}) => {
  // const isAddToAddressBook = formikValues[`${formName}AddToaddressBook`];
  // let reqObj = {};
  // if (isAddToAddressBook) {
  //   reqObj = {
  //     organization: getCustomerAccountID(),
  //     accountNumber: (formikValues.accountNumber || {}).accountId,
  //     destinationCountry: 'GB',
  //     deliveryAddress: getCustomerAddress(formikValues, formName),
  //     customerReference: formikValues[`${formName}CustomerReference`],
  //     parcelDetails: {
  //       numberOfItems: formikValues.fromParcel ? formikValues.fromParcel : undefined,
  //       weight: formikValues.fromWeight ? formikValues.fromWeight : undefined,
  //     },
  //   };
  // } else {
  let reqObj = { ...addressObj };
  reqObj.deliveryAddress = getCustomerAddress(formikValues, formName);
  reqObj.favouriteUsers = [];
  reqObj.organization = getCustomerAccountID();
  reqObj.destinationCountry = 'GB';
  reqObj.collectionInstructions = formikValues?.collectionInstructions;
  reqObj.descriptionOfGoodsToBeCollected = formikValues?.descriptionOfGoods;

  // }
  // if (formName === 'from' && isAddToAddressBook) {
  //   reqObj['collectionInstruction'] = formikValues.collectionInstructions;
  //   reqObj['descriptionOfGoodsToBeCollected'] = formikValues.descriptionOfGoods;
  // } else if (isAddToAddressBook) {
  //   reqObj['deliveryOptions'] = {
  //     deliveryType: CONSTANTS.deliveryType[formikValues.deliveryType],
  //     doorStepInstructions: (formikValues.doorStepInstructions || {}).value,
  //     specialInstructions: formikValues.specialInstructions,
  //     additionalInstruction: formikValues.additionalInstruction,
  //     leaveSafeLocation: formikValues.leaveSafeLocation,
  //   };
  //   reqObj['service'] = formikValues.toServiceRequired?.name;
  //   reqObj['product'] = formikValues.toServiceRequired?.orderedProduct;
  // }
  if (formikValues[`${formName}AddToFavourites`]) {
    reqObj['favouriteUsers'] = getUserIdArray(userDetails);
  }
  return reqObj;
};

const getCustomerAddress = (formikValues, formName) => {
  return {
    recipientType: CONSTANTS.recipientType[formikValues[`${formName}AddressType`]],
    customerNumber: formikValues[`${formName}CustomerNo`],
    businessName: formikValues[`${formName}BusinessName`],
    recipientName: formikValues[`${formName}ContactName`],
    line1: formikValues[`${formName}AddressLine1`],
    line2: formikValues[`${formName}AddressLine2`],
    line3: formikValues[`${formName}AddressLine3`],
    town: formikValues[`${formName}Town`],
    county: formikValues[`${formName}County`],
    postCode: formikValues[`${formName}PostCode`],
    telephone: formikValues[`${formName}TelephoneNumber`],
    email: formikValues[`${formName}EmailAddress`],
  };
};

export const userDetailsObj = (obj) => {
  let accountsLength = obj.accounts.length;
  const accountsArray = [];
  for (let i = 0; i < accountsLength; i++) {
    accountsArray[i] = obj.accounts[i]?.split("-")[0];
  }
  let reqObj = {
    name: obj.userName,
    emailId: obj.emailId,
    role: obj.role.label,
    accounts: accountsArray,
  };
  return reqObj;
};

export const updateUserObj = (obj) => {
  let reqObj = {
    name: obj.userName,
    emailId: obj.emailId,
    customerAccountID: 'DHL',
    role: obj.role.label,
    accounts: obj.accounts.map(acc => acc?.split("-")?.[0]).filter(val => val),
    isActive: obj.isActive,
  };
  return reqObj;
};

export const removeUndefinedProperties = (object) => {
  Object.entries(object).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      removeUndefinedProperties(value);
    } else if (value === undefined || value === null) {
      delete object[key];
    }
  });
};

const formatReqObjForDomestic = (reqObj, formikValues) => {
  if (formikValues?.destinationCountry?.code === 'GBR') {
    delete reqObj.customDeclaration;
    delete reqObj.extendedLiability?.extendedLiabilityRequired;
  } else {
    delete reqObj.extendedLiability?.extendedLiability;
  }
};

export const getMappingCountry = (countryList, key, name = '') => {
  var mappingCountry = [];

  switch (name) {
    case 'code':
      mappingCountry = countryList.find((country) => country['code'].toLowerCase().trim() === key.toLowerCase().trim());
      break;
    case 'code2Digit':
      mappingCountry = countryList.find(
        (country) => country['code2Digit'].toLowerCase().trim() === key.toLowerCase().trim(),
      );
      break;
    default:
      mappingCountry = countryList.find((country) => country['name'].toLowerCase().trim() === key.toLowerCase().trim());
      break;
  }
  return mappingCountry;
};

export const sortBasedOnNestedOrNot = (
  arrayObj = [],
  propertyName = 'deliveryAddress.customerNumber',
  isNested = false,
) => {
  propertyName = isNested ? propertyName.split('.') : propertyName;
  return (arrayObj || []).sort((a, b) => {
    if (isNested) {
      a = ((a[propertyName[0]] || {})[propertyName[1]] || '').toLowerCase();
      b = ((b[propertyName[0]] || {})[propertyName[1]] || '').toLowerCase();
    } else {
      a = (a[propertyName] || '').toLowerCase();
      b = (b[propertyName] || '').toLowerCase();
    }
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const basicSort = (a, b) => {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
};

export function removeDuplicates(data, key) {
  return [...new Map(data.map((item) => [key(item), item])).values()];
}

export const setDropdownValues = (arrayObj = [], propertyName, value) => {
  return (
    (arrayObj.length > 0 &&
      ((arrayObj || []).find((data) => {
        if (typeof data[propertyName] === 'string' && typeof value === 'string') {
          return (data[propertyName] || '').toLowerCase() === (value || '').toLowerCase();
        } else {
          return data[propertyName] === value;
        }
      }) ||
        arrayObj[0])) ||
    undefined
  );
};

const setParcelDimensions = (parcelResponse, responseObj, vWDivisor = 4000) => {
  let formattedDimensions = [];
  if (responseObj.destinationCountry === 'GBR' || responseObj.destinationCountry === 'GB') {
    formattedDimensions = (parcelResponse || []).map((parcel, index) => {
      const parcelRecord = {
        ...parcel,
        id: index + 1,
        pieceWeight: parcel.pieceWeight ?? 0,
        volumetricWeight: (parcel.volumetricWeight || (parcel.length * parcel.width * parcel.height) / vWDivisor) ?? 0,
      };
      return parcelRecord;
    });
  }
  return formattedDimensions;
};

const setIntlParcelDimensions = (dims, responseObj) => {
  let formattedDimensions = [];
  if (responseObj.destinationCountry !== 'GBR' || responseObj.destinationCountry !== 'GB') {

        // Code to populate first parcel detail data in all fields
        // const parcelRecord = {
        //   length: dims[0].length === 0 ?'': dims[0].length,
        //   width: dims[0].width === 0 ?'': dims[0].width,
        //   height: dims[0].height === 0 ?'': dims[0].height,
        //   weight: dims[0].pieceWeight === 0 ? '' : dims[0].pieceWeight,
        //   pieceRefId: dims[0].pieceRefId ?? '',
        //   isValid: dims[0].isValid,
        // };
        // let i=0;
        // while(i < responseObj?.parcelDetails?.numberOfItems){
        //   formattedDimensions.push(parcelRecord);
        //   i++;
        // }
        let i=0;
        while(i < responseObj?.parcelDetails?.numberOfItems){
        const parcelRecord = {
            length: dims[i]?.length ? dims[i]?.length : '',
            width: dims[i]?.width ? dims[i]?.width : '',
            height: dims[i]?.height ? dims[i]?.height : '',
            weight: dims[i]?.pieceWeight ? dims[i]?.pieceWeight : '',
            pieceRefId: dims[i]?.pieceRefId ?? '',
            isValid: dims[i]?.isValid,
        };
        formattedDimensions.push(parcelRecord);
        i++;
      }
  }
  return formattedDimensions;
}

export const setValuesToSetupDetails = (responseObj, countries = []) => {
  const countryCode = responseObj.destinationCountry === 'GBR' ? 'GB' : responseObj.destinationCountry;
  return {
    // handOverMethod: CONSTANTS.reverseHandOverMethod[(responseObj || {}).parcelHandOverMethod],
    // accountNumber: responseObj.accountNumber,
    destinationCountry: setDropdownValues(countries, 'code2Digit', countryCode),
  };
};

export const setValuesToTemplateDetails = (responseObj) => {
  return {
    selectedTemplate: responseObj.templateName ?? '',
    templateName: responseObj.templateName ?? '',
  };
};

export const setValuesToParcelDetails = (responseObj, vWDivisor = null, isDefaults = false) => {
  const parcelResponse = responseObj?.parcelDetails;
  return {
    noOfItems: (parcelResponse || {}).numberOfItems ?? '',
    createPalletShipment: (parcelResponse || {}).pallets > 0 ? 'yes' : 'no',
    pallets: (parcelResponse || {}).pallets ?? '',
    weight: (parcelResponse || {}).weight ?? '',
    totalVolumetricWeight: (parcelResponse || {}).totalVolumetricWeight,
    parcelDetails: setParcelDimensions((parcelResponse || {}).parcelDimensions, responseObj, vWDivisor),
    intlParcelDetails: responseObj?.destinationCountry !== 'GBR' && !isDefaults ? setIntlParcelDimensions(((parcelResponse || {}).parcelDimensions), responseObj) : [],
    addParcelDimensions: Boolean(
      (responseObj?.destinationCountry === 'GBR' ||
        responseObj?.destinationCountry === 'GB' ||
        (responseObj || {}).templateName) &&
      parcelResponse?.parcelDimensions?.length > 0,
    ),
  };
};

export const setValuesToAdditionalInfo = (responseObj) => {
  return {
    alternateReference: (responseObj || {}).alternativeRefernce,
    customerReference: (responseObj || {}).customerReference,
    emailRecipient: (responseObj.deliveryNotification || {}).email,
    mobileRecipient: (responseObj.deliveryNotification || {}).mobile,
    extendedCoverDomestic: ((responseObj || {}).extendedLiability || {}).extendedLiability,
    extendedCoverInternational: ((responseObj || {}).extendedLiability || {}).extendedLiabilityRequired,
  };
};

export const setValuesToDeliveryOptions = (responseObj) => {
  const deliveryOptions = (responseObj || {}).deliveryOptions;
  return {
    additionalInstruction: (deliveryOptions || {}).additionalInstruction ?? '',
    leaveSafeLocation: (deliveryOptions || {}).leaveSafeLocation ?? '',
    deliveryType: CONSTANTS.reverseDeliveryType[(deliveryOptions || {}).deliveryType],
    doorStepInstructions: setDropdownValues(
      CONSTANTS.doorStepInstructionOptions,
      'value',
      deliveryOptions?.doorStepInstructions,
    ),
    exchange: (deliveryOptions || {}).exchangeOnDelivery ? 'yes' : 'no', // have to check again
    specialInstructions: (deliveryOptions || {}).specialInstructions ?? '',
  };
};

export const setValuesToDeliveryOptions_domaesticDefaults = (responseObj) => {
  const deliveryOptions = (responseObj || {}).deliveryOptions;
  const specialInstructions = deliveryOptions?.additionalInstruction ? `${(deliveryOptions?.specialInstructions ?? '')} ${deliveryOptions.additionalInstruction}` : (deliveryOptions?.specialInstructions ?? '');
  return {
    additionalInstruction: '',
    leaveSafeLocation: deliveryOptions?.leaveSafeLocation ?? '',
    deliveryType: CONSTANTS.reverseDeliveryType[(deliveryOptions || {}).deliveryType],
    doorStepInstructions: setDropdownValues(
      CONSTANTS.doorStepInstructionOptionswithSelect,
      'value',
      (deliveryOptions || {}).leaveSafeLocation !== null && (deliveryOptions || {}).leaveSafeLocation !== undefined
        ? 'leaveSafe'
        : (deliveryOptions || {}).doorStepInstructions,
    ),
    exchange: (deliveryOptions || {}).exchangeOnDelivery ? 'yes' : 'no', // have to check again
    specialInstructions: specialInstructions,
  };
};

export const setValuesToProductAndService = (responseObj) => {
  return {
    // product:responseObj.product,
    // service:responseObj.service,
  };
};

export const setValuesToDeliveryAddress = (responseObj) => {
  const deliveryAddress = (responseObj || {}).deliveryAddress;
  return {
    addressType: CONSTANTS.reverseRecipientType[(deliveryAddress || {}).recipientType],
    postalCode: deliveryAddress?.postCode ?? '',
    recipientName: deliveryAddress?.recipientName ?? '',
    businessName: deliveryAddress?.businessName ?? '',
    addressLine1: deliveryAddress?.line1 ?? '',
    addressLine2: deliveryAddress?.line2 ?? '',
    addressLine3: deliveryAddress?.line3 ?? '',
    town: deliveryAddress?.town ?? '',
    county: deliveryAddress?.county ?? '',
    telephone: deliveryAddress?.telephone ?? '',
    emailAddress: deliveryAddress?.email ?? '',
    customerNumber: deliveryAddress?.customerNumber ?? '',
    what3Words: responseObj?.destinationCountry === 'GB' ? deliveryAddress?.what3Words?.words : '',
    w3WCoordinates: responseObj?.destinationCountry === 'GB' ? deliveryAddress?.what3Words?.geo : '',
  };
};

export const setValuesToBasicDeclaration = (responseObj, currencies = []) => {
  const customDeclaration = responseObj.customDeclaration;
  return {
    descriptionOfGoods: (customDeclaration || {}).descriptionOfGoods,
    // customDeclarationValue:(customDeclaration||{}), // check with abhinav
    customDeclarationCurrency: setDropdownValues(currencies, 'code', (customDeclaration || {}).currency),
  };
};

export const setCommodityDetails = (commodityDetails = [], countries = []) => {
  const formattedCommodities = [];
  commodityDetails.forEach((commodity) => {
    formattedCommodities.push({
      ...commodity,
      countryOfOrigin: setDropdownValues(countries, 'code2Digit', commodity.countryOfOrigin),
    });
  });
  return formattedCommodities;
};

export const setMarketplaceCommodityDetails = (commodityDetails = [], countries = [], marketPlacesList = []) => {
  const formattedCommodities = [];
  commodityDetails.forEach((commodity) => {
    formattedCommodities.push({
      ...commodity,
      marketplaceChannel: commodity?.marketplaceCode ? setDropdownValues(marketPlacesList, 'marketplaceCode', commodity?.marketplaceCode) : undefined,
      countryOfOrigin: setDropdownValues(countries, 'code2Digit', commodity.countryOfOrigin),
    });
  });
  return formattedCommodities;
};

export const setValuesToCustomDeclaration = (responseObj, countries = []) => {
  const customDeclaration = (responseObj || {}).customDeclaration;
  const InvoiceDate = (customDeclaration || {}).invoiceDate;
  return {
    invoiceTypeLabel: CONSTANTS.reverseInvoiceType[(customDeclaration || {}).invoiceType],
    InvoiceDate: InvoiceDate ? new Date(InvoiceDate) : new Date(),
    // InvoiceDate: new Date((customDeclaration || {}).invoiceDate), // have to check
    InvoiceNumber: (customDeclaration || {}).invoiceNumber,
    customDeclarationShippingCharges: (customDeclaration || {}).shippingCharges,
    customDeclarationShippersEoriNo: (customDeclaration || {}).shippersEORINumber,
    customDeclarationReceiversEoriNo: (customDeclaration || {}).receiversEORINumber,
    // reasonForExport: setDropdownValues(
    //   CONSTANTS.reasonForExportOptions,
    //   'value',
    //   (customDeclaration || {}).reasonForExport,
    // ),
    fdaRegistrationNumber: (customDeclaration || {}).fdaRegistrationNumber,
    termsOfDelivery: (customDeclaration || {}).termsOfDelivery,
    commodityDetails: setCommodityDetails((customDeclaration || {}).customItemDetails, countries),
  };
};

export const setValuesToReturnOption = (responseObj) => {
  let _tempObject = {};

  if ((responseObj || {}).includeReturnLabel !== null && (responseObj || {}).includeReturnLabel !== undefined) {
    return {
      inboxReturnLabel: `${(responseObj || {}).includeReturnLabel}`,
      returnReference: responseObj.returnReference,
    };
  } else {
    return {
      returnReference: responseObj.returnReference,
    };
  }
};

export const setBarcodevaluesToForm = (barCodeValues, formikValues) => {
  const newFormValues = {
    filedToSearchBy: setDropdownValues(
      CONSTANTS.filedToSearchByOptions,
      'value',
      (barCodeValues || {}).fieldToSearchBy,
    ),
    startPosition: barCodeValues.startPosition,
    finalPosition: barCodeValues.finalPosition,
    barCodeLength: barCodeValues.barCodeLength,
  };
  const formValues = { ...formikValues, ...newFormValues };

  return formValues;
};

export const setValuesToCollectionDetailsFromAddressBook = (responseObj) => {
  return {
    collectionInstructions: (responseObj || {})?.collectionInstructions,
    descriptionOfGoodsToBeCollected: (responseObj || {})?.descriptionOfGoodsToBeCollected,
  };
};

export const setValuesToForm = (responseObj, formikValues, countries = [], currencies = [], excludeEmpty = false) => {
  const newFormValues = {
    ...setValuesToTemplateDetails(responseObj),
    ...setValuesToSetupDetails(responseObj, countries),
    ...setValuesToParcelDetails(responseObj),
    ...setValuesToDeliveryAddress(responseObj),
    ...setValuesToDeliveryOptions(responseObj),
    ...setValuesToProductAndService(responseObj),
    ...setValuesToAdditionalInfo(responseObj),
    ...setValuesToReturnOption(responseObj),
    ...setValuesToBasicDeclaration(responseObj, currencies),
    ...setValuesToCustomDeclaration(responseObj, countries, currencies),
  };
  // if (responseObj.destinationCountry !== 'GBR' || responseObj.destinationCountry !== 'GB') {
  //   const parcelDimensions = (responseObj?.parcelDetails?.parcelDimensions || [])[0];
  //   newFormValues['length'] = parcelDimensions?.length === 0 && excludeEmpty ? '' : parcelDimensions?.length;
  //   newFormValues['width'] = parcelDimensions?.width === 0 && excludeEmpty ? '' : parcelDimensions?.width;
  //   newFormValues['height'] = parcelDimensions?.height === 0 && excludeEmpty ? '' : parcelDimensions?.height;
  // }
  removeUndefinedProperties(newFormValues);
  const formValues = { ...formikValues, ...newFormValues };
  delete formValues.inboxReturnLabel;
  delete formValues.returnReference;
  return formValues;
};

export const formatCurrenciesToDisplay = (currencies) => {
  return (currencies || []).map((currency) => {
    return {
      code: currency.code,
      name: `${currency.code} - ${currency.name}`,
      symbol: currency.symbol,
    };
  });
};

export const resetFormAndKeepInitialValues = (resetForm, setFieldValue, countries = [], accountNumber, setFlagForAfterSubmitCheck) => {
  resetForm();
  setFieldValue('destinationCountry', setDropdownValues(countries, 'code2Digit', 'GB'));
  setTimeout(() => {
    setFlagForAfterSubmitCheck?.(true)
    setFieldValue('product','');
    setFieldValue('service','');
  }, 0);
};

export const createShipmentRequest = (formValues, servicePointDetails, inboxReturn = false, isOrderRequest = false) => {
  const accountDetails = formValues.accountNumber;
  let newShipmentRequest = ShipmentRequestModel();
  let shipment = ShipmentModel();
  let noOfClearanceItem=0;

  //Would be replaced with actual account once integrated with user data.
  newShipmentRequest.pickupAccount = accountDetails?.accountId;

  //set drop off method
  newShipmentRequest.dropOffType = shipmentRequestAPIConstants.dropOffType[formValues.handOverMethod];

  if (formValues.handOverMethod === 'driverCollection') {
    newShipmentRequest.pickup = {};
    newShipmentRequest.pickup.date = formatDate(formValues.DispatchDate ? formValues.DispatchDate : new Date());
    newShipmentRequest.pickup.accountAddress = true;
    newShipmentRequest.pickup.pickupIdentifier = 'PICKUP12345';
  }

  //set sender Address from account loaded.
  newShipmentRequest.senderAddress = {
    companyName: accountDetails.sender.companyName
      ? accountDetails.sender.companyName.toString().substr(0, 35)
      : accountDetails.sender.companyName,
    address1: accountDetails.sender.address1
      ? accountDetails.sender.address1.toString().substr(0, 35)
      : accountDetails.sender.address1,
    address2: accountDetails.sender.address2
      ? accountDetails.sender.address2.toString().substr(0, 35)
      : accountDetails.sender.address2,
    address3: accountDetails.sender.address3
      ? accountDetails.sender.address3.toString().substr(0, 35)
      : accountDetails.sender.address3,
    city: accountDetails.sender.city ? accountDetails.sender.city.toString().substr(0, 35) : accountDetails.sender.city,
    state: accountDetails.sender.state
      ? accountDetails.sender.state.toString().substr(0, 35)
      : accountDetails.sender.state,
    postalCode: accountDetails.sender.postalCode
      ? accountDetails.sender.postalCode.toString().substr(0, 15)
      : accountDetails.sender.postalCode,
    country: accountDetails.sender.country
      ? accountDetails.sender.country.toString().substr(0, 35)
      : accountDetails.sender.country,
    name: accountDetails.sender.name ? accountDetails.sender.name.toString().substr(0, 35) : accountDetails.sender.name,
    email: accountDetails.sender.email
      ? accountDetails.sender.email.toString().substr(0, 70)
      : accountDetails.sender.email,
    phone: accountDetails.sender.phone
      ? accountDetails.sender.phone.toString().substr(0, 35)
      : accountDetails.sender.phone,
  };

  //parcelDetails
  shipment.shipmentDetails.totalPieces =
    formValues.handOverMethod === 'servicePoint' ? 1 : parseInt(formValues.noOfItems);
  shipment.shipmentDetails.totalWeight = formValues.weight;

  //set delivery address details.
  shipment.consigneeAddress.recipientType = formValues.addressType;
  shipment.consigneeAddress.addressType = shipmentRequestAPIConstants.deliveryType[formValues.deliveryType];
  shipment.consigneeAddress.companyName = formValues.businessName;
  shipment.consigneeAddress.address1 = formValues.addressLine1?.trim();
  shipment.consigneeAddress.address2 = formValues.addressLine2?.trim();
  shipment.consigneeAddress.address3 = formValues.addressLine3.trim();
  shipment.consigneeAddress.city = formValues.town;
  shipment.consigneeAddress.state = formValues.county;
  shipment.consigneeAddress.postalCode = formValues.postalCode;
  shipment.consigneeAddress.country = formValues.destinationCountry?.code2Digit;
  shipment.consigneeAddress.name = formValues.recipientName;
  shipment.consigneeAddress.phone = formValues.telephone;
  shipment.consigneeAddress.email = formValues.emailAddress;
  shipment.consigneeAddress.idNumber = formValues.customerNumber || '';

  const what3Words = {
    words: formValues.what3Words,
    geo:{
    latitude: formValues.w3WCoordinates?.lat || formValues.w3WCoordinates?.latitude,
    longitude: formValues.w3WCoordinates?.lng || formValues.w3WCoordinates?.longitude,
    }
  };
  //set What3words details
  if(formValues.destinationCountry.code2Digit === 'GB' && what3Words?.geo && what3Words?.words){
  shipment.consigneeAddress.what3Words = what3Words || {};
  }
  // shipment.consigneeAddress.state = formValues.county;

  // set clearation details
 if(formValues?.shipmentMovement?.value == 'c2c')
 {
//   const item = [{
//     descriptionOfGoods: formValues?.descriptionOfGoods
//  }]

  shipment.clearanceDeclaration.shipmentMovementType = 'C2C';
  shipment.clearanceDeclaration.totalValue = Math.round(formValues?.clearanceTotalValue*100)/100;
  shipment.clearanceDeclaration.items = null;
  shipment.clearanceDeclaration.descriptionOfGoods = formValues?.descriptionOfGoods;
 }
 else if(formValues?.shipmentMovement?.value == 'c2b')
 {
  const item = formValues?.commodityDetails.map(obj => {
    noOfClearanceItem += Number(obj?.quantity);
    return {
    commodityCode: obj?.commodityCode,
    descriptionOfGoods: obj?.fullDescriptionOfGoods,
    unitQuantity:Number(obj?.quantity),
  };
});
  shipment.clearanceDeclaration.shipmentMovementType = 'C2B';
  shipment.clearanceDeclaration.recipientEORINumber = formValues?.receipentEORI;
  shipment.clearanceDeclaration.recipientUKIMSNumber = formValues?.receipentUKIMS;
  shipment.clearanceDeclaration.totalValue = Math.round(formValues?.clearanceTotalValue*100)/100;
  shipment.clearanceDeclaration.numberOfItems = noOfClearanceItem;
  shipment.clearanceDeclaration.items = item;
 }
 else if(formValues?.shipmentMovement?.value == 'b2c')
  {
    const item = formValues?.commodityDetails.map(obj => {
      noOfClearanceItem += Number(obj?.quantity);
      return {
      commodityCode: obj?.commodityCode,
      descriptionOfGoods: obj?.fullDescriptionOfGoods,
      unitQuantity:Number(obj?.quantity),
    };
  });
   shipment.clearanceDeclaration.shipmentMovementType = 'B2C';
   shipment.clearanceDeclaration.sendersEORINumber = formValues?.shipperEORI;
   shipment.clearanceDeclaration.sendersUKIMSNumber = formValues?.shipperUKIMS;
   shipment.clearanceDeclaration.totalValue = Math.round(formValues?.clearanceTotalValue*100)/100;
   shipment.clearanceDeclaration.numberOfItems = noOfClearanceItem;
   shipment.clearanceDeclaration.items = item;
  }
  else if(formValues?.shipmentMovement?.value == 'b2b')
    {
      const item = formValues?.commodityDetails.map(obj => {
        noOfClearanceItem += Number(obj?.quantity);
        return {
        commodityCode: obj?.commodityCode,
        descriptionOfGoods: obj?.fullDescriptionOfGoods,
        unitQuantity:Number(obj?.quantity),
        unitValue: obj?.unitValue ? Math.round(obj?.unitValue*100)/100:null,
        unitWeight: obj?.unitWeight ? Math.round(obj?.unitWeight*100)/100:null,
        countryOfManufacture:obj?.countryOfOrigin?.code2Digit,
      };
    });
     shipment.clearanceDeclaration.shipmentMovementType = 'B2B';
     shipment.clearanceDeclaration.sendersEORINumber = formValues?.shipperEORI;
     shipment.clearanceDeclaration.sendersUKIMSNumber = formValues.shipperUKIMS == '' ? null:formValues.shipperUKIMS ;
     shipment.clearanceDeclaration.recipientEORINumber = formValues?.receipentEORI == ''? null:formValues.receipentEORI;
     shipment.clearanceDeclaration.recipientUKIMSNumber = formValues?.receipentUKIMS;
     shipment.clearanceDeclaration.totalValue = Math.round(formValues?.clearanceTotalValue*100)/100;
     shipment.clearanceDeclaration.reasonForExport = (formValues.shipperUKIMS == '' && formValues?.receipentUKIMS == '' )?formValues?.reasonExport?.value:null;
     shipment.clearanceDeclaration.shippingCharges = (formValues.shipperUKIMS == '' && formValues?.receipentUKIMS == '' )?formValues?.shippingCharges? Math.round(formValues?.shippingCharges*100)/100:null:null;
     shipment.clearanceDeclaration.sendersDefermentAccount = (formValues.shipperUKIMS == '' && formValues?.receipentUKIMS == '' ) ?formValues?.shipperDeferment:null;
     shipment.clearanceDeclaration.numberOfItems = noOfClearanceItem;
     shipment.clearanceDeclaration.items = item;
    }
    else{
      shipment.clearanceDeclaration = null
    }
  //set shipment details
  shipment.shipmentDetails.deliveryChoice= formValues?.doorStepInstructions.key;
  shipment.shipmentDetails.orderedProduct = formValues?.service?.orderedProduct;

  //set pieces value for international or domestic shipment.
  if (formValues.destinationCountry.code2Digit === 'GB') {
    if (formValues.addParcelDimensions) {
      const piecesDetails = formValues.parcelDetails.map((piece) => {
        return {
          weight: parseFloat(piece.pieceWeight),
          length: parseFloat(piece.length),
          width: parseFloat(piece.width),
          height: parseFloat(piece.height),
        };
      });
      shipment.pieces = piecesDetails;
    }
    formValues.createPalletShipment === 'yes' ? shipment.shipmentDetails.totalPallets = formValues.pallets : shipment.shipmentDetails.totalPallets = 0;

    //Set Delivery instructions for domestic shipment

    if (isOrderRequest || (formValues.doorStepInstructions?.value === 'deliverToDoorStepOnly' && (formValues.createPalletShipment === 'yes' || formValues.exchange === 'yes'))) {
      shipment.shipmentDetails.deliveryInstructions = formValues.specialInstructions;
    }
    else {
      shipment.shipmentDetails.deliveryInstructions = formValues.additionalInstruction ? `${formValues.specialInstructions} ${formValues.additionalInstruction}` : formValues.specialInstructions;
    }
    if (formValues.deliveryType === 'deliverToDoorStep') {
      shipment.consigneeAddress.addressType = 'doorstep';
      if (formValues.doorStepInstructions.value === 'leaveSafe') {
        shipment.shipmentDetails.secureLocation = formValues.leaveSafeLocation;
      } else if (formValues.doorStepInstructions.value === 'deliverToDoorStepOnly') {
        shipment.shipmentDetails.exchangeOnDelivery = formValues.exchange === 'yes';
      }
    } else if (formValues.deliveryType === 'deliverToServicePoint') {
      //To do set sercvice point information.
      if (servicePointDetails) {
        shipment.consigneeAddress.locationId = servicePointDetails?.servicePointID ?? '';
        shipment.consigneeAddress.locationType = 'ParcelShop';
        shipment.recipientAddress = recipientAddress();

        //set serviepointdetials to consignee address.
        shipment.consigneeAddress.companyName = servicePointDetails?.address?.businessName;
        shipment.consigneeAddress.address1 = servicePointDetails?.address?.address1?.trim();
        shipment.consigneeAddress.address2 = servicePointDetails?.address?.address2?.trim();
        shipment.consigneeAddress.address3 = servicePointDetails?.address?.address3?.trim();
        shipment.consigneeAddress.city = servicePointDetails?.address?.postalTown;
        shipment.consigneeAddress.postalCode = servicePointDetails?.address?.zipcode;
        shipment.consigneeAddress.country = formValues.destinationCountry.code2Digit;
        shipment.consigneeAddress.name = 'ShopKeeper';
        shipment.consigneeAddress.phone = formValues.telephone;
        shipment.consigneeAddress.email = formValues.emailAddress;

        //set address in delivery section of the form to recepient address.
        shipment.recipientAddress.companyName = formValues.businessName;
        shipment.recipientAddress.address1 = formValues.addressLine1?.trim();
        shipment.recipientAddress.address2 = formValues.addressLine2?.trim();
        shipment.recipientAddress.address3 = formValues.addressLine3?.trim();
        shipment.recipientAddress.city = formValues.town;
        shipment.recipientAddress.postalCode = formValues.postalCode;
        shipment.recipientAddress.country = formValues.destinationCountry.code2Digit;
        shipment.recipientAddress.name = formValues.recipientName;
        shipment.recipientAddress.email = formValues.emailAddress;
        shipment.recipientAddress.phone = formValues.telephone;
      }
    }
  } else {
    if (formValues.deliveryType === 'deliverToServicePoint') {
      //To do set sercvice point information.
      if (servicePointDetails) {
        shipment.consigneeAddress.locationId = servicePointDetails?.servicePointID ?? '';
        shipment.consigneeAddress.locationType = 'ParcelShop';
        shipment.recipientAddress = recipientAddress();

        //set serviepointdetials to consignee address.
        shipment.consigneeAddress.companyName = servicePointDetails?.address?.businessName;
        shipment.consigneeAddress.address1 = servicePointDetails?.title;
        shipment.consigneeAddress.address2 = servicePointDetails?.address?.address1?.trim();
        shipment.consigneeAddress.address3 = servicePointDetails?.address?.address2?.trim();
        shipment.consigneeAddress.city = servicePointDetails?.address?.postalTown;
        shipment.consigneeAddress.postalCode = servicePointDetails?.address?.zipcode;
        shipment.consigneeAddress.country = formValues.destinationCountry.code2Digit;
        shipment.consigneeAddress.name = 'ShopKeeper';
        shipment.consigneeAddress.phone = formValues.telephone;
        shipment.consigneeAddress.email = formValues.emailAddress;

        //set address in delivery section of the form to recepient address.
        shipment.recipientAddress.companyName = formValues.businessName;
        shipment.recipientAddress.address1 = formValues.addressLine1?.trim();
        shipment.recipientAddress.address2 = formValues.addressLine2?.trim();
        shipment.recipientAddress.address3 = formValues.addressLine3?.trim();
        shipment.recipientAddress.city = formValues.town;
        shipment.recipientAddress.postalCode = formValues.postalCode;
        shipment.recipientAddress.country = formValues.destinationCountry.code2Digit;
        shipment.recipientAddress.name = formValues.recipientName;
        shipment.recipientAddress.email = formValues.emailAddress;
        shipment.recipientAddress.phone = formValues.telephone;
      }
    }
    //Add pieces details for international shipment.
    let declaredValue = 0;
    let shipmentPieces = [];
    formValues.intlParcelDetails.map((parcelDetails) => {
      const parcelDims = {
        weight: parseFloat(parcelDetails.weight),
        length: parseFloat(parcelDetails.length),
        width: parseFloat(parcelDetails.width),
        height: parseFloat(parcelDetails.height),
        pieceRefId: `${parcelDetails.pieceRefId}`,
      };
      shipmentPieces.push(parcelDims);
    });

    shipment.shipmentDetails.totalPallets = 0;
    if (formValues.commodityDetails.length > 0 && formValues.isFullCustomDetails) {
      const customsInvoice = {
        type: formValues.invoiceTypeLabel,
        number: formValues.InvoiceNumber,
        date: formatDate(formValues.InvoiceDate),
        reasonForExport: formValues.reasonForExport?.type || '',
      };
      shipment.customsInvoice = customsInvoice;
      shipment.shipmentDetails.freightCharges = parseFloat(formValues.customDeclarationShippingCharges);
      shipment.shipmentDetails.currency = formValues.customDeclarationCurrency.code;

      shipment.senderCustomsRegistrations = [];
      shipment.senderCustomsRegistrations.push({ type: 'EORI', id: formValues.customDeclarationShippersEoriNo });
      if (formValues.FDARegistrationNumber) {
        shipment.senderCustomsRegistrations.push({ type: 'FDA', id: formValues.FDARegistrationNumber });
      }
      if (
        formValues.isFullCustomDetails &&
        formValues.destinationCountry.code2Digit !== 'GB' &&
        accountDetails?.customsRegistration?.vatTvaNumber
      ) {
        shipment.senderCustomsRegistrations.push({ type: 'VAT', id: accountDetails.customsRegistration.vatTvaNumber });
      }
      if (formValues.customDeclarationReceiversEoriNo) {
        shipment.recipientCustomsRegistrations = [];
        shipment.recipientCustomsRegistrations.push({
          type: 'DEFAULT',
          id: formValues.customDeclarationReceiversEoriNo,
        });
      }
      const customsDetails = formValues.commodityDetails.map((comm) => {
        declaredValue = declaredValue + comm.unitValue * comm.quantity;
        return {
          hsCode: comm.commodityCode,
          countryOfOrigin: comm.countryOfOrigin.code2Digit,
          itemDescription: comm.fullDescriptionOfGoods,
          packagedQuantity: comm.quantity,
          itemValue: comm.unitValue,
          itemWeight: comm.weight,
        };
      });
      if (shipmentPieces.length > 0) {
        shipmentPieces[0].declaredValue = parseFloat(parseFloat(declaredValue).toFixed(2));
        shipmentPieces[0].customsDetails = [];
        shipmentPieces[0].customsDetails = customsDetails;
      }

      //need to check with API team about all valid terms of delivery.
      shipment.shipmentDetails.dutiesPaid = formValues.TermsOfDelivery.label.toString();
    } else {

      shipment.shipmentDetails.shipmentDesc = formValues?.descriptionOfGoods;
      shipment.shipmentDetails.currency = formValues.customDeclarationCurrency?.code;
      shipmentPieces[0].declaredValue = parseFloat(parseFloat(formValues?.customDeclarationValue ?? 0).toFixed(2));

    }
    shipment.pieces = shipmentPieces;
  }

  // Additional Information for domestic & international shipments
  shipment.shipmentDetails.customerRef1 = formValues.customerReference;
  shipment.shipmentDetails.customerRef2 = formValues.alternateReference;

  // Adding info if inboxreturn is true.
  if (inboxReturn && formValues.inboxReturnLabel === 'true') {
    shipment.shipmentDetails.inBoxReturn = true;
    shipment.return = {};

    shipment.return.returnAccount = accountDetails.returnConfiguration.customerReturnAccount;
    shipment.return.customerRef1 = formValues.returnReference;
    shipment.return.shipmentDesc = 'ShipmentDescription';
    shipment.return.declaredValue = 99;
  }

  //Todo Add extended liablilty
  if (
    formValues.destinationCountry.code2Digit === 'GB' &&
    formValues.extendedCoverDomestic &&
    formValues.extendedCoverDomestic !== ''
  ) {
    shipment.extendedLiability = {};
    shipment.extendedLiability.extendedLiabilityUnits = parseInt(formValues.extendedCoverDomestic || 0);
  } else if (formValues.destinationCountry.code2Digit !== 'GB' && formValues.extendedCoverInternational) {
    shipment.extendedLiability = {};
    shipment.extendedLiability.extendedLiabilityRequired = formValues.extendedCoverInternational;
  }

  //Ioss Related Changes
  if (formValues.destinationCountry.iossApplies) {
    if (formValues.applyIossNumber === 'true') {
      shipment.shipmentDetails.iossShipment = true;
      const shippersNo =
        formValues.iossType === 'marketNo'
          ? formValues.marketplaceIossNo
          : formValues.iossType === 'shippersNo'
            ? formValues.iossNumber
            : null;
      if (shippersNo) {
        shipment.senderCustomsRegistrations.push({
          type: 'IOSS',
          id: shippersNo,
        });
      }
    }
  }

  newShipmentRequest.shipments.push(shipment);
  return newShipmentRequest;
};

export const createAmendRequest = (formValues) => {

  const amendShipmentModel = {
    "pickup": {
      "date": formatDate(formValues.DispatchDate ? formValues.DispatchDate : new Date())
    },
    "recipient": {
      "name": formValues.recipientName,
      "phone": formValues.telephone,
      "email": formValues.emailAddress
    },
    "shipmentDetails": {
      "customerRef1": formValues.customerReference,
      "customerRef2": formValues.alternateReference,
      "orderedProduct": formValues?.service?.orderedProduct,
      "totalpieces": formValues.noOfItems,
      "totalWeight": formValues.weight,
      "deliveryInstructions": formValues.specialInstructions,
      "secureLocation": "",
      "totalPallets": formValues.pallets,
      "exchangeOnDelivery": false,
      "deliveryChoice":formValues?.doorStepInstructions.key
    },
    "pieces": [],
    "extendedLiability": {
      "extendedLiabilityUnits": 0
    },
    'clearanceDeclaration':{}
  }

  if (formValues.doorStepInstructions?.value === 'deliverToDoorStepOnly' && (formValues.createPalletShipment === 'yes' || formValues.exchange === 'yes')) {
    amendShipmentModel.shipmentDetails.deliveryInstructions = formValues.specialInstructions;
  }

  if (formValues.deliveryType === 'deliverToDoorStep') {
    if (formValues.doorStepInstructions.value === 'leaveSafe') {
      amendShipmentModel.shipmentDetails.secureLocation = formValues.leaveSafeLocation;
    } else if (formValues.doorStepInstructions.value === 'deliverToDoorStepOnly') {
      amendShipmentModel.shipmentDetails.exchangeOnDelivery = formValues.exchange === 'yes';
    }
  }
  if (
    formValues.destinationCountry.code2Digit === 'GB' &&
    formValues.extendedCoverDomestic &&
    formValues.extendedCoverDomestic !== ''
  ) {
    amendShipmentModel.extendedLiability.extendedLiabilityUnits = parseInt(formValues.extendedCoverDomestic || 0);
  }
  if (formValues.destinationCountry.code2Digit === 'GB') {
    if (formValues.totalPallets > 0) {
      amendShipmentModel.shipmentDetails.secureLocation = "";
    }
    else if (formValues.addParcelDimensions) {
      const piecesDetails = formValues.parcelDetails.map((piece) => {
        return {
          weight: parseFloat(piece.pieceWeight),
          length: parseFloat(piece.length),
          width: parseFloat(piece.width),
          height: parseFloat(piece.height),
        };
      });
      amendShipmentModel.pieces = piecesDetails;
    }
// changes to save windsor 

    amendShipmentModel.clearanceDeclaration= formValues?.shipmentMovement?.value? { ...getSaveClearanceDeclaration(formValues) }:null;

    return amendShipmentModel
  }
}

const shipmentRequestAPIConstants = {
  dropOffType: {
    driverCollection: 'Pickup',
    servicePoint: 'Dropoff',
  },
  deliveryType: {
    deliverToDoorStep: 'doorstep',
    deliverToServicePoint: 'servicePoint',
  },
  reasonForExport: {
    Gift: 'gift',
    Documents: 'documents',
    'Commercial sale': 'commercialsale',
    'Commercial sample': 'commercialsample',
    'Returned goods': 'returnedgoods',
    Repairs: 'repairs',
  },
};

export const schemaDetailsObj = (file) => {
  const fileObj = {
    fileBase64: file,
  };
  return fileObj;
};
export const getDomesticAccounts = (userAccounts) => {
  return userAccounts?.filter((acc) => {
    return (
      acc?.allowedProducts?.filter((allowedProduct) => {
        return CONSTANTS.DOMESTIC_PRODUCTS.find((domProduct) => domProduct === allowedProduct) !== undefined;
      }).length > 0
    );
  });
};
export const getInternationalAccounts = (userAccounts) => {
  return userAccounts?.filter((acc) => {
    return (
      acc?.allowedProducts?.filter((allowedProduct) => {
        return CONSTANTS.DOMESTIC_PRODUCTS.find((domProduct) => domProduct !== allowedProduct) !== undefined;
      }).length > 0
    );
  });
};

export const getInternationalRoadAccounts = (userAccounts) => {
  return userAccounts?.filter((acc) => {
    return (
      acc?.allowedProducts?.filter((allowedProduct) => {
        return CONSTANTS.INTERNATIONAL_ROAD_PRODUCTS.find((domProduct) => domProduct === allowedProduct) !== undefined;
      }).length > 0
    );
  });
};

export const getDriverCollectionAccounts = (userAccounts) => {
  return userAccounts.filter((account) => account?.configuration?.firstMileDropOffAllowed === false);
};

export const getFMDAccounts = (userAccounts) => {
  return userAccounts.filter((account) => account?.configuration?.firstMileDropOffAllowed === true);
};

export const getNonReturnAccounts = (userAccounts) => {
  return userAccounts.filter((account) => account?.configuration?.returnAccount === false);
};

export const formatDate = (date = new Date(), joiner = '-', dateFormat = 'yy/mm/dd' , isDashboardDetails=false) => {
  if(isDashboardDetails && date == null)
  {
    return '';
  }
  var d = new Date(date),
    month = `${d.getMonth() + 1}`,
    day = `${d.getDate()}`,
    year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }
  if (dateFormat === 'yy/mm/dd') {
    return [year, month, day].join(joiner);
  } else {
    return [day, month, year].join(joiner);
  }
};

export const formatDateToSecondFormat = (date) => {
  let d = new Date(date);
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  return `${da} ${mo} ${ye}`;
};

export const getDayOfTheWeek = (date) => {
  let DayOftheWeek;
  if (date === undefined || date === null) {
    DayOftheWeek = '';
  }

  if (new Date(date).getDay() === 0) {
    DayOftheWeek = 'Sunday';
  } else if (new Date(date).getDay() === 1) {
    DayOftheWeek = 'Monday';
  } else if (new Date(date).getDay() === 2) {
    DayOftheWeek = 'Tuesday';
  } else if (new Date(date).getDay() === 3) {
    DayOftheWeek = 'Wednesday';
  } else if (new Date(date).getDay() === 4) {
    DayOftheWeek = 'Thursday';
  } else if (new Date(date).getDay() === 5) {
    DayOftheWeek = 'Friday';
  } else if (new Date(date).getDay() === 6) {
    DayOftheWeek = 'Saturday';
  } else {
    DayOftheWeek = '';
  }

  return DayOftheWeek;
};

export const yesterdayDate1 = (publicHolidays = []) => {
  let prevDate = new Date();
  let fromDate;
  while (!fromDate) {
    prevDate.setDate(prevDate.getDate() - 1);
    if (
      prevDate.getDay() !== 0 &&
      prevDate.getDay() !== 6 &&
      !(publicHolidays || []).some((holiday) => new Date(holiday.date).getTime() === prevDate.getTime())
    ) {
      return (fromDate = new Date(prevDate));
    }
    return prevDate;
  }
};

export const weekDay = (date) => {
  date = new Date(date);
  var day = date.getDay(),
    diff = date.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

export const firstBusinessDayOfMonth = (year, month, publicHolidays = []) => {
  var date = new Date();
  var offset = 1;
  var result = null;

  if (typeof year === 'undefined' || year === null) {
    year = date.getFullYear();
  }

  if (typeof month === 'undefined' || month === null) {
    month = date.getMonth();
  }
  do {
    result = new Date(year, month, offset++);
  } while (
    result.getDay() === 0 ||
    result.getDay() === 6 ||
    (publicHolidays || []).some((holiday) => new Date(holiday.date).getTime() === result.getTime())
  );
  return result;
};

export const firstDayOfPreviousMonth = (year, month, publicHolidays = []) => {
  var date = new Date();
  var offset = 1;
  var result = null;
  month = date.getMonth() - 1;
  if (typeof year === 'undefined' || year === null) {
    year = date.getFullYear();
  }
  do {
    result = new Date(year, month, offset++);
  } while (
    result.getDay() === 0 ||
    result.getDay() === 6 ||
    (publicHolidays || []).some((holiday) => new Date(holiday.date).getTime() === result.getTime())
  );

  return result;
};

export const lastDayOfPreviousMonth = (publicHolidays = []) => {
  var d = new Date();
  d.setDate(1);
  d.setHours(-1);
  return d;
};

export const formatDateToUKFormat = (date) => {
  if (date) {
    const datePart = date?.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + '/' + month + '/' + year;
  }
  return '';
};

export const getDefaultDashboardValues = (formikValues) => {
  let queryParams = '';
  queryParams += `isDomestic=${formikValues.isDomestic}`;
  queryParams += `&fromCollectionDate=${formikValues.fromCollectionDate}`;
  if (!formikValues.isDomestic && formikValues.internationalCountry?.code2Digit !== '') {
    queryParams += `&destinationCountry=${(formikValues.internationalCountry || {}).code2Digit}`;
  }
  return (queryParams += `&toCollectionDate=${formikValues.toCollectionDate}`);
};

export const getDefaultDashboardFindValues = (formikValues, userAccounts, isDispatchDate) => {
  let last6months = moment().subtract(6, 'months').calendar();
  let lastDay = moment().subtract(1, 'days');
  let last6monthsFormat = moment(last6months).format('YYYY-MM-DD');
  let lastDaymonthsFormat = moment().format('YYYY-MM-DD');
  let queryParams = arrayValuesToQueryStringParam(userAccounts);
  if (!isDispatchDate) {
    if (formikValues?.deliveryStatusForAccounts?.value === 'daterange') {
      queryParams += `fromCollectionDate=${formikValues.fromCollectionDate}`;
      queryParams += `&toCollectionDate=${formikValues.toCollectionDate}&`;
    } else {
      queryParams += `fromCollectionDate=${last6monthsFormat}`;
      queryParams += `&toCollectionDate=${lastDaymonthsFormat}&`;
    }
  }
  return queryParams;
};

export const dynamicSort = (property) => {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
};

export const customTableShipmentNoSort = (a, b) => {
  if (!a) {
    // Change this values if you want to put `null` values at the end of the array
    return -1;
  }
  if (!b) {
    // Change this values if you want to put `null` values at the end of the array
    return +1;
  }
  if (a != null && b != null) {
    return a.localeCompare(b, 'en', { numeric: true });
  }
};
export const customTableSort = (a, b) => {
  // console.log(a , b , 'hi sorting')
  if (!a) {
    // Change this values if you want to put `null` values at the end of the array
    return -1;
  }
  if (!b) {
    // Change this values if you want to put `null` values at the end of the array
    return +1;
  }
  let aA = a.replace(reA, '')?.toLowerCase();
  let bA = b.replace(reA, '')?.toLowerCase();
  if (aA === bA) {
    var aN = parseInt(a.replace(reN, ''), 10);
    var bN = parseInt(b.replace(reN, ''), 10);
    return aN === bN ? 0 : (aN > bN) || (b.replace(reN, '') === '') ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
};

export const getBackGroundColor = (orderStatus) => {
  switch ((orderStatus || '')?.toLowerCase()) {
    // case 'collected':
    //   return '#EA7601';
    case 'out for delivery':
      return '#025598';
    case 'in transit':
      return '#F7BC00';
    case 'delivered':
      return '#76BD22';
    case 'order received':
      return '#ED7700';
    case 'exception':
      return '#9A77E8';
    case 'delivery attempted':
      return '#008AFF';
    default:
      return '#FFE4B5';
  }
};

export const isPickUpAvailableInAccount = (acc) => acc?.configuration?.firstMileDropOffAllowed === false;
export const dateFormatForSummaryPage = (date) => {
  if (date) {
    var d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;

    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    let year = d.getFullYear();

    return [day, month, year].join('/');
  }
};

export const customDebounce = (func, wait = 300) => {
  let timeout;
  return (...args) => {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout =
      (() => {
        timeout = null;
        func.apply(context, args);
      },
        wait);
  };
};

export const setValuesToShipmentForm = (
  responseObj,
  formikValues,
  countries = [],
  currencies = [],
  userDetails = [],
) => {
  const newFormValues = {
    ...setValuesToShipmentSetupDetails(responseObj, countries, userDetails),
    ...setValuesToShipmentParcelDetails(responseObj),
    ...setValuesToShipmentDeliveryAddress(responseObj),
    ...setValuesToShipmentDeliveryOptions(responseObj),
    ...setValuesToShipmentProductAndService(responseObj),
    ...setValuesToShipmentAdditionalInfo(responseObj),
    ...setValuesToShipmentReturnOption(responseObj),
    ...setValuesToShipmentBasicDeclaration(responseObj, currencies),
    ...setValuesToShipmentCustomDeclaration(responseObj, countries, currencies),
  };
  const deliveryAddress = ((responseObj || {}).shipments || [])[0].consigneeAddress;
  if ((deliveryAddress || {}).country !== 'GB') {
    const parcelDimensions = ((responseObj || {}).shipments || [])[0].pieces;
    const intlParcelDimensonsArray = [];
    parcelDimensions.map(element => {
      const obj = {
        height: element.height,
        length: element.length,
        weight: element.weight,
        width: element.width,
        pieceRefId: element.pieceRefId,
        isValid: (isValidNumber(element.length) && isValidNumber(element.height) && isValidNumber(element.weight) && isValidNumber(element.width))
      }
      intlParcelDimensonsArray.push(obj);
    });
    newFormValues['intlParcelDetails'] = intlParcelDimensonsArray;
  }
  removeUndefinedProperties(newFormValues);
  const formValues = { ...formikValues, ...newFormValues };
  return formValues;
};

export const setValuesToCarriageForwardForm = (responseObj, formikValues, userDetails = []) => {
  const newFormValues = {
    ...setValuesToShipmentParcelDetails(responseObj),
    ...setValuesToCarriageForwardReturnType(responseObj),
    ...setValuesToCarriageForwardFromDeliveryAddress(responseObj, userDetails),
    ...setValuesToCarriageForwardToDeliveryAddress(responseObj, formikValues),
    ...setValuesToCarriageForwardDeliveryOptions(responseObj),
    ...setValuesToBasicDetailsCollectionDate(responseObj),
    ...setValuesToCollectionDetails(responseObj),
  };

  // if (responseObj.destinationCountry !== 'GBR') {
  //   // const parcelDimensions = (((responseObj || {}).parcelDetails || {}).parcelDimensions || [])[0];
  //   const parcelDimensions = ((responseObj || {}).shipments || [])[0].pieces
  //   newFormValues['length'] = (parcelDimensions || {}).length;
  //   newFormValues['width'] = (parcelDimensions || {}).width;
  //   newFormValues['height'] = (parcelDimensions || {}).height;
  // }
  removeUndefinedProperties(newFormValues);
  const formValues = { ...formikValues, ...newFormValues };
  return formValues;
};

export const setValuesToCarriageForwardReturnType = (responseObj) => {
  const returnType = (((responseObj || {}).shipments || [])[0] || {}).shipmentDetails;
  if (returnType.carriageForward === true) {
    return {
      returnType: CONSTANTS.ReturnTypes[1].value,
    };
  } else {
    return {
      returnType: CONSTANTS.ReturnTypes[0].value,
    };
  }
};

export const setValuesToShipmentSetupDetails = (responseObj, countries = [], userDetails = []) => {
  const parcelMethod = (responseObj || {}).dropOffType;
  const pickup = (responseObj || {}).pickup;
  const deliveryAddress = ((responseObj || {}).shipments || [])[0].consigneeAddress;
  var selectedCountry = (countries || []).find((country) => country?.code2Digit == deliveryAddress?.country);
  var parcelMethodValue = 1;
  if (parcelMethod && parcelMethod.toLowerCase() == 'dropoff') parcelMethodValue = 2;
  return {
    handOverMethod: CONSTANTS.reverseHandOverMethod[parcelMethodValue],
    accountNumber: setDropdownValues(userDetails.accounts, 'accountId', responseObj.pickupAccount),
    DispatchDate: formatDate((pickup || {}).date),
    destinationCountry: setDropdownValues(countries, 'code', selectedCountry?.code),
  };
};

export const setValuesToCollectionDetails = (responseObj) => {
  return {
    collectionInstructions: responseObj.pickup.instructions,
    descriptionOfGoods: responseObj.shipments[0].shipmentDetails.shipmentDesc,
  };
};
export const setValuesToBasicDetailsCollectionDate = (responseObj) => {
  let pickUpdate;
  if ((responseObj || {}).pickup !== null && (responseObj || {}).pickup !== undefined) {
    pickUpdate = (responseObj || {}).pickup.date;
  }
  var d = new Date(),
    month = `${d.getMonth() + 1}`,
    day = `${d.getDate()}`,
    year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }
  let pickup_date = [year, month, day].join('-');
  return {
    collectionDate: pickUpdate !== null ? pickUpdate : pickup_date,
  };
};
export const setValuesToShipmentParcelDetails = (responseObj) => {
  const parcelResponse = ((responseObj || {}).shipments || [])[0].shipmentDetails;
  const parcelDimensions = ((responseObj || {}).shipments || [])[0].pieces;
  const deliveryAddress = ((responseObj || {}).shipments || [])[0].consigneeAddress;
  return {
    noOfItems: (parcelResponse || {}).totalPieces,
    pallets: (parcelResponse || {}).totalPallets,
    weight: (parcelResponse || {}).totalWeight,
    createPalletShipment: (parcelResponse || {}).totalPallets > 0 ? 'yes' : 'no',
    // totalVolumetricWeight: (parcelResponse || {}).totalVolumetricWeight,
    parcelDetails: setShipmentParcelDimensions(parcelDimensions, responseObj),
    addParcelDimensions: Boolean(
      deliveryAddress?.country === 'GB' &&
      // ((responseObj || {}).destinationCountry === 'GBR' || (responseObj || {}).templateName) &&
      (parcelDimensions || []).length > 0,
    ),
  };
};

const setShipmentParcelDimensions = (parcelDimensions, responseObj) => {
  let formattedDimensions = [];
  const deliveryAddress = ((responseObj || {}).shipments || [])[0].consigneeAddress;
  if ((deliveryAddress || {}).country === 'GB') {
    formattedDimensions = (parcelDimensions || []).map((parcel, index) => {
      var volumetricWeightCalc = (parcel.length * parcel.width * parcel.height) / 4000;
      const parcelRecord = {
        ...parcel,
        pieceId: index + 1,
        id: index + 1,
        volumetricWeight: volumetricWeightCalc,
        pieceWeight: parcel.weight,
      };
      return parcelRecord;
    });
  }
  return formattedDimensions;
};

export const setValuesToShipmentDeliveryAddress = (responseObj) => {
  const consigneeAddress = ((responseObj || {}).shipments || [])[0].consigneeAddress;
  const recipientAddress = ((responseObj || {}).shipments || [])[0].recipientAddress;
  if ((consigneeAddress || {}).addressType?.toLowerCase() === 'servicepoint') {
    let recipientType = 'residential';
    if ((recipientAddress || {}).companyName) {
      recipientType = 'business';
    }
    return {
      addressType: recipientType,
      postalCode: (recipientAddress || {}).postalCode,
      recipientName: (recipientAddress || {}).name,
      businessName: (recipientAddress || {}).companyName,
      addressLine1: (recipientAddress || {}).address1?.trim(),
      addressLine2: (recipientAddress || {}).address2?.trim(),
      addressLine3: (recipientAddress || {}).address3?.trim(),
      town: (recipientAddress || {}).city,
      county: (recipientAddress || {}).state,
      telephone: (recipientAddress || {}).phone,
      emailAddress: (recipientAddress || {}).email,
      customerNumber: consigneeAddress?.idNumber ?? "",
    };
  } else {
    return {
      addressType:
        (consigneeAddress?.recipientType === 'business' || consigneeAddress?.companyName)
          ? CONSTANTS.reverseRecipientType[1]
          : CONSTANTS.reverseRecipientType[2],
      postalCode: (consigneeAddress || {}).postalCode,
      recipientName: (consigneeAddress || {}).name,
      businessName: (consigneeAddress || {}).companyName,
      addressLine1: (consigneeAddress || {}).address1?.trim(),
      addressLine2: (consigneeAddress || {}).address2?.trim(),
      addressLine3: (consigneeAddress || {}).address3?.trim(),
      town: (consigneeAddress || {}).city,
      county: (consigneeAddress || {}).state,
      telephone: (consigneeAddress || {}).phone,
      emailAddress: (consigneeAddress || {}).email,
      customerNumber: (consigneeAddress || {}).idNumber,
      what3Words: (consigneeAddress || {}).what3Words?.words,
      w3WCoordinates: (consigneeAddress || {}).what3Words?.geo,
    };
  }
};

export const setValuesToCarriageForwardToDeliveryAddress = (responseObj, formikValues) => {
  const deliveryAddress = (((responseObj || {}).shipments || [])[0] || {}).consigneeAddress;
  if (deliveryAddress.addressType === 'doorstep') {
    return {
      toAddressType: deliveryAddress?.recipientType,
      toPostCode: (deliveryAddress || {}).postalCode,
      toContactName: (deliveryAddress || {}).name?.trim(),
      toBusinessName: (deliveryAddress || {}).companyName?.trim(),
      toAddressLine1: (deliveryAddress || {}).address1?.trim(),
      toAddressLine2: (deliveryAddress || {}).address2?.trim(),
      toAddressLine3: (deliveryAddress || {}).address3?.trim(),
      toTown: (deliveryAddress || {}).city,
      toCounty: (deliveryAddress || {}).state,
      toTelephoneNumber: (deliveryAddress || {}).phone,
      toEmailAddress: (deliveryAddress || {}).email,
      toCustomerNo: (deliveryAddress || {}).idNumber,
      deliveryServicePoint: (deliveryAddress || {}).postalCode,
      what3Words: (deliveryAddress || {}).what3Words?.words,
      w3WCoordinates: (deliveryAddress || {}).what3Words?.geo,
    };
  } else {
    const recipientAddress = (((responseObj || {}).shipments || [])[0] || {}).recipientAddress;
    return {
      toAddressType: deliveryAddress?.recipientType,
      toPostCode: (recipientAddress || {}).postalCode,
      toContactName: (recipientAddress || {}).name,
      toBusinessName: (recipientAddress || {}).companyName,
      toAddressLine1: (recipientAddress || {}).address1?.trim(),
      toAddressLine2: (recipientAddress || {}).address2?.trim(),
      toAddressLine3: (recipientAddress || {}).address3?.trim(),
      toTown: (recipientAddress || {}).city,
      toCounty: (recipientAddress || {}).state,
      toTelephoneNumber: (recipientAddress || {}).phone,
      toEmailAddress: (recipientAddress || {}).email,
      toCustomerNo: (recipientAddress || {}).idNumber,
      deliveryServicePoint: (deliveryAddress || {}).postalCode,
    };
  }
};

export const setValuesToCarriageForwardFromDeliveryAddress = (responseObj, userDetails = []) => {
  const pickupAddress = responseObj && responseObj.pickupAddress;
  const shipmentDetails = (((responseObj || {}).shipments || [])[0] || {}).shipmentDetails;
  return {
    fromAddressType:
      pickupAddress?.recipientType?.trim() ??
      (pickupAddress?.companyName ? CONSTANTS.reverseRecipientType[1] : CONSTANTS.reverseRecipientType[2]),
    accountNumber: setDropdownValues(userDetails.accounts, 'accountId', responseObj.pickupAccount),
    fromParcel: shipmentDetails.totalPieces,
    fromWeight: shipmentDetails.totalWeight,
    fromPostCode: (pickupAddress || {}).postalCode,
    fromContactName: (pickupAddress || {}).name?.trim(),
    fromBusinessName: (pickupAddress || {}).companyName?.trim(),
    fromAddressLine1: (pickupAddress || {}).address1?.trim(),
    fromAddressLine2: (pickupAddress || {}).address2?.trim(),
    fromAddressLine3: (pickupAddress || {}).address3?.trim(),
    fromTown: (pickupAddress || {}).city,
    fromCounty: (pickupAddress || {}).state,
    fromTelephoneNumber: (pickupAddress || {}).phone,
    fromEmailAddress: (pickupAddress || {}).email,
    fromCustomerReference: shipmentDetails?.customerRef1 ?? '',
  };
};
export const setValuesToShipmentDeliveryOptions = (responseObj) => {
  const deliveryOptions = ((responseObj || {}).shipments || [])[0].shipmentDetails;
  const consigneeAddress = ((responseObj || {}).shipments || [])[0].consigneeAddress;
  const addressType = (consigneeAddress || {}).addressType;
  var addressTypeValue = 1;
  if (addressType && addressType.toLowerCase() == 'servicepoint') addressTypeValue = 2;
  return {
    // houseNo: (deliveryOptions || {}).additionalInstruction,
    deliveryType: CONSTANTS.reverseDeliveryType[addressTypeValue],
    doorStepInstructions: setDropdownValues(
      CONSTANTS.doorStepInstructionOptions,
      'value',
      (deliveryOptions || {}).doorStepInstructions,
    ),
    exchange: (deliveryOptions || {}).exchangeOnDelivery ? 'yes' : 'no', // have to check again
    specialInstructions: (deliveryOptions || {}).deliveryInstructions ?? "",
    leaveSafeLocation: deliveryOptions?.secureLocation ?? ""
  };
};

export const setValuesToCarriageForwardDeliveryOptions = (responseObj) => {
  // const deliveryOptions = (responseObj || {}).deliveryOptions;
  const deliveryOptions = ((responseObj || {}).shipments || [])[0].shipmentDetails;
  const consigneeAddress = ((responseObj || {}).shipments || [])[0].consigneeAddress;
  let _specialInstructions = (deliveryOptions || {}).deliveryInstructions;
  return {
    // houseNo: (deliveryOptions || {}).additionalInstruction,
    deliveryType:
      consigneeAddress?.addressType === 'servicepoint'
        ? CONSTANTS.reverseDeliveryType[2]
        : CONSTANTS.reverseDeliveryType[1],
    // doorStepInstructions: setDropdownValues(
    //   CONSTANTS.doorStepInstructionOptions,
    //   'value',
    //   (deliveryOptions || {}).doorStepInstructions,
    // ),
    exchange: (deliveryOptions || {}).exchangeOnDelivery ? 'yes' : 'no', // have to check again
    specialInstructions:
      _specialInstructions != null
        ? _specialInstructions.length <= 30
          ? (deliveryOptions || {}).deliveryInstructions
          : _specialInstructions.slice(0, 30)
        : '',
    additionalInstruction:
      _specialInstructions != null ? (_specialInstructions.length <= 30 ? '' : _specialInstructions.slice(30)) : '',
    leaveSafeLocation: (deliveryOptions || {}).secureLocation,
  };
};

export const setValuesToShipmentProductAndService = (responseObj) => {
  return {
    // product:(responseObj || {}).product
    // service:responseObj.service,
  };
};

export const setValuesToShipmentAdditionalInfo = (responseObj) => {
  const additionalInfo = ((responseObj || {}).shipments || [])[0].shipmentDetails;
  const consigneeAddress = ((responseObj || {}).shipments || [])[0].consigneeAddress;
  const extendedLiability = ((responseObj || {}).shipments || [])[0].extendedLiability;
  return {
    alternateReference: (additionalInfo || {}).customerRef2,
    customerReference: (additionalInfo || {}).customerRef1,
    extendedCoverDomestic: (extendedLiability || {}).extendedLiabilityUnits,
    extendedCoverInternational: (extendedLiability || {}).extendedLiabilityRequired,
  };
};

export const setValuesToShipmentReturnOption = (responseObj) => {
  const returnOption = ((responseObj || {}).shipments || [])[0].shipmentDetails;
  const returnRefValue = ((responseObj || {}).shipments || [])[0].return;
  return {
    inboxReturnLabel: (returnOption || {}).inBoxReturn ? `${(returnOption || {}).inBoxReturn}` : 'false',
    returnReference: (returnRefValue || {}).customerRef1 || '',
  };
};

export const setValuesToShipmentBasicDeclaration = (responseObj, currencies = []) => {
  const basicDetails = ((responseObj || {}).shipments || [])[0].shipmentDetails;
  const customDeclaration = (((responseObj || {}).shipments || [])[0].pieces || [])[0];
  return {
    descriptionOfGoods: (basicDetails || {}).shipmentDesc,
    customDeclarationValue: (customDeclaration || {}).declaredValue,
    customDeclarationCurrency: setDropdownValues(currencies, 'code', (basicDetails || {}).currency),
  };
};

export const setValuesToCarriageForwardBasicDeclaration = (responseObj, currencies = []) => {
  // const customDeclaration = responseObj.customDeclaration;
  const customDeclaration = ((responseObj || {}).shipments || [])[0].shipmentDetails;
  return {
    descriptionOfGoods: (customDeclaration || {}).shipmentDesc,
    // customDeclarationValue:(customDeclaration||{}), // check with abhinav
    // customDeclarationCurrency: setDropdownValues(currencies, 'code', (customDeclaration || {}).currency),
  };
};

export const setValuesToShipmentCustomDeclaration = (responseObj, countries = []) => {
  const shipmentDetails = ((responseObj || {}).shipments || [])[0].shipmentDetails;
  const customDeclaration = ((responseObj || {}).shipments || [])[0].pieces;
  const customsInvoice = ((responseObj || {}).shipments || [])[0].customsInvoice;
  const receipentRegistrations = (((responseObj || {}).shipments || [])[0].recipientCustomsRegistrations || [])[0];
  const senderRegistrations = (((responseObj || {}).shipments || [])[0].senderCustomsRegistrations || []).find(
    (custom) => custom?.type === 'EORI',
  );
  const senderFdaRegistrations = (((responseObj || {}).shipments || [])[0].senderCustomsRegistrations || []).find(
    (custom) => custom?.type !== 'EORI' && custom?.type !== 'IOSS',
  );
  // var reasonForExport = Object.keys(shipmentRequestAPIConstants.reasonForExport).find(
  //   (key) => shipmentRequestAPIConstants.reasonForExport[key] === customsInvoice?.reasonForExport,
  // );
  return {
    invoiceTypeLabel:
      (customsInvoice || {}).type === 'proforma' ? CONSTANTS.reverseInvoiceType[2] : CONSTANTS.reverseInvoiceType[1],
    InvoiceDate: formatDate(customsInvoice?.date), // have to check
    InvoiceNumber: customsInvoice?.number,
    customDeclarationShippingCharges: shipmentDetails?.freightCharges,
    customDeclarationShippersEoriNo: senderRegistrations?.id,
    customDeclarationReceiversEoriNo: receipentRegistrations?.id,
    // reasonForExport: setDropdownValues(CONSTANTS.reasonForExportOptions, 'label', reasonForExport),
    FDARegistrationNumber: senderFdaRegistrations?.id,
    TermsOfDelivery: shipmentDetails?.dutiesPaid,
    commodityDetails: setShipmentCommodityDetails(customDeclaration?.[0]?.customsDetails, countries),
  };
};

export const setShipmentCommodityDetails = (commodityDetails = [], countries = []) => {
  const formattedCommodities = [];
  if (commodityDetails && countries) {
    commodityDetails.forEach((commodity) => {
      var selectedCountry = countries.find((country) => country.code2Digit == commodity.countryOfOrigin);
      formattedCommodities.push({
        commodityCode: commodity.hsCode,
        fullDescriptionOfGoods: commodity.itemDescription,
        weight: commodity.itemWeight,
        quantity: commodity.packagedQuantity,
        unitValue: commodity.itemValue,
        countryOfOrigin: setDropdownValues(countries, 'code', selectedCountry?.code),
      });
    });
  }
  return formattedCommodities;
};

const getAddressFromAccount = (accountAddress) => {
  return {
    companyName: accountAddress.companyName,
    address1: accountAddress.address1,
    address2: accountAddress.address2,
    address3: accountAddress.address3,
    city: accountAddress.city,
    state: accountAddress.state,
    postalCode: accountAddress.postalCode,
    country: accountAddress.country,
    name: accountAddress.name,
    phone: accountAddress.phone,
    email: accountAddress.email,
    recipientType: 'business', // verify
    addressType: 'doorstep', //verify
    // recipientType: formikValues[`${formName}AddressType`], //verify
  };
};
const getSaveAddressFromAccount = (collectionResponse, accountAddress) => {
  return {
    companyName: accountAddress.companyName,
    address1: accountAddress.address1,
    address2: accountAddress.address2,
    address3: accountAddress.address3,
    city: accountAddress.city,
    state: accountAddress.state,
    postalCode: accountAddress.postalCode,
    country: accountAddress.country,
    name: accountAddress.name,
    phone: accountAddress.phone,
    email: accountAddress.email,
    recipientType: 'business', // verify
    addressType: 'doorstep', //verify
    // recipientType: formikValues[`${formName}AddressType`], //verify
    locationId: collectionResponse.shipmentModel.shipments[0].consigneeAddress.locationId,
    locationType: collectionResponse.shipmentModel.shipments[0].consigneeAddress.locationType,
    idNumber: collectionResponse.shipmentModel.shipments[0].consigneeAddress.idNumber,
    idType: collectionResponse.shipmentModel.shipments[0].consigneeAddress.idType,
    what3Words: collectionResponse.shipmentModel.shipments[0].consigneeAddress.what3Words,
  };
};

const getAddressForShipmentApi = (formikValues, formName) => {
  const what3Words = {
    words: formikValues.what3Words,
    geo:{
    latitude: formikValues.w3WCoordinates?.lat || formikValues.w3WCoordinates?.latitude,
    longitude: formikValues.w3WCoordinates?.lng || formikValues.w3WCoordinates?.longitude,
    }
  };
  
  return {
    companyName: formikValues[`${formName}BusinessName`]?.trim(),
    address1: formikValues[`${formName}AddressLine1`]?.trim(),
    address2: formikValues[`${formName}AddressLine2`]?.trim(),
    address3: formikValues[`${formName}AddressLine3`]?.trim(),
    city: formikValues[`${formName}Town`],
    state: formikValues[`${formName}County`],
    postalCode: formikValues[`${formName}PostCode`],
    country: 'GB',
    name: formikValues[`${formName}ContactName`],
    phone: formikValues[`${formName}TelephoneNumber`],
    email: formikValues[`${formName}EmailAddress`],
    recipientType: formikValues[`${formName}AddressType`],
    addressType:
      formName === 'from'
        ? shipmentRequestAPIConstants.deliveryType['deliverToDoorStep']
        : shipmentRequestAPIConstants.deliveryType[formikValues.deliveryType],
    what3Words: (formName === 'to' && what3Words?.geo && what3Words?.words)? what3Words : {},
  };
};

const getConsigneeSaveAddressCollectionApi = (collectionResponse, formikValues, formName) => {
  return {
    companyName: formikValues[`${formName}BusinessName`]?.trim(),
    address1: formikValues[`${formName}AddressLine1`]?.trim(),
    address2: formikValues[`${formName}AddressLine2`]?.trim(),
    address3: formikValues[`${formName}AddressLine3`]?.trim(),
    city: formikValues[`${formName}Town`],
    state: formikValues[`${formName}County`],
    postalCode: formikValues[`${formName}PostCode`],
    country: 'GB',
    name: formikValues[`${formName}ContactName`],
    phone: formikValues[`${formName}TelephoneNumber`],
    email: formikValues[`${formName}EmailAddress`],
    recipientType: formikValues[`${formName}AddressType`],
    addressType: shipmentRequestAPIConstants.deliveryType[formikValues.deliveryType],
    locationId: collectionResponse.shipmentModel.shipments[0].consigneeAddress.locationId,
    locationType: collectionResponse.shipmentModel.shipments[0].consigneeAddress.locationType,
    idNumber: collectionResponse.shipmentModel.shipments[0].consigneeAddress.idNumber,
    idType: collectionResponse.shipmentModel.shipments[0].consigneeAddress.idType,
    what3Words: collectionResponse.shipmentModel.shipments[0].consigneeAddress.what3Words,
  };
};

const formatServicePointAddress = (servicePointDetails, formikValues) => {
  const serivcePointAddress = servicePointDetails?.address;
  return {
    companyName: servicePointDetails?.title || serivcePointAddress?.businessName,
    address1: serivcePointAddress?.businessName,
    address2: serivcePointAddress?.address1?.trim(),
    address3: serivcePointAddress?.address2?.trim(),
    city: serivcePointAddress?.postalTown,
    state: serivcePointAddress?.county,
    postalCode: serivcePointAddress?.zipcode,
    country: 'GB', // serivcePointAddress?.threeDigitCountryCode, // three digit instead of two
    name: 'ShopKeeper',
    phone: formikValues.toTelephoneNumber,
    email: formikValues.toEmailAddress,
    locationType: serivcePointAddress?.addressType,
    recipientType: formikValues.toAddressType,
    addressType: shipmentRequestAPIConstants.deliveryType[formikValues.deliveryType],
    locationId: servicePointDetails?.servicePointID,
  };
};
const formatSaveCollectionServicePointAddress = (collectionResponse, servicePointDetails, formikValues, formName) => {
  // console.log(collectionResponse, servicePointDetails);
  const serivcePointAddress = servicePointDetails
    ? servicePointDetails?.address
    : collectionResponse.shipmentModel.shipments[0].consigneeAddress;
  return {
    companyName: servicePointDetails ? serivcePointAddress.businessName : serivcePointAddress.companyName,
    address1: serivcePointAddress.address1?.trim(),
    address2: serivcePointAddress.address2?.trim(),
    address3: serivcePointAddress.address3?.trim(),
    city: servicePointDetails ? serivcePointAddress.postalTown : serivcePointAddress.city,
    state: servicePointDetails ? serivcePointAddress.county : serivcePointAddress.state,
    postalCode: servicePointDetails ? serivcePointAddress.zipcode : serivcePointAddress.postalCode,
    country: 'GB', // serivcePointAddress.threeDigitCountryCode, // three digit instead of two
    name: formikValues.toContactName,
    phone: formikValues.toTelephoneNumber,
    email: formikValues.toEmailAddress,
    locationType: servicePointDetails ? serivcePointAddress.addressType : serivcePointAddress.locationType,
    recipientType: formikValues.toAddressType,
    addressType: shipmentRequestAPIConstants.deliveryType[formikValues.deliveryType],
    locationId: servicePointDetails ? servicePointDetails?.servicePointID : serivcePointAddress.locationId,
    idNumber: serivcePointAddress.idNumber,
    idType: serivcePointAddress.idType,
    what3Words: serivcePointAddress.what3Words,
  };
};

export const createCollectionReqObj = (collectionResponse, formikValues, servicePointSelected) => {
  let collectionResponse_length = Object.keys(collectionResponse).length;
  const isReturnToSender = formikValues.returnType === 'returnToSender';
  const reqObj = {
    pickupAccount: formikValues?.accountNumber?.accountId,
    dropoffType: formikValues?.handOverMethod === 'servicePoint' ? 'Dropoff' : 'Pickup', // not available
    pickup:
      formikValues?.handOverMethod === 'servicePoint'
        ? null
        : {
          date: formatDate(formikValues.collectionDate),
          accountAddress: false, // need to check
          // pickupIdentifier: 'PICKUP12345', // need to check
          instructions: formikValues?.collectionInstructions,
        },
    senderAddress: getAddressForShipmentApi(formikValues, 'from'),
    pickupAddress:
      formikValues?.handOverMethod === 'servicePoint' ? null : getAddressForShipmentApi(formikValues, 'from'),
    shipments: [
      {
        consigneeAddress: isReturnToSender
          ? { ...getAddressFromAccount(formikValues?.accountNumber?.sender) }
          : formikValues.deliveryType === 'deliverToDoorStep'
            ? getAddressForShipmentApi(formikValues, 'to')
            : formatServicePointAddress(servicePointSelected, formikValues),
        recipientAddress:
          !isReturnToSender && formikValues.deliveryType !== 'deliverToDoorStep'
            ? getAddressForShipmentApi(formikValues, 'to')
            : {},
        shipmentDetails: {
          shipmentId:
            collectionResponse_length > 0
              ? collectionResponse.shipmentModel.shipments[0].shipmentDetails.shipmentId
              : '',
          customerRef1: formikValues.fromCustomerReference, // need to check
          orderedProduct: formikValues?.toServiceRequired?.orderedProduct,
          totalWeight: formikValues?.fromWeight,
          // secureLocation: formikValues?.leaveSafeLocation,
          carriageForward: formikValues.returnType === 'sendToThirdParty',
          thirdPartyCollection: formikValues.returnType === 'returnToSender',
          deliveryInstructions: formikValues.additionalInstruction ? `${formikValues.specialInstructions} ${formikValues.additionalInstruction}` : formikValues.specialInstructions,
          totalPieces: formikValues.fromParcel,
          shipmentDesc: formikValues?.descriptionOfGoods,
          inBoxReturn:
            collectionResponse_length > 0
              ? collectionResponse.shipmentModel.shipments[0].shipmentDetails.inBoxReturn
              : false,
          currency:
            collectionResponse_length > 0 ? collectionResponse.shipmentModel.shipments[0].shipmentDetails.currency : '',
          documentsOnly: false,
        },
        // extendedLiability: {  // need to check
        //   extendedLiabilityUnits: 2,
        // },
        return: collectionResponse_length > 0 ? collectionResponse.shipmentModel.shipments[0].return : null,
        // pieces: [
        //   {
        //     weight: formikValues?.fromWeight,
        //   },
        // ],
        pieces: collectionResponse_length > 0 ? collectionResponse.shipmentModel.shipments[0].pieces : [],
        customsDetails:
          collectionResponse_length > 0
            ? collectionResponse.shipmentModel.shipments[0].pieces[0]?.customsDetails
            : null,
        senderCustomsRegistrations:
          collectionResponse_length > 0 ? collectionResponse.shipmentModel.shipments[0].senderCustomsRegistrations : [],
        recipientCustomsRegistrations:
          collectionResponse_length > 0
            ? collectionResponse.shipmentModel.shipments[0].recipientCustomsRegistrations
            : [],
      },
    ],
  };

  if (isReturnToSender || formikValues.deliveryType === 'deliverToDoorStep') {
    delete reqObj?.shipments?.[0]?.recipientAddress;
  }
  return reqObj;
};
export const saveCollectionReqObj = (collectionResponse, formikValues, servicePointSelected) => {
  const isReturnToSender = formikValues.returnType === 'returnToSender';
  const reqObj = {
    id: collectionResponse.id,
    organization: collectionResponse.organization,
    referenceNumber: formikValues.fromCustomerReference,
    source: collectionResponse.source,
    shipmentOrdersName: collectionResponse.shipmentOrdersName,
    status: collectionResponse.status,
    documentCreatedOn: collectionResponse.documentCreatedOn,
    sourceReference: collectionResponse?.sourceReference,
    subSource: collectionResponse?.subSource,
    shipmentModel: {
      pickupAccount: formikValues?.accountNumber?.accountId,
      dropoffType: 'PICKUP', // not available
      destinationCountry: collectionResponse.shipmentModel.destinationCountry,
      service: collectionResponse.shipmentModel.service,
      product: collectionResponse.shipmentModel.product,
      shipmentId: collectionResponse.shipmentModel.shipmentId,
      pickup: {
        date: formatDate(formikValues.collectionDate),
        accountAddress: false, // need to check
        // pickupIdentifier: 'PICKUP12345', // need to check
        instructions: formikValues?.collectionInstructions,
        pickupIdentifier: collectionResponse.shipmentModel.pickup.pickupIdentifier,
        secureLocation: collectionResponse.shipmentModel.pickup.secureLocation,
        labelRequired: collectionResponse.shipmentModel.pickup.labelRequired,
      },
      pickupAddress: getAddressForShipmentApi(formikValues, 'from'),
      senderAddress: getAddressForShipmentApi(formikValues, 'from'),
      createdOn: collectionResponse.shipmentModel.createdOn,
      isDelivered: collectionResponse.shipmentModel.isDelivered,
      isDeferred: collectionResponse.shipmentModel.isDeferred,
      isLive: collectionResponse.shipmentModel.isLive,
      dueDate: collectionResponse.shipmentModel.dueDate,
      // },
      shipments: [
        {
          consigneeAddress: isReturnToSender
            ? { ...getSaveAddressFromAccount(collectionResponse, formikValues?.accountNumber?.sender) }
            : formikValues.deliveryType === 'deliverToDoorStep'
              ? getConsigneeSaveAddressCollectionApi(collectionResponse, formikValues, 'to')
              : formatSaveCollectionServicePointAddress(collectionResponse, servicePointSelected, formikValues, 'to'),
          // : getAddressForShipmentApi(servicePointSelected, "to"),
          recipientAddress:
            !isReturnToSender && formikValues.deliveryType !== 'deliverToDoorStep'
              ? getAddressForShipmentApi(formikValues, 'to')
              : null,
          shipmentDetails: {
            shipmentId: collectionResponse.shipmentModel.shipments[0].shipmentDetails.shipmentId,
            customerRef1: formikValues.fromCustomerReference, // need to check
            orderedProduct: formikValues?.toServiceRequired?.orderedProduct,
            // totalItems: formikValues?.fromParcel, // check again
            totalPieces: formikValues.fromParcel,
            totalWeight: formikValues?.fromWeight,
            inBoxReturn: collectionResponse.shipmentModel.shipments[0].shipmentDetails.inBoxReturn,
            shipmentDesc: formikValues?.descriptionOfGoods,
            dutiesPaid: collectionResponse.shipmentModel.shipments[0].shipmentDetails.dutiesPaid,
            freightCharges: collectionResponse.shipmentModel.shipments[0].shipmentDetails.freightCharges,
            currency: collectionResponse.shipmentModel.shipments[0].shipmentDetails.currency,
            // deliveryInstructions: formikValues.specialInstructions,
            deliveryInstructions: formikValues.additionalInstruction ? `${formikValues.specialInstructions} ${formikValues.additionalInstruction}` : formikValues.specialInstructions,
            secureLocation: formikValues?.leaveSafeLocation,
            partnerCode: collectionResponse?.shipmentModel?.shipments[0]?.shipmentDetails?.partnerCode,
            partnerOrderId: collectionResponse?.shipmentModel?.shipments[0]?.shipmentDetails?.partnerOrderId,
            totalPallets: collectionResponse.shipmentModel.shipments[0].shipmentDetails.totalPallets,
            exchangeOnDelivery: collectionResponse.shipmentModel.shipments[0].shipmentDetails.exchangeOnDelivery,
            carriageForward: formikValues.returnType === 'sendToThirdParty',
            thirdPartyCollection: formikValues.returnType === 'returnToSender',
            directInjectionCustomerId:
              collectionResponse.shipmentModel.shipments[0].shipmentDetails.directInjectionCustomerId,
          },
          // extendedLiability: {  // need to check
          //   extendedLiabilityUnits: 2,
          // },
          // pieces: [
          //   {
          //     pieceId: collectionResponse.shipmentModel.shipments[0].pieces[0].pieceId,
          //     pieceRefId: collectionResponse.shipmentModel.shipments[0].pieces[0].pieceRefId,
          //     declaredValue: collectionResponse.shipmentModel.shipments[0].pieces[0].declaredValue,
          //     weight: formikValues?.fromWeight,
          //     length: collectionResponse.shipmentModel.shipments[0].pieces[0].length,
          //     width: collectionResponse.shipmentModel.shipments[0].pieces[0].width,
          //     height: collectionResponse.shipmentModel.shipments[0].pieces[0].height,
          //     diameter: collectionResponse.shipmentModel.shipments[0].pieces[0].diameter,
          //     customsDetails: collectionResponse.shipmentModel.shipments[0].pieces[0].customsDetails,
          //     isBulky: collectionResponse.shipmentModel.shipments[0].pieces[0].isBulky
          //   },
          // ],
          pieces: collectionResponse.shipmentModel.shipments[0].pieces,
          customsInvoice: collectionResponse.shipmentModel.shipments[0].customsInvoice,
          // return: {
          //   returnShipmentId: collectionResponse.shipmentModel.shipments[0].return.returnShipmentId,
          //   customerRef1: formikValues.fromCustomerReference,
          //   returnAccount: collectionResponse.shipmentModel.shipments[0].return.returnAccount,
          //   shipmentDesc: formikValues?.descriptionOfGoods,
          //   declaredValue: collectionResponse.shipmentModel.shipments[0].return.declaredValue
          // },
          return: collectionResponse.shipmentModel.shipments[0].return,
          extendedLiability: collectionResponse.shipmentModel.shipments[0].extendedLiability,
          // senderCustomsRegistrations: [
          //   {
          //     type: collectionResponse.shipmentModel.shipments[0].senderCustomsRegistrations[0].type,
          //     id: collectionResponse.shipmentModel.shipments[0].senderCustomsRegistrations[0].id
          //   }
          // ],
          // recipientCustomsRegistrations: [
          //   {
          //     type: collectionResponse.shipmentModel.shipments[0].recipientCustomsRegistrations[0].type,
          //     id: collectionResponse.shipmentModel.shipments[0].recipientCustomsRegistrations[0].id
          //   }
          // ],
          senderCustomsRegistrations: collectionResponse.shipmentModel.shipments[0].senderCustomsRegistrations,
          recipientCustomsRegistrations: collectionResponse.shipmentModel.shipments[0].recipientCustomsRegistrations,
          customerPrice: collectionResponse.shipmentModel.shipments[0].customerPrice,
        },
      ],
      correlationId: collectionResponse.shipmentModel.correlationId,
    },
    isValid: collectionResponse.isValid,
    errors: collectionResponse.errors,
    // }
    // ],
  };

  if (isReturnToSender || formikValues.deliveryType === 'deliverToDoorStep') {
    delete reqObj?.shipments?.[0]?.recipientAddress;
  }
  return reqObj;
};

export const arrayToString = (arrayObj, paramsName, propertyName) => {
  let concatedString = '';
  arrayObj.forEach((ele) => {
    concatedString += `&${paramsName}=${ele[propertyName]}`;
  });
  return concatedString;
};
export const checkToken = () => {
  return sessionStorage.getItem(`msal.${applicationID}.idtoken`);
};

export const saveOrderReqObj = (orderResponse, formikValues, servicePointSelected) => {
  const isServicePoint = formikValues.deliveryType.toLowerCase() === 'delivertoservicepoint';

  const reqObj = {
    id: orderResponse?.id,
    organization: orderResponse?.organization,
    referenceNumber: formikValues?.customerReference,
    source: orderResponse?.source,
    shipmentOrdersName: orderResponse?.shipmentOrdersName,
    status: orderResponse?.status,
    documentCreatedOn: orderResponse?.documentCreatedOn,
    sourceReference: orderResponse?.sourceReference,
    subSource: orderResponse?.subSource,
    delimiter: orderResponse?.delimiter,
    shipmentModel: {
      correlationId: orderResponse?.shipmentModel?.correlationId,
      createdOn: orderResponse?.shipmentModel?.createdOn,
      destinationCountry: orderResponse?.shipmentModel?.destinationCountry,
      dropOffType: formikValues?.handOverMethod === 'driverCollection' ? 'PICKUP' : 'dropoff', //shipmentRequestAPIConstants.dropOffType[formikValues.handOverMethod] // not available
      dueDate: orderResponse?.shipmentModel?.dueDate,
      isDeferred: orderResponse?.shipmentModel?.isDeferred,
      isDelivered: orderResponse?.shipmentModel?.isDelivered,
      isLive: orderResponse?.shipmentModel?.isLive,
      pickup: {
        date: formatDate(formikValues?.DispatchDate),
        accountAddress: orderResponse?.shipmentModel?.pickup?.accountAddress,
        instructions: orderResponse?.shipmentModel?.pickup?.instructions,
        pickupIdentifier: orderResponse?.shipmentModel?.pickup?.pickupIdentifier,
        secureLocation: orderResponse?.shipmentModel?.pickup?.secureLocation,
        labelRequired: orderResponse?.shipmentModel?.pickup?.labelRequired,
      },
      pickupAccount: formikValues?.accountNumber?.accountId,
      pickupAddress: orderResponse?.shipmentModel?.pickupAddress,
      shipmentId: orderResponse?.shipmentModel?.shipmentId,
      senderAddress: {
        companyName: orderResponse?.shipmentModel?.senderAddress?.companyName,
        address1: orderResponse?.shipmentModel?.senderAddress?.address1,
        address2: orderResponse?.shipmentModel?.senderAddress?.address2,
        address3: orderResponse?.shipmentModel?.senderAddress?.address3,
        city: orderResponse?.shipmentModel?.senderAddress?.city,
        state: orderResponse?.shipmentModel?.senderAddress?.state,
        postalCode: orderResponse?.shipmentModel?.senderAddress?.postalCode,
        country: orderResponse?.shipmentModel?.senderAddress?.country,
        name: orderResponse?.shipmentModel?.senderAddress?.name,
        phone: orderResponse?.shipmentModel?.senderAddress?.phone,
        email: orderResponse?.shipmentModel?.senderAddress?.email,
        recipientType: orderResponse?.shipmentModel?.senderAddress?.recipientType,
        addressType: orderResponse?.shipmentModel?.senderAddress?.addressType,
      },
      service: orderResponse?.shipmentModel?.service,
      product: orderResponse?.shipmentModel?.product,
      shipments: [
        {
          consigneeAddress: isServicePoint
            ? { ...getSaveAddressFromServicePoint(orderResponse, formikValues, servicePointSelected) }
            : { ...getConsigneeSaveAddressOrderApi(orderResponse, formikValues) },
          recipientAddress: isServicePoint
            ? { ...getRecipentSaveConsigneeAddress(orderResponse, formikValues) }
            : orderResponse?.shipmentModel?.shipments[0]?.recipientAddress
              ? { ...getRecipentShipAddress(orderResponse) }
              : null,
          shipmentDetails: {
            carriageForward: orderResponse?.shipmentModel?.shipments[0]?.shipmentDetails?.carriageForward,
            currency: formikValues?.customDeclarationCurrency?.code,
            shipmentId: orderResponse?.shipmentModel?.shipments[0]?.shipmentDetails?.shipmentId,
            shipmentDesc: formikValues?.descriptionOfGoods,
            customerRef1: formikValues?.customerReference, // need to check
            customerRef2: formikValues?.alternateReference, // need to check
            deliveryInstructions: formikValues?.specialInstructions,
            directInjectionCustomerId:
              orderResponse?.shipmentModel?.shipments[0]?.shipmentDetails?.directInjectionCustomerId,
            dutiesPaid: formikValues?.TermsOfDelivery?.label,
            exchangeOnDelivery: formikValues?.exchange === 'yes' ? true : false,
            freightCharges: parseFloat(formikValues?.customDeclarationShippingCharges || 0),
            inBoxReturn: (formikValues?.inboxReturnLabel === "yes" || formikValues?.inboxReturnLabel === 'true') ? true : false,
            orderedProduct: formikValues?.service?.orderedProduct,
            partnerCode: orderResponse?.shipmentModel?.shipments[0]?.shipmentDetails?.partnerCode,
            partnerOrderId: orderResponse?.shipmentModel?.shipments[0]?.shipmentDetails?.partnerOrderId,
            secureLocation: orderResponse?.shipmentModel?.shipments[0]?.shipmentDetails?.secureLocation,
            thirdPartyCollection: orderResponse?.shipmentModel?.shipments[0]?.shipmentDetails?.thirdPartyCollection,
            totalPieces: formikValues?.noOfItems === '' ? 0 : formikValues?.noOfItems,
            totalWeight: formikValues?.weight === '' ? 0 : formikValues?.weight,
            totalPallets: formikValues?.pallets === null ? 0 : formikValues?.pallets,
          },
          extendedLiability: {
            // need to check
            extendedLiabilityRequired: formikValues?.extendedCoverInternational,
            extendedLiabilityUnits:
              formikValues?.destinationCountry?.code === 'GBR' ? formikValues?.extendedCoverDomestic || 0 : null,
          },
          pieces: [
            {
              pieceId: orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.pieceId,
              pieceRefId: orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.pieceRefId,
              declaredValue: orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.declaredValue,
              weight: formikValues?.destinationCountry?.code2Digit === 'GB' ? formikValues?.weight === '' ? 0 : parseFloat(formikValues?.weight) : formikValues?.intlParcelDetails[0]?.weight === '' ? 0 : parseFloat(formikValues?.intlParcelDetails[0]?.weight),
              length: formikValues?.destinationCountry?.code2Digit === 'GB' ? formikValues?.length === '' ? 0 : parseFloat(formikValues?.length) : formikValues?.intlParcelDetails[0]?.length === '' ? 0 : parseFloat(formikValues?.intlParcelDetails[0]?.length),
              width: formikValues?.destinationCountry?.code2Digit === 'GB' ? formikValues?.width === '' ? 0 : parseFloat(formikValues?.width) : formikValues?.intlParcelDetails[0]?.width === '' ? 0 : parseFloat(formikValues?.intlParcelDetails[0]?.width),
              height: formikValues?.destinationCountry?.code2Digit === 'GB' ? formikValues?.height === '' ? 0 : parseFloat(formikValues?.height) : formikValues?.intlParcelDetails[0]?.height === '' ? 0 : parseFloat(formikValues?.intlParcelDetails[0]?.height),
              diameter: orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.diameter,
              isBulky: orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.isBulky,
              ...(formikValues.isFullCustomDetails && {
                customsDetails:
                  formikValues?.destinationCountry?.code2Digit === 'GB'
                    ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                    : [
                      {
                        countryOfOrigin:
                          formikValues?.commodityDetails?.length > 0
                            ? formikValues?.commodityDetails[0]?.countryOfOrigin?.code2Digit
                            : orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                              ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails[0]
                                ?.countryOfOrigin
                              : null,
                        descriptionExport: orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                          ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails[0]
                            ?.descriptionExport
                          : null,
                        descriptionImport: orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                          ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails[0]
                            ?.descriptionImport
                          : null,
                        hsCode:
                          formikValues?.commodityDetails?.length > 0
                            ? formikValues?.commodityDetails[0]?.commodityCode
                            : orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                              ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails[0]?.hsCode
                              : null,
                        itemDescription:
                          formikValues?.commodityDetails?.length > 0
                            ? formikValues?.commodityDetails[0]?.fullDescriptionOfGoods
                            : orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                              ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails[0]
                                ?.itemDescription
                              : null,
                        itemValue:
                          formikValues?.commodityDetails?.length > 0
                            ? formikValues?.commodityDetails[0]?.unitValue
                            : orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                              ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails[0]?.itemValue
                              : null,
                        itemWeight:
                          formikValues?.commodityDetails?.length > 0
                            ? formikValues?.commodityDetails[0]?.weight
                            : orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                              ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails[0]?.itemWeight
                              : null,
                        packagedQuantity:
                          formikValues?.commodityDetails?.length > 0
                            ? formikValues?.commodityDetails[0]?.quantity
                            : orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                              ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails[0]
                                ?.packagedQuantity
                              : null,
                        skuNumber: orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails
                          ? orderResponse?.shipmentModel?.shipments[0]?.pieces[0]?.customsDetails[0]?.skuNumber
                          : null,
                      },
                    ],
              }),
            },
          ],
          customsInvoice: {
            date: formatDate(formikValues?.InvoiceDate),
            number: formikValues?.InvoiceNumber,
            reasonForExport: formikValues?.reasonForExport?.type ?? '',
            type: formikValues?.invoiceTypeLabel,
          },
          return:
            formikValues.inboxReturnLabel === 'true'
              ? {
                returnShipmentId: orderResponse?.shipmentModel?.shipments[0]?.return?.returnShipmentId,
                customerRef1: formikValues?.returnReference,
                returnAccount: orderResponse?.shipmentModel?.shipments[0]?.return?.returnAccount,
                shipmentDesc: orderResponse?.shipmentModel?.shipments[0]?.return?.shipmentDesc,
                declaredValue: formikValues?.customDeclarationValue ? formikValues?.customDeclarationValue : 0,
              }
              : null,
          senderCustomsRegistrations:
            orderResponse?.shipmentModel?.shipments[0]?.senderCustomsRegistrations.length > 0
              ? (() => {
                let arr = [];
                arr.push({
                  type: orderResponse?.shipmentModel?.shipments[0]?.senderCustomsRegistrations[0]?.type,
                  id: formikValues?.customDeclarationShippersEoriNo,
                });
                if (formikValues?.FDARegistrationNumber) {
                  arr.push({
                    type: orderResponse?.shipmentModel?.shipments[0]?.senderCustomsRegistrations[1]?.type,
                    id: formikValues?.FDARegistrationNumber,
                  });
                }
                return arr;
              })()
              : [],

          recipientCustomsRegistrations:
            orderResponse?.shipmentModel?.shipments[0]?.recipientCustomsRegistrations.length > 0
              ? [
                {
                  type: orderResponse?.shipmentModel?.shipments[0]?.recipientCustomsRegistrations[0]?.type,
                  id: formikValues?.customDeclarationReceiversEoriNo,
                },
              ]
              : [],
          customerPrice: orderResponse?.shipmentModel?.shipments[0]?.customerPrice,

          clearanceDeclaration: formikValues?.shipmentMovement?.value? { ...getSaveClearanceDeclaration(formikValues) }:null,
          

          
        },
      ],
    },
    isValid: orderResponse?.isValid,
    // errors: orderResponse?.errors,
    // }
    // ],
  };

  // if (isReturnToSender || formikValues.deliveryType === 'deliverToDoorStep') {
  //   delete reqObj?.shipments?.[0]?.recipientAddress;
  // }
  if (formikValues?.destinationCountry?.code2Digit !== 'GB') {
    for (let i = 1; i < formikValues.intlParcelDetails.length; i++) {
      const tempPieceObj = {
        pieceId: (i + 1).toString(),
        pieceRefId: formikValues?.intlParcelDetails[i].pieceRefId !== '' ? formikValues?.intlParcelDetails[i].pieceRefId : null,
        declaredValue: 0,
        weight: formikValues?.intlParcelDetails[i]?.weight === '' ? 0 : parseFloat(formikValues?.intlParcelDetails[i]?.weight),
        length: formikValues?.intlParcelDetails[i]?.length === '' ? 0 : parseFloat(formikValues?.intlParcelDetails[i]?.length),
        width: formikValues?.intlParcelDetails[i]?.width === '' ? 0 : parseFloat(formikValues?.intlParcelDetails[i]?.width),
        height: formikValues?.intlParcelDetails[i]?.height === '' ? 0 : parseFloat(formikValues?.intlParcelDetails[i]?.height),
        diameter: 0,
        customsDetails: null,
        isBulky: false,
      };
      reqObj.shipmentModel.shipments[0].pieces.push(tempPieceObj);
    }

    for (let i = 1; i < formikValues.commodityDetails.length; i++) {
      reqObj.shipmentModel.shipments[0].pieces[0].customsDetails.push({
        countryOfOrigin: formikValues?.commodityDetails[i]?.countryOfOrigin?.code2Digit,
        descriptionExport: null,
        descriptionImport: null,
        hsCode: formikValues?.commodityDetails[i]?.commodityCode,
        itemDescription: formikValues?.commodityDetails[i]?.fullDescriptionOfGoods,
        itemValue: formikValues?.commodityDetails[i]?.unitValue,
        packagedQuantity: formikValues?.commodityDetails[i]?.quantity,
        skuNumber: null,
        itemWeight: formikValues?.commodityDetails[i]?.weight
      })
    }
  }
  return reqObj;
};

const getSaveAddressFromServicePoint = (orderResponse, formikValues, servicePointDetails) => {
  const what3Words = {
    words: formikValues.what3Words,
    geo:{
    latitude: formikValues.w3WCoordinates?.lat || formikValues.w3WCoordinates?.latitude,
    longitude: formikValues.w3WCoordinates?.lng || formikValues.w3WCoordinates?.longitude,
    }
  };
  return {
    companyName: servicePointDetails?.address?.businessName,
    address1: servicePointDetails?.address?.address1?.trim(),
    address2: servicePointDetails?.address?.address2?.trim(),
    address3: servicePointDetails?.address?.address3?.trim(),
    city: servicePointDetails?.address?.postalTown,
    state: servicePointDetails?.state,
    postalCode: servicePointDetails?.address?.zipcode,
    country: formikValues?.destinationCountry?.code2Digit,
    name: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.name,
    phone: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.phone,
    email: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.email,
    recipientType: 'business', // verify
    addressType: 'servicepoint', //verify
    // recipientType: formikValues[`${formName}AddressType`], //verify
    locationId: servicePointDetails ? servicePointDetails?.servicePointID : '',
    locationType: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.locationType,
    idNumber: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.idNumber,
    idType: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.idType,
    what3Words: formikValues.destinationCountry?.code2Digit === 'GB' ? what3Words || {} : {},
  };
};

const getSaveClearanceDeclaration = (formikValues) =>{
  let noOfClearanceItem=0;
  if(formikValues?.shipmentMovement?.value == 'c2c')
    {
   return{
     shipmentMovementType : 'C2C',
     totalValue : Math.round(formikValues?.clearanceTotalValue*100)/100,
     items : null,
     descriptionOfGoods : formikValues?.descriptionOfGoods,
   }
    }
    else if(formikValues?.shipmentMovement?.value == 'c2b')
    {
     const item = formikValues?.commodityDetails.map(obj => {
      noOfClearanceItem+=Number(obj?.quantity);
       return {
       commodityCode: obj?.commodityCode,
       descriptionOfGoods: obj?.fullDescriptionOfGoods,
       unitQuantity:Number(obj?.quantity),
     };
   });
  return{
     shipmentMovementType : 'C2B',
     recipientEORINumber : formikValues?.receipentEORI,
     recipientUKIMSNumber : formikValues?.receipentUKIMS,
     totalValue : Math.round(formikValues?.clearanceTotalValue*100)/100,
     numberOfItems : noOfClearanceItem,
     items : item,
  }
    }
    else if(formikValues?.shipmentMovement?.value == 'b2c')
     {
       const item = formikValues?.commodityDetails.map(obj => {
        noOfClearanceItem+=Number(obj?.quantity);
         return {
         commodityCode: obj?.commodityCode,
         descriptionOfGoods: obj?.fullDescriptionOfGoods,
         unitQuantity:Number(obj?.quantity),
       };
     });
     return{
      shipmentMovementType : 'B2C',
     sendersEORINumber : formikValues?.shipperEORI,
      sendersUKIMSNumber : formikValues?.shipperUKIMS,
      totalValue : Math.round(formikValues?.clearanceTotalValue*100)/100,
      numberOfItems : noOfClearanceItem,
      items : item
     }
     }
     else if(formikValues?.shipmentMovement?.value == 'b2b')
       {
         const item = formikValues?.commodityDetails.map(obj => {
          noOfClearanceItem+=Number(obj?.quantity);
           return {
           commodityCode: obj?.commodityCode,
           descriptionOfGoods: obj?.fullDescriptionOfGoods,
           unitQuantity:Number(obj?.quantity),
           unitValue: obj?.unitValue ? Math.round(obj?.unitValue*100)/100:0,
           unitWeight: obj?.unitWeight ? Math.round(obj?.unitWeight*100)/100:0,
           countryOfManufacture:obj?.countryOfOrigin?.code2Digit,
         };
       });
       return{
        shipmentMovementType : 'B2B',
        sendersEORINumber : formikValues?.shipperEORI,
       sendersUKIMSNumber : formikValues.shipperUKIMS||null,
        recipientEORINumber : formikValues?.receipentEORI ||null,
        recipientUKIMSNumber : formikValues?.receipentUKIMS,
        totalValue : Math.round(formikValues?.clearanceTotalValue*100)/100,
        reasonForExport : (formikValues.shipperUKIMS == '' && formikValues?.receipentUKIMS == '' )?formikValues?.reasonExport?.value:null,
        shippingCharges : (formikValues.shipperUKIMS == '' && formikValues?.receipentUKIMS == '' )?formikValues?.shippingCharges? Math.round(formikValues?.shippingCharges*100)/100:0:0,
        sendersDefermentAccount : (formikValues.shipperUKIMS == '' && formikValues?.receipentUKIMS == '' ) ?formikValues?.shipperDeferment:null,
        numberOfItems : noOfClearanceItem,
        items : item,
       }
      }
       else{
        return{
        clearanceDeclaration :null
        }
       }
      
}

const getConsigneeSaveAddressOrderApi = (orderResponse, formikValues) => {
  const what3Words = {
    words: formikValues.what3Words,
    geo:{
    latitude: formikValues.w3WCoordinates?.lat || formikValues.w3WCoordinates?.latitude,
    longitude: formikValues.w3WCoordinates?.lng || formikValues.w3WCoordinates?.longitude,
    }
  };
  return {
    companyName: formikValues?.businessName,
    address1: formikValues?.addressLine1?.trim(),
    address2: formikValues?.addressLine2?.trim(),
    address3: formikValues?.addressLine3?.trim(),
    city: formikValues?.town,
    state: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.state,
    postalCode: formikValues?.postalCode,
    country: formikValues?.destinationCountry?.code2Digit,
    name: formikValues?.recipientName,
    phone: formikValues?.telephone,
    email: formikValues?.emailAddress,
    recipientType: formikValues?.addressType,
    addressType: shipmentRequestAPIConstants.deliveryType[formikValues?.deliveryType],
    locationId: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.locationId,
    locationType: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.locationType,
    idNumber: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.idNumber,
    idType: orderResponse?.shipmentModel?.shipments[0]?.consigneeAddress?.idType,
    what3Words: formikValues.destinationCountry?.code2Digit === 'GB' ? what3Words || {} : {},
  };
};

const getRecipentSaveConsigneeAddress = (orderResponse, formikValues) => {
  return {
    companyName: formikValues?.businessName,
    address1: formikValues?.addressLine1?.trim(),
    address2: formikValues?.addressLine2?.trim(),
    address3: formikValues?.addressLine3?.trim(),
    city: formikValues?.town,
    state: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.state,
    postalCode: formikValues?.postalCode,
    country: formikValues?.destinationCountry?.code2Digit,
    name: formikValues?.name,
    phone: formikValues?.telephone,
    email: formikValues?.emailAddress,
    recipientType: formikValues?.addressType,
    addressType: shipmentRequestAPIConstants.deliveryType[formikValues?.deliveryType],
  };
};

const getRecipentShipAddress = (orderResponse) => {
  return {
    companyName: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.businessName,
    address1: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.address1?.trim(),
    address2: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.address2?.trim(),
    address3: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.address3?.trim(),
    city: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.city,
    state: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.state,
    postalCode: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.postalCode,
    country: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.country,
    name: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.name,
    phone: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.phone,
    email: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.email,
    recipientType: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.recipientType,
    addressType: orderResponse?.shipmentModel?.shipments[0]?.recipientAddress?.addressType,
  };
};

export const testFunctionForPostCodeRegex = (schema, errorMessage, regexPattern, validateOnlyUK = false) => {
  return schema.test('postalCodeRegex', errorMessage, (postalCode) => {
    const regex = validateOnlyUK ? CONSTANTS.UK_POSTAL_CODE_REGEX : new RegExp(regexPattern);
    if (regex?.test?.(postalCode?.trim()?.toUpperCase())) {
      return true;
    }
    return false;
  });
};

export const remove_duplicates = (a, b, key_one, key_two) => {
  a = a?.filter(function (item) {
    for (var i = 0, len = b.length; i < len; i++) {
      if (b[i].shipmentId == item.id) {
        return false;
      }
    }
    return true;
  });
  return a;
};

export const remove_duplicates_dashboardSummary = (a, b) => {
  a = a.filter(function (item) {
    for (var i = 0, len = b.length; i < len; i++) {
      if (b[i].shipmentId == item.shipmentId) {
        return false;
      }
    }
    return true;
  });
  return a;
};

export const getAllGenralConfiguration = (org, dispatch, actions) => {
  let url = `${ServiceEndPoints.genralConfiguration}?organization=${org}`;
  GET(url)
    .then((response) => {
      dispatch(actions.setGenralConfiguration(response.data));
    })
    .catch((err) => {
      dispatch(actions.setGenralConfiguration(null));
    });
};

export const tradingLocationArrayForm = (tradingLocation, userAccounts, checkForActiveAccounts = false) => {
  let accountsArray = [];
  let LocationArray = [];
  tradingLocation.map((element, i) => {
    let tt = element?.accounts ?? [];
    let tempArray = userAccounts.filter(({ accountId: _accountId, accountStatus: _accountStatus }) =>
      tt.some(({ accountId: accountId }) =>
        checkForActiveAccounts
          ? _accountStatus !== 'OnStop' && _accountStatus !== 'Closed' && accountId === _accountId
          : accountId === _accountId,
      ),
    );
    if (tempArray.length !== 0) {
      LocationArray.push(element);
      tempArray.map((ele) => {
        accountsArray.push(ele);
      });
    }
  });
  return [accountsArray, LocationArray];
};

export const convertTZwithFormat = (date, format) => {
  let _formatDate = moment.tz(date, 'Europe/London').format(format);
  return _formatDate;
};
export const convertTZ = (date) => {
  let _returnObject = {};
  let _formatDate = moment.tz(date, 'Europe/London').format('YYYY-MM-DD hh:mm A');
  // let is_DST_flag = moment.tz(date, 'Europe/London').isDST();
  // console.log("is_DST_flag if", is_DST_flag)
  // if (is_DST_flag) {
  //   console.log("inside if")
  //   let _returnFormatDate = moment.tz(_formatDate, 'Europe/London').utcOffset("+01:00").format('YYYY-MM-DD hh:mm A');
  //   _returnObject.logDate = moment(_returnFormatDate).format('DD/MM/YYYY');
  //   _returnObject.logTime = moment(_returnFormatDate).format('hh:mm A');
  // } else {
  //   console.log("inside esle")
  //   let _returnFormatDate = moment.tz(_formatDate, 'Europe/London').format('YYYY-MM-DD hh:mm A');
  //   _returnObject.logDate = moment(_returnFormatDate).format('DD/MM/YYYY');
  //   _returnObject.logTime = moment(_returnFormatDate).format('hh:mm A');
  // }
  _returnObject.logDate = moment(_formatDate).format('DD/MM/YYYY');
  _returnObject.logTime = moment(_formatDate).format('hh:mm A');
  return _returnObject;
};

/////Please do not add More functions Create another class.
