/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import PropTypes from 'prop-types';
import CustomDialog from '../../../../framework/dialog/customDialog';
import CustomAddressTable from '../../../../framework/CustomAddressTable/CustomAddressTable';
import * as actions from '../../../../store/actions/index';
import { abortOnGoingCallsFor, axiosConfig, GET } from '../../../../api/axios';
import { fullSearchByOptions, UserRoles, MISC_VALUES } from '../../../../utils/constants/constants';
import './setupDetails.scss';
import { useSelector, useDispatch, connect } from 'react-redux';
import {
  getDomesticAccounts,
  getInternationalAccounts,
  getWorkingDays,
  setDropdownValues,
  setValuesToDeliveryAddress,
  setValuesToForm,
  formatDate,
  getCustomerAccountID,
  getFMDAccounts,
  getDriverCollectionAccounts,
  tradingLocationArrayForm,
} from '../../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';

const SetupDetails = (props) => {
  const {
    formField: { handOverMethod, accountNumber, setupSearchBy, destinationCountry, DispatchDate },
    setFieldValue,
    countries,
    errors,
    setCustomerNumber,
    setValues,
    formikValues,
    currencies,
    fromPreviewSummary,
    backUpResponse,
    setAddressSearchObj,
    id,
    shipmentId,
    isAmendShipment,
    flagForAfterSubmitCheck,
    setFlagForAfterSubmitCheck,
    setIsServicePointAddress,
    searchedAddressBookData,
    servicePointWeight
  } = props;

  const [rows, setRows] = React.useState([]);
  const [workingDays, setWorkingDays] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [NFopen, setNFopen] = React.useState(false);
  const [displayAccounts, setDisplayAccounts] = React.useState([]);

  const dispatch = useDispatch();

  const userAccounts = useSelector((state) => state.accounts.nonReturnuserAccounts);
  const [isHandOverDisabled, setHandOverDisabled] = useState(false);
  const [isHandOverDisabledByAccount, setHandOverDisabledByAccount] = useState(false);
  const [isPickUpBooked, setIsPickUpBooked] = useState(false);
  const [isDisPatchDateVisible, setIsDispatchDateVisible] = useState(true);
  const [tradingLocation, setTradingLocation] = useState([]);

  const handleModal = (value) => {
    setOpen(value);
  };

  const handleNFModal = (value) => {
    setNFopen(value);
  };

  const handleClose = (value) => {
    setOpen(false);
    setNFopen(false);
  };

  const sortAlphaNum = (a, b) => a?.companyName?.toLowerCase().localeCompare(b?.companyName?.toLowerCase(), 'en', { numeric: true })

  const getPickupLocation = () => {
    axiosConfig
      .get(`${ServiceEndPoints.getCustomerTradingLocation}?customerAccountId=${getCustomerAccountID()}`)
      .then((res) => {
        let tradingLocation = res.data.tradingLocations.sort(sortAlphaNum);
        setTradingLocation(tradingLocation);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const rowClicked = (val) => {
    if (formikValues?.selectedTemplate && (val?.destinationCountry === 'GBR' || val.destinationCountry === 'GB')) {
      setValues({
        ...formikValues,
        ...setValuesToDeliveryAddress(val)
      });
      dispatch(
        actions.setLoadedFrom({
          loadedFrom: 'dropdownResponse',
          loadedResponse: { ...backUpResponse.loadedFrom.loadedResponse },
        }),
      );

      if (backUpResponse?.loadedFrom?.loadedResponse?.parcelDetails?.weight) {
        setFieldValue('weight', backUpResponse?.loadedFrom?.loadedResponse?.parcelDetails?.weight ?? '');
      } else {
        setFieldValue('weight', formikValues.weight ?? '');
      }
    } else {
      setValues(setValuesToForm(val, formikValues, countries, currencies));
      dispatch(actions.setLoadedFrom({ loadedFrom: 'dropdownResponse', loadedResponse: { ...val } }));
      setFieldValue('weight', val?.parcelDetails?.weight ?? '');
      if(formikValues?.handOverMethod === 'servicePoint'){
        setIsServicePointAddress(true);
      }
    }
    searchedAddressBookData(val);
    setTimeout(() => {
      dispatch(actions.setLoaderLayOver(false));
      dispatch(actions.setLoadedFrom({ loadedFrom: '' }));
    }, 3500);
    dispatch(actions.setBackUpResponse({ loadedFrom: 'favourites', loadedResponse: { ...val } }));
    setCustomerNumber(val?.deliveryAddress?.customerNumber || '');
    handleModal(false);
    setAddressSearchObj(val);
    dispatch(actions.setLoaderLayOver(true));
  };

  const getAddresses = (event) => {
    event.preventDefault();
    let searchedValue = props.formikValues.setupSearchFor.trim();
    if (searchedValue && props.formikValues.setupSearchBy) {
      const url = `${ServiceEndPoints.getAddressBook}?organization=${getCustomerAccountID()}&${props.formikValues.setupSearchBy.value
        }=${searchedValue}`;
      axiosConfig.get(url)
        .then((res) => {
          handleModal(true);
          setRows(res.data.addressBooks);
          if(res.data?.addressBooks?.[0]?.deliveryAddress?.postCode){
            let postalCode = res.data?.addressBooks?.[0]?.deliveryAddress?.postCode;
            abortOnGoingCallsFor(ServiceEndPoints.getPostcodeDistrict);
            axiosConfig
            .get(`${ServiceEndPoints.getPostcodeDistrict}?postcode=${postalCode}`)
            .then((res) => {
              dispatch(actions.setPostalCodeDistrict(res?.data));
            })
            .catch((err) => {
              console.log(err);
              return null;
            });
    
          }

        })
        .catch((err) => {
          handleNFModal(true);
        });
    }
  };

  useEffect(() => {
    displayAccounts.length && setFieldValue('accountNumber', displayAccounts[0]);
    if (
      fromPreviewSummary?.loadedFrom === 'fromPreviewSummary' ||
      (fromPreviewSummary?.loadedFrom === 'dropdownResponse' &&
        fromPreviewSummary?.loadedResponse?.accountNumber?.accountId)
    ) {
      setTimeout(() => {
        setFieldValue(
          'accountId',
          setDropdownValues(displayAccounts, 'accountId', fromPreviewSummary.loadedResponse?.accountNumber?.accountId),
        );
      }, 0);
      setTimeout(() => {
        setFieldValue(
          'accountNumber',
          setDropdownValues(displayAccounts, 'accountId', fromPreviewSummary.loadedResponse?.accountNumber?.accountId),
        );
      }, 0);

    }
  }, [displayAccounts]);

  useEffect(() => {
    const { destinationCountry, weight, noOfItems } = formikValues;
    if (!isAmendShipment && destinationCountry && weight > servicePointWeight) {
      setFieldValue('handOverMethod', 'driverCollection');
      setHandOverDisabled(true);
    } else if ((destinationCountry || {}).code === 'GBR' && noOfItems > 1) {
      setFieldValue('handOverMethod', 'driverCollection');
      setHandOverDisabled(true);
    } else {
      formikValues.createPalletShipment === MISC_VALUES.YES ? setHandOverDisabled(true) : setHandOverDisabled(false);
    }
  }, [formikValues.destinationCountry, formikValues.weight, formikValues.noOfItems, servicePointWeight]);

  useEffect(async () => {
    const { destinationCountry } = props.formikValues;
    let holidayRegion;
    if (formikValues.accountNumber?.sender?.postalCode) {
      abortOnGoingCallsFor(ServiceEndPoints.getPostcodeDistrict);
      holidayRegion = await axiosConfig
        .get(`${ServiceEndPoints.getPostcodeDistrict}?postcode=${formikValues.accountNumber.sender.postalCode}`)
        .then((res) => {
          return res?.data?.ukHolidayRegion;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    }

    if (formikValues?.accountNumber?.configuration && destinationCountry?.code2Digit) {
      abortOnGoingCallsFor(ServiceEndPoints.getPublicHolidays);
      axiosConfig.get(`${ServiceEndPoints.getPublicHolidays}?countryCode=GB`) //${destinationCountry?.code2Digit || 'GB'}`)
        .then((res) => {
          const publicHolidays = holidayRegion
            ? res.data?.publicHolidays.filter(
              (holiday) => holiday.region?.trim()?.toLowerCase() === holidayRegion?.trim()?.toLowerCase(),
            )
            : res.data?.publicHolidays;
          getWorkingDays(
            publicHolidays,
            setWorkingDays,
            true,
            formikValues?.accountNumber?.configuration?.weekendHolidayPickup,
            formikValues?.accountNumber?.configuration?.shipmentForwardDating,
            (destinationCountry?.code2Digit != 'GB') ? true : false
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setFieldValue('setupSearchBy', fullSearchByOptions[0]);
  }, [formikValues.destinationCountry, formikValues.accountNumber]);

  //Setting up the accounts based on country & handover method
  useEffect(() => {
    const { destinationCountry, handOverMethod } = props.formikValues;
    let displayAccounts = [];
    if (userAccounts.length > 0 && destinationCountry && destinationCountry.code) {
      if (destinationCountry && destinationCountry.code === 'GBR') {
        const allDomesticAccount = getDomesticAccounts(userAccounts);
        const domesticFmdAccounts = getFMDAccounts(allDomesticAccount);
        const domesticCollectionAccounts = getDriverCollectionAccounts(allDomesticAccount);

        if (
          formikValues.handOverMethod === 'driverCollection' &&
          domesticCollectionAccounts.length === 0 &&
          domesticFmdAccounts.length > 0
        ) {
          setFieldValue('handOverMethod', 'servicePoint');
          return;
        } else if (
          formikValues.handOverMethod === 'servicePoint' &&
          domesticFmdAccounts.length === 0 &&
          domesticCollectionAccounts.length > 0
        ) {
          setFieldValue('handOverMethod', 'driverCollection');
          return;
        } else {
          handOverMethod === 'driverCollection'
            ? (displayAccounts = domesticCollectionAccounts)
            : (displayAccounts = domesticFmdAccounts);
        }
        domesticCollectionAccounts.length === 0 || domesticFmdAccounts.length === 0
          ? setHandOverDisabledByAccount(true)
          : setHandOverDisabledByAccount(false);
      } else {
        const allInternationalAccount = getInternationalAccounts(userAccounts);
        const internationalFmdAccounts = getFMDAccounts(allInternationalAccount);
        const internationalCollectionAccounts = getDriverCollectionAccounts(allInternationalAccount);
        if (
          formikValues.handOverMethod === 'driverCollection' &&
          internationalCollectionAccounts.length === 0 &&
          internationalFmdAccounts.length > 0
        ) {
          setFieldValue('handOverMethod', 'servicePoint');
          return;
        } else if (
          formikValues.handOverMethod === 'servicePoint' &&
          internationalFmdAccounts.length === 0 &&
          internationalCollectionAccounts.length > 0
        ) {
          setFieldValue('handOverMethod', 'driverCollection');
          return;
        } else {
          handOverMethod === 'driverCollection'
            ? (displayAccounts = internationalCollectionAccounts)
            : (displayAccounts = internationalFmdAccounts);
        }
        internationalCollectionAccounts.length === 0 || internationalFmdAccounts.length === 0
          ? setHandOverDisabledByAccount(true)
          : setHandOverDisabledByAccount(false);
      }
    }

    if (formikValues.handOverMethod === 'servicePoint') {
      setIsDispatchDateVisible(false);
    } else if (formikValues.handOverMethod === 'driverCollection') {
      setIsDispatchDateVisible(true);
    }

    setDisplayAccounts(displayAccounts);
    dispatch(actions.setDisplayAccount(displayAccounts));
  }, [formikValues.destinationCountry, formikValues.handOverMethod]);

  useEffect(() => {
    dispatch(actions.setSelectedAccount(formikValues.accountNumber));
    if (!formikValues.accountNumber && displayAccounts?.length > 0) {
      setFieldValue('accountNumber', displayAccounts[0]);
    }
    if (formikValues.accountNumber) {
      setIossNumber();
    }
  }, [formikValues.accountNumber]);

  const setIossNumber = () => {
    if (!props.IossNumber && formikValues.accountNumber.configuration.iossDHLAccountAllowed) {
      //Call the API to get the IOSS Number from DHL.
      dispatch(actions.setDhlIossNumber('IM0987654321'));
    }
  };

  useEffect(() => {
    setFieldValue('destinationCountry', setDropdownValues(countries, 'code', 'GBR'));
  }, [countries]);

  useEffect(() => {
    formikValues.destinationCountry?.code !== "GBR" && setFieldValue("createPalletShipment", "no") && setFieldValue('TermsOfDelivery', setDropdownValues([], 'label', ''));
  }, [formikValues.destinationCountry]);

  useEffect(() => {
    if (countries.length > 0 && fromPreviewSummary?.loadedFrom === 'fromPreviewSummary') {
      const loadedFormikValues = { ...fromPreviewSummary.loadedResponse };
      loadedFormikValues.product = '';
      loadedFormikValues.service = '';
      loadedFormikValues.customDeclarationCurrency = '';
      loadedFormikValues.destinationCountry = setDropdownValues(
        countries,
        'code',
        loadedFormikValues.destinationCountry.code ? loadedFormikValues.destinationCountry.code : 'GBR',
      );
      setValues({ ...formikValues, ...loadedFormikValues });
      setFieldValue('weight', loadedFormikValues?.weight ?? '');
      setCustomerNumber(loadedFormikValues.customerNumber);
      setTimeout(() => {
        dispatch(actions.setLoadedFrom({ loadedFrom: '' }));
      }, 10000);
      // if (loadedFormikValues.updateAddToAddressBook) {
      //   setTimeout(() => {
      //     setFieldValue('updateAddToAddressBook', true);
      //   }, 100);
      // }
    }
  }, [countries, fromPreviewSummary]);

  useEffect(() => {
    setFieldValue('setupSearchFor', '');
  }, [formikValues.setupSearchBy]);

  useEffect(() => {
    const dispatchProperty = isAmendShipment ? "DispatchDateForAmend" : "DispatchDate";
    const sessionDispatchDate = sessionStorage.getItem(dispatchProperty);
    const firstWorkingDay = workingDays[0];
    const lastWorkingDay = formikValues?.accountNumber?.configuration?.shipmentForwardDating ? workingDays[workingDays.length - 1] : workingDays[4];
    if (
      workingDays.length > 0 &&
      ((!formikValues.DispatchDate && (fromPreviewSummary === '' || fromPreviewSummary?.loadedFrom === '')) ||
        (formikValues.DispatchDate &&
          (new Date(formikValues.DispatchDate).getTime() < new Date(firstWorkingDay).getTime() ||
            new Date(formikValues.DispatchDate).setHours(0, 0, 0, 0) > new Date(lastWorkingDay).getTime())))
    ) {
      if (id !== undefined || shipmentId !== undefined) {
        setTimeout(() => {
          setFieldValue('DispatchDate', firstWorkingDay);
          sessionStorage.setItem(dispatchProperty, firstWorkingDay);
        }, 0);
      }
      else if (sessionDispatchDate) {
        if (
          new Date(sessionDispatchDate).getTime() < new Date(firstWorkingDay).getTime() ||
          new Date(sessionDispatchDate).getTime() > new Date(lastWorkingDay).getTime()
        ) {
          setFieldValue('DispatchDate', firstWorkingDay);
          sessionStorage.setItem(dispatchProperty, firstWorkingDay);
        } else setFieldValue('DispatchDate', formatDate(sessionDispatchDate));
      }
    }
    else if (id !== undefined || shipmentId !== undefined) {
      setTimeout(() => {
        sessionStorage.setItem(dispatchProperty, formikValues.DispatchDate);
        setFieldValue('DispatchDate', formikValues.DispatchDate);
      }, 0);
    }
    else {
      !formikValues.DispatchDate && setFieldValue('DispatchDate', formatDate(sessionDispatchDate))
    }
  }, [workingDays]);

  useEffect(() => {
    if (formikValues.accountNumber && formikValues.DispatchDate && (new Date(formikValues.DispatchDate).toDateString() === new Date().toDateString()) && tradingLocation.length > 0) {
      const dispatchProperty = isAmendShipment ? "DispatchDateForAmend" : "DispatchDate";
      sessionStorage.setItem(dispatchProperty, formikValues.DispatchDate);
      const tmpBookedDate = formatDate(formikValues.DispatchDate);
      abortOnGoingCallsFor(ServiceEndPoints.getPickups);
      axiosConfig.get(
        `${ServiceEndPoints.getPickups
        }?customerAccountId=${getCustomerAccountID()}&startDate=${tmpBookedDate}&endDate=${tmpBookedDate}`,
      )
        .then((res) => {
          if (res.data?.length > 0) {
            let availablePickups = res.data.filter(e => (e.pickupType?.toLowerCase() !== "carriageforward"
              && e.pickupType?.toLowerCase() !== "return")).filter(val => (val.status !== null
                && val.status?.toLowerCase() !== "failed"
                && val.status?.toLowerCase() !== "autogeneratedclosed"));

            let availableTradingLocations = tradingLocationArrayForm(tradingLocation, [formikValues.accountNumber])[1];
            availablePickups = availablePickups.filter(pickup => availableTradingLocations.some(location => location.tradingLocationId === pickup.tradingLocationId))
            if (availablePickups.length > 0) {
              setIsPickUpBooked(true)
            }
            else {
              setIsPickUpBooked(false)
            }
          }
          else if (res.data?.length === 0) {
            setIsPickUpBooked(false)
          }
          else {
            setIsPickUpBooked(false)
          }
        })
        .catch((err) => {
          setIsPickUpBooked(false);
        });
    }
  }, [formikValues.DispatchDate, tradingLocation, formikValues.accountNumber])

  useEffect(() => {
    if (!sessionStorage.getItem('DispatchDate') || !sessionStorage.getItem('DispatchDateForAmend')) {
      setFieldValue('DispatchDate', new Date());
      sessionStorage.setItem('DispatchDate', new Date());
      sessionStorage.setItem('DispatchDateForAmend', new Date());

    }
    getPickupLocation();
  }, []);

  //Pallet related changes
  useEffect(() => {
    if (formikValues.createPalletShipment === MISC_VALUES.YES) {
      setHandOverDisabled(true);
      setFieldValue('handOverMethod', 'driverCollection');
    } else {
      setHandOverDisabled(false);
      setFieldValue('handOverMethod', 'driverCollection');
    }
  }, [formikValues.createPalletShipment]);
  return (
    <Grid container className="setup-container">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container spacing={3} direction="row" alignItems="flex-start">
          <Grid item xs={7} sm={8} md={8} lg={8}>
            <FormRenderer
              {...handOverMethod.props}
              data={handOverOptions}
              isDisabled={isHandOverDisabled || isHandOverDisabledByAccount || isAmendShipment}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} className="AccountNumber-main-grid AutoComplete_inputRoot_parent_class" style={{ paddingLeft: '58px' }}>
            <FormRenderer {...accountNumber.props} flagForAfterSubmitCheck={flagForAfterSubmitCheck} setFlagForAfterSubmitCheck={setFlagForAfterSubmitCheck} variant="standard" fieldValue={formikValues.accountNumber} valuePropertyName="accountId" data={displayAccounts} isDisabled={isAmendShipment} fullWidth />
            {/* <Select options={displayAccounts} /> */}
          </Grid>
          <Grid item xs={9} sm={6}></Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="flex-start" spacing={3} className="searchBy-destinationCountry-grid">
        <Grid item xs={12} sm={6} lg={6} className="Setup-details-searchBy" style={{ paddingRight: '40px' }}>
          <FormRenderer
            data={fullSearchByOptions}
            selectProps={setupSearchBy.selectProps.props}
            textProps={setupSearchBy.textProps.props}
            {...setupSearchBy.props}
            onSearchClick={getAddresses}
            isIconDisabled={formikValues.setupSearchFor?.trim() === '' || isAmendShipment || props.userDetails.role === UserRoles.BasicShipmentEntry}
            isDisabled={errors.setupSearchFor || isAmendShipment || props.userDetails.role === UserRoles.BasicShipmentEntry}
          />
          <Grid container>
            <CustomDialog open={open} onClose={handleClose} title="Customer’s address search">
              <CustomAddressTable rows={rows} rowClicked={rowClicked} />
            </CustomDialog>
          </Grid>
          <Grid container>
            <CustomDialog open={NFopen} onClose={handleClose} title="Customer not found">
              {/* <div> Customer Not found </div> */}
            </CustomDialog>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Grid container direction="row" spacing={3}>
            {isDisPatchDateVisible ? (
              <Grid item xs={5} md={5} lg={5} className="Dispatch_date dispatch-date-align">
                <span
                  className={
                    formikValues.DispatchDate && new Date(formikValues.DispatchDate).toDateString() === new Date().toDateString()
                      ? isPickUpBooked
                        ? 'booked-msg'
                        : 'not-booked-msg'
                      : 'no-message'
                  }
                >
                  {formikValues.DispatchDate && new Date(formikValues.DispatchDate).toDateString() === new Date().toDateString()
                    ? isPickUpBooked
                      ? 'Pick-up booked'
                      : 'Pick-up not booked'
                    : ''}
                </span>
                <FormRenderer {...DispatchDate.props} workingDays={workingDays} isWorkingDays={true} isAmendShipment={isAmendShipment} isDisabled={isAmendShipment} fullWidth />
              </Grid>
            ) : (
              <Grid item xs={5} md={5} lg={5} className="Dispatch_date dispatch-date-align"></Grid>
            )}
            <Grid item xs={7} md={7} lg={7}>
              <FormRenderer {...destinationCountry.props} data={countries} fullWidth isDisabled={isAmendShipment} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SetupDetails.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  countries: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    fromPreviewSummary: state.loadedFrom.loadedFrom,
    backUpResponse: state.backUpResponse,
    IossNumber: state.common.DhlIossNumber,
    userDetails: state.auth.userDetails,
    servicePointWeight: state.common.servicePointWeight
  };
};

export default connect(mapStateToProps, null)(SetupDetails);

const handOverOptions = [
  {
    value: 'driverCollection',
    label: 'Driver collection',
  },
  {
    value: 'servicePoint',
    label: 'Drop off at ServicePoint',
  },
];
