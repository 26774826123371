/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { connect } from 'react-redux';
import { shipmentMovementOptions,reasonOptions } from '../../../../utils/constants/constants';

import CommodityDetails from '../clearanceDeclaration/commodityDetails/commodityDetails';
import { CLERANCE_DECLARATION } from '../../../../utils/constants/constants';

const ClearanceDeclaration = (props) => {
  const {
    formField: {
      shipmentMovement,
      clearanceTotalValue,
      descriptionOfGoods,
      receipentUKIMS,
      receipentEORI,
      shipperUKIMS,
      shipperEORI,
      shippingCharges,
      reasonExport,
      shipperDeferment
    },
    formikValues,
    setFieldValue,
    isCarriageForward,
    clearanceDecleration,
    populateClearance
  } = props;


  useEffect(() => {
    if(populateClearance)
    {
      setFieldValue('shipperEORI', clearanceDecleration?.sendersEORINumber||'');
      setFieldValue('shipperDeferment', clearanceDecleration?.sendersDefermentAccount||'');
    }else{
        setFieldValue('shipperUKIMS', formikValues?.accountNumber?.customsRegistration?.ukimsNumber || '');
        setFieldValue('shipperDeferment', formikValues?.accountNumber?.customsRegistration?.defermentAccountNumber||'');
        setFieldValue('shipperEORI', formikValues?.accountNumber?.customsRegistration?.eoriNumber ||'');
  
    }
  }, [formikValues.shipmentMovement,formikValues.accountNumber]);
  useEffect(() => {
    if(populateClearance){
    shipmentMovementOptions.map((shipmentMovementOption, index) => {
      if (clearanceDecleration?.shipmentMovementType == shipmentMovementOption.name) {
        setFieldValue('shipmentMovement', shipmentMovementOptions[index]);
      }
    });
    const itemData  = clearanceDecleration?.items?.map((obj)=>{
      const selectedCountry = clearanceDecleration?.itemCountry.filter((country) => {
        return (obj?.countryOfManufacture == country?.code2Digit) 
      });
      return{
       commodityCode:obj?.commodityCode||'',
       countryOfOrigin:selectedCountry[0],
       fullDescriptionOfGoods:obj?.descriptionOfGoods||'',
       quantity:obj?.unitQuantity||'',
       unitValue:obj?.unitValue||'',
       unitWeight:obj?.unitWeight||'',
      }
 
     })
    setFieldValue('descriptionOfGoods', clearanceDecleration?.descriptionOfGoodsShipment||'');
    setFieldValue('clearanceTotalValue', clearanceDecleration?.totalValue||'');
    setFieldValue('receipentUKIMS', clearanceDecleration?.recipientUKIMSNumber||'');
    setFieldValue('receipentEORI', clearanceDecleration?.recipientEORINumber||'');
    setFieldValue('shipperUKIMS', clearanceDecleration?.sendersUKIMSNumber||'');
    setFieldValue('shipperEORI', clearanceDecleration?.sendersEORINumber||'');
    setFieldValue('shippingCharges', clearanceDecleration?.shippingCharges||'');
    setFieldValue('shipperDeferment', clearanceDecleration?.sendersDefermentAccount||'');
    setFieldValue('commodityDetails', itemData);
    reasonOptions.map((reasonOption, index) => {
      if (clearanceDecleration?.reasonForExport == reasonOption.value) {
        setFieldValue('reasonExport', reasonOptions[index]);
      }
    });
  }
  },[clearanceDecleration,populateClearance])

  const {
    MOVEMENT_TYPE_B2B_VALUE,
    MOVEMENT_TYPE_C2C_VALUE,
    MOVEMENT_TYPE_C2B_VALUE,
    MOVEMENT_TYPE_B2C_VALUE,
  } = CLERANCE_DECLARATION;

  return (
    <div className="wd-100">
      <Grid container spacing={3} direction="row" alignItems="flex-start" style={{ paddingTop: '0px' }}>
        <Grid item xs={12} sm={4} md={isCarriageForward ? 12 : 6} style={{ paddingRight: '38px' }}>
          <FormRenderer {...shipmentMovement.props} fullWidth data={shipmentMovementOptions} />
        </Grid>
      </Grid>
{ formikValues.shipmentMovement?.value && <>
      <Grid container spacing={3} direction="row" style={{ paddingTop: '4px' }} alignItems="flex-start">
        {formikValues.shipmentMovement?.value === MOVEMENT_TYPE_C2C_VALUE &&
          <Grid item xs={12} sm={12} md={6} style={{ paddingRight: '37px' }}>
            <FormRenderer {...descriptionOfGoods.props} fullWidth />
          </Grid>
        }
        <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
          <FormRenderer {...clearanceTotalValue.props} fullWidth />
        </Grid>

      </Grid>

      {formikValues.shipmentMovement?.value === MOVEMENT_TYPE_C2B_VALUE &&
        <Grid container spacing={3} direction="row" style={{ paddingTop: '4px' }} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={6} style={{ paddingRight: '37px' }}>
            <FormRenderer {...receipentUKIMS.props} fullWidth />
          </Grid>
          <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
            <FormRenderer {...receipentEORI.props} fullWidth />
          </Grid>
        </Grid>
      }
      {formikValues.shipmentMovement?.value === MOVEMENT_TYPE_B2C_VALUE &&
        <Grid container spacing={3} direction="row" style={{ paddingTop: '4px' }} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={6} style={{ paddingRight: '37px' }}>
            <FormRenderer {...shipperUKIMS.props} fullWidth />
          </Grid>
          <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
            <FormRenderer {...shipperEORI.props} fullWidth />
          </Grid>
        </Grid>
      }

      {formikValues.shipmentMovement?.value === MOVEMENT_TYPE_B2B_VALUE &&
        <Grid container spacing={3} direction="row" style={{ paddingTop: '4px' }} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={6} style={{ paddingRight: '37px' }}>
            <FormRenderer {...shipperUKIMS.props} fullWidth />
          </Grid>
          <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
            <FormRenderer {...shipperEORI.props} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={6} style={{ paddingRight: '37px' }}>
            <FormRenderer {...receipentUKIMS.props} fullWidth />
          </Grid>
          <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
            <FormRenderer {...receipentEORI.props} fullWidth />
          </Grid>

        </Grid>
      }
      {formikValues.shipmentMovement?.value !== MOVEMENT_TYPE_C2C_VALUE &&
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CommodityDetails {...props} shipmentMovementType={formikValues.shipmentMovement?.value} />
        </Grid>
      }
      {!formikValues?.receipentUKIMS && !formikValues?.shipperUKIMS && formikValues.shipmentMovement?.value == MOVEMENT_TYPE_B2B_VALUE &&
        <>
          <Grid container spacing={3} direction="row" style={{ paddingTop: '20px' }} alignItems="flex-start">
            <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
              <FormRenderer {...shippingCharges.props} fullWidth />
            </Grid>
            <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
              <FormRenderer {...reasonExport.props} data={reasonOptions} fullWidth />
            </Grid>
            <Grid item xs={10} sm={6} md={6} style={{ paddingRight: '37px' }}>
              <FormRenderer {...shipperDeferment.props} fullWidth />
            </Grid>
          </Grid>
        </>
      }
      </>
    }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadedFrom: state.loadedFrom.loadedFrom,
  };
};

export default connect(mapStateToProps, null)(ClearanceDeclaration);
